import React, { Component } from 'reactn';
import { Result as Res, Button } from 'antd';
import Main from './Main';

class Result extends Component {
	constructor(props) {
		super(props);

		this.state = {
			IsOk: false,
		};
	}

	_RenderSuccess = () => {
		let _Txt1 = this.global.LanguagePack[this.global.LanguageNo].LabelThankyou;
		let _Txt2 = this.global.LanguagePack[this.global.LanguageNo].LabelText1;

		let _TableNo =
			this.global.LanguagePack[this.global.LanguageNo].ButtonMyTableCaption +
			' ' +
			localStorage.getItem('TableName');

		let _OrderNo =
			this.global.LanguagePack[this.global.LanguageNo].LabelOrderNo +
			' ' +
			this.global.OrderNo;



		let _Style = { color: 'MediumSeaGreen', 'font-weight': 'bold' };
		//	let _Style2 = { color: 'Red' };

		if (
			this.global.Default.AppMode === 'T' ||
			this.global.Default.AppMode === 'P'
		) {
			_TableNo = '';
		}



		let _AddTxt = 'Screenshot this screen for record purpose'

		return (
			<Res
				status="success"
				title={
					<div>
						<p>{_Txt1}</p>
						<p>{_Txt2}</p>
					</div>
				}
				subTitle={
					<div style={_Style}>
						<p>{_TableNo}</p>
						<p>{_OrderNo}</p>


					</div>



				}
				extra={[
					<Button
						type="primary"
						key="Back"
						size="large"
						color={this.global.Color}
						block
						onClick={() => this.setState({ IsOk: true })}
					>
						{this.global.LanguagePack[this.global.LanguageNo].LabelContinue}
					</Button>,
				]}
			/>
		);
	};

	render() {
		if (this.state.IsOk === true) {
			return <Main />;
		}


		return this._RenderSuccess();
	}
}

export default Result;

import React, { getGlobal, setGlobal } from 'reactn';
import * as lod from 'lodash';
import Geocode from 'react-geocode';

export async function CallGetApi(zValue) {
	let _Gb = getGlobal();

	//console.log("GB", _Gb);

	let _url = _Gb.BasedUrl;
	let _ProfileCode = _Gb.ProfileCode;

	switch (zValue.Type) {
		case 'UPDATEPAYMENT':
			_url =
				_url +
				'PaymentGateway/Mol/UpdatePayment?zTokenId=' +
				zValue.TokenId +
				'&zProfileCode=' +
				_ProfileCode;
			break;

		case 'QUERY':
			_url = _url + 'Validation/ValidateProfile?zProfileCode=' + _ProfileCode;
			break;

		case 'READORDER':
			_url =
				_url +
				'Trans/ReadOrder?zProfileCode=' +
				_ProfileCode +
				'&zTableCode=' +
				zValue.Value.TableCode +
				'&zToken=' +
				zValue.Value.TokenId +
				'&zWebType=' +
				zValue.Value.WebType +
				'&zTrsNo=' +
				zValue.Value.TrsNo;
			break;

		case 'READWEBORDER':
			_url =
				_url +
				'Trans/ReadWebOrder?zProfileCode=' +
				_ProfileCode +
				'&zTrsNo=' +
				zValue.Value.TrsNo;

			zValue.FetchType = 'D';

			break;
		case 'GETOUTLET':
			_url =
				_url +
				'Outlet/ListOutlet?zProfileCode=' +
				_ProfileCode +
				'&zAddress=' +
				zValue.Value.Address +
				'&zGoogleApiKey=' +
				_Gb.WebInfo.GoogleApiKey;
			break;

		default:
			break;
	}

	var requestOptions = {
		method: 'GET',
		redirect: 'follow',
	};

	//console.log('url', _url);
	//console.log('zValue', zValue);
	//console.log('return Value', res);

	if (zValue.FetchType === 'D') {
		return new Promise((resolve, reject) => {
			fetch(_url, requestOptions)
				.then(response => {
					if (!response.ok) {
						throw new Error('HTTP error:');
					}
					return response.json();
				})
				.then(res => {
					resolve(res);
				})
				.catch(error => {
					console.error('Server-side validation error', error);

					reject('Cannot contact validation server');
				});
		});
	} else {
		return new Promise((resolve, reject) => {
			fetch(_url, requestOptions)
				.then(response => {
					if (!response.ok) {
						throw new Error('HTTP error:');
					}
					return response.json();
				})
				.then(res => {
					//  console.log('object', res)
					if (res.IsSuccess === 1) {
						resolve(res);
					} else {
						console.log('Error', res);
						reject('Error Return');
					}

					resolve(res);
				})
				.catch(error => {
					console.error('Server-side validation error', error);
					reject('Cannot contact validation server');
				});
		});
	}
}

export async function CallPostApi(zValue) {
	let _Gb = getGlobal();

	let _url = _Gb.BasedUrl;
	let _ProfileCode = _Gb.ProfileCode;

	switch (zValue.Type) {
		case 'GETREV':
			_url = _url + 'PaymentGateway/Rev/Request?zProfileCode=' + _ProfileCode;
			break;

		case 'POSTREV':
			_url = 'https://staging-gateway.revpay.com.my/payment_json';
			break;

		case 'GETMOL':
			_url = _url + 'PaymentGateway/Mol/Request';
			break;
		case 'QUERY':
			_url = _url + 'Validation/ValidateProfile?zProfileCode=' + _ProfileCode;
			break;

		case 'SAVEDATA':
			_url = _url + 'Trans/StoreData?zProfileCode=' + _ProfileCode;
			break;

		case 'VALIDATEMEMBER':
			_url = _url + 'Member/Validation?zProfileCode=' + _ProfileCode;
			break;
		case 'INSERTMEMBER':
			_url = _url + 'Member/Insert?zProfileCode=' + _ProfileCode;
			break;

		default:
			break;
	}

	let NewData = JSON.stringify(zValue.Value);
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json');

	var requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: NewData,
		redirect: 'follow',
	};

	//	console.log('Post Url', _url);
	//	console.log('Post Body', NewData);

	if (zValue.FetchType === 'D') {
		return new Promise((resolve, reject) => {
			fetch(_url, requestOptions)
				.then(response => {
					if (!response.ok) {
						throw new Error('HTTP error:');
					}
					return response.json();
				})
				.then(res => {
					resolve(res);
				})
				.catch(error => {
					console.error('Server-side validation error', error);

					reject('Cannot contact validation server');
				});
		});
	} else {
		return new Promise((resolve, reject) => {
			fetch(_url, requestOptions)
				.then(response => {
					if (!response.ok) {
						throw new Error('HTTP error:');
					}
					return response.json();
				})
				.then(res => {
					if (res.IsSuccess === 1) {
						resolve(res);
					} else {
						resolve(res);
					}

					resolve(res);
				})
				.catch(error => {
					console.error('Server-side validation error', error);
					reject('Cannot contact validation server');
				});
		});
	}
}

export function StartInit(zValue) {
	let _OutletInfo = zValue.OutletInfo[0];
	let _ItemInfoAll = zValue.QueryReturn.ListItem;

	let _ItemAll = zValue.QueryReturn.ListAllItem;

	//console.log('object', zValue);

	let _ItemInfo = lod.filter(
		_ItemInfoAll,
		q => q.RefDesc.substr(21, 1) === 'X' && q.RefDesc.substr(23, 1) === 'X'
	);

	//let _ClassGroupInfo = zValue.QueryReturn.ListClassGroup;

	let _ClassGroupInfo = lod.filter(
		zValue.QueryReturn.ListClassGroup,
		q => q.TotalItems >= 1
	);

	let _ClassDetailsInfo = lod.filter(
		zValue.QueryReturn.ListClassDetails,
		q => q.TotalItems >= 1
	);

	let _SubItemInfo = zValue.QueryReturn.ListSubItem;

	//let _ClassDetailsInfo = zValue.QueryReturn.ListClassDetails;
	let _MajorGroupInfo = zValue.QueryReturn.ListMajorGroup;
	let _ServiceInfo = zValue.QueryReturn.ListService;
	let _ComboSetInfo = zValue.QueryReturn.ListComboSet;
	let _PromItemInfo = zValue.QueryReturn.ListPromItem;
	let _WebPaymentInfo = zValue.QueryReturn.ListWebPayment;
	let _InterfaceConfigInfo = zValue.QueryReturn.ListInterfaceConfig;
	let _ModifierGroupInfo = zValue.QueryReturn.ListModifierGroup;
	let _ModifierDetailsInfo = zValue.QueryReturn.ListModifierDetails;
	if (_ModifierDetailsInfo !== null) {
		_ModifierDetailsInfo.forEach(el => {
			el.IsCheck = false;
		});
	}

	let _CondimentGroupInfo = zValue.QueryReturn.ListCondimentGroup;
	let _CondimentDetailsInfo = zValue.QueryReturn.ListCondimentDetails;

	if (_CondimentDetailsInfo !== null) {
		_CondimentDetailsInfo.forEach(e2 => {
			e2.IsCheck = false;
		});
	}

	let _AppMode = localStorage.getItem('ServiceCode');
	let _TableCode = localStorage.getItem('TableCode');
	let _TableCode2 = localStorage.getItem('TableCode');

	let _MemberInfo = { Code: '', Name: '', EmailAddress: '', HpNo: '' };

	//========================== Load Table
	localStorage.setItem('TableCode', '-');
	let _TableName = '-';
	let _TableInfo = lod.filter(
		zValue.QueryReturn.ListTable,
		m => m.TableCode === _TableCode
	);
	//console.log('Table',_TableInfo.length)
	//console.log('Table----111',zValue.QueryReturn.ListTable)

	// ==== UpSell

	let _UpSellMap = zValue.QueryReturn.ListupSellMap;
	let _UpSellCondition = zValue.QueryReturn.ListupSellCondition;

	if (_TableInfo.length !== 0) {
		_TableName = _TableInfo[0].TableName;
		localStorage.setItem('TableCode', _TableCode);
		localStorage.setItem('TableName', _TableName);
	}

	let _Color = 'warning';

	let _lineBoldGrayFont = {
		'font-size': '14px',
		color: 'Gray',
		'font-weight': 'bold',
	};

	let _lineBoldFont = {
		'font-size': '14px',
		color: 'Red',
		'font-weight': 'bold',
	};

	let _lineTextFont = {
		'font-size': '14px',
		color: 'Dark',
	};

	let _lineTextGrayFont = {
		'font-size': '14px',
		color: 'Gray',
	};

	let _lineTextGraySmallFont = {
		'font-size': '11px',
		color: 'Gray',
	};

	let _lineTextRedFont = {
		'font-size': '14px',
		color: 'Red',
	};

	let _App = {
		TextBold: _lineBoldFont,
		TextNormal: _lineTextFont,
		TextGraySmall: _lineTextGraySmallFont,
		TextGrayNormal: _lineTextGrayFont,
		TextBoldGrayNormal: _lineBoldGrayFont,
		TextRedNormal: _lineTextRedFont,
	};
	let _Default = {
		SelectedServiceInfo: null,
		SelectedServiceCode: null,
		AppMode: _AppMode,
		TableCode: _TableCode,
		TableName: _TableName,
		DefTableCode: _TableCode2,
	};

	let _IsQrPayment = 0;
	// [F22] Enable QrPayment
	if (_OutletInfo.RefOutlet.substr(21, 1) === 'O') {
		_IsQrPayment = 1;
	}

	let _IsQrPayLater = 0;
	let _IsDisablePayLater = true;
	// [F24] Enable Payment Later
	if (_OutletInfo.RefOutlet.substr(23, 1) === 'O') {
		_IsQrPayLater = 1;
		_IsDisablePayLater = false;
	}
	/* 	console.log(
		'first',
		_OutletInfo.RefOutlet,
		_OutletInfo.RefOutlet.substr(21, 1),
		_OutletInfo.RefOutlet.substr(23, 1)
	); */

	// Check Web Payment

	let _DefWebPayInfo = lod.filter(
		zValue.QueryReturn.ListInterfaceConfig,
		Y => Y.ModuleCode === zValue.OutletInfo[0].WebPaymentCode
	);

	if (_DefWebPayInfo.length === 0) {
		_DefWebPayInfo = null;
	} else {
		_DefWebPayInfo = _DefWebPayInfo[0];
	}

	//console.log('dddd', _DefWebPayInfo);
	//console.log('zValue', zValue);
	let _WebInfo = {
		//PaymentInfo: _DefWebPayInfo,
		PayLaterButton: _IsDisablePayLater,
		WithPayment: _IsQrPayment,
		WithPayLater: _IsQrPayLater,
		GoogleApiKey: 'AIzaSyBJOkSSqHCxlZv-x4RacrqRDzcKLwmRde8',
		MobileNo: localStorage.getItem('MobileNo'),
		CustomerName: localStorage.getItem('CustomerName'),
		OutletCode: '',
		ServiceCode: '',
		FromAddress: '',
		ToAddress: '',
		UnitNo: '',
		IsLinkLalamove: zValue.IsLinkLalamove,
		LalamoveBasedUrl: 'https://rest.sandbox.lalamove.com',
		LalamoveApiKey: '2728fc428fa4e107ed75c66acafb7963',
		LalamoveSecretKey:
			'xt4/T7HDpVd9ipcrc5pUNeol+0cDjWw3bPHv41vN/TsspPZ8gdJqPEa67Jx4eLEJ',
	};

	let _LiveData = { Bills: {}, BillDetails: [] };

	let _Status = {
		AppType: localStorage.getItem('AppType'),
		ModuleType: localStorage.getItem('ModuleType'),
		Status: localStorage.getItem('ReturnStatus'),
		ReturnToken: localStorage.getItem('ReturnToken'),
	};

	setGlobal({
		UpSellMap: _UpSellMap,
		UpSellCondition: _UpSellCondition,
		MemberInfo: _MemberInfo,
		Url: window.location.href,
		ReturnStatus: _Status,
		WebInfo: _WebInfo,
		LiveData: _LiveData,
		Default: _Default,
		App: _App,
		OutletInfo: _OutletInfo,
		WebPaymentInfo: _WebPaymentInfo,
		InterfaceConfig: _InterfaceConfigInfo,
		ItemAll: _ItemAll,
		ItemInfo: _ItemInfo,
		SubItemInfo: _SubItemInfo,
		ItemInfoAll: _ItemInfoAll,
		ComboSetInfo: _ComboSetInfo,
		ClassGroupInfo: _ClassGroupInfo,
		ClassDetailsInfo: _ClassDetailsInfo,
		PromItemInfo: _PromItemInfo,
		CondimentGroupInfo: _CondimentGroupInfo,
		CondimentDetailsInfo: _CondimentDetailsInfo,
		ModifierGroupInfo: _ModifierGroupInfo,
		ModifierDetailsInfo: _ModifierDetailsInfo,
		MajorGroupInfo: _MajorGroupInfo,
		ServiceInfo: _ServiceInfo,
		ImageInfo: zValue.QueryReturn.ListImageLoop,
		Color: _Color,
		LanguageNo: 0,
		LanguagePack: ConvertLanguage(0),
	});

	localStorage.removeItem('AppType');
	localStorage.removeItem('ModuleType');
	localStorage.removeItem('ReturnStatus');
	localStorage.removeItem('ReturnToken');
}

export function ConvertLanguage(e) {
	let lang = [
		{
			ButtonMyTableCaption: 'My Table No.',
			ButtonOrderCaption: 'Start New Order',
			ButtonMyHistoryCaption: 'My Order',
			ButtonDeliveryCaption: 'DELIVERY',
			ButtonSelfCaption: 'SELF COLLECT',
			ButtonDineInCaption: 'DINE IN',
			ButtonTakeAwayCaption: 'TAKE AWAY',
			ButtonCancelCaption: 'CANCEL',
			ButtonSelectLanguage: 'Select Language',
			ButtonOrderingMethod: 'Choose your ordering method',
			ButtonSelectCategory: 'Select Category',
			ButtonSelectCondiment: 'Select Condiment',
			ButtonSelectModifier: 'Select Modifier',
			ButtonAddCaption: 'Add',
			ButtonEditCaption: 'EDIT',
			ButtonTotalCaption: 'TOTAL',
			ButtonComfirmCaption: 'CONFIRM',
			ButtonAddCart: 'Add to Cart',
			LabelOutStock: 'Out of Stock',
			LabelInformation: 'BILL TOTAL',
			LabelDetails: 'DETAILS',
			LabelSummary: 'SUMMARY',
			LabelSubtotal: 'SUBTOTAL',
			LabelTax: 'TAX',
			LabelSvc: 'SERVICE CHARGE',
			LabelGross: 'BEFORE TAX',
			LabelDeliveryCharges: 'DELIVERY CHARGES',
			LabelTotal: 'TOTAL',
			LabelDue: 'TOTAL DUE',
			LabelSignIn: 'SIGN IN',
			LabelSignup: 'SIGN UP',
			LabelPassword: 'Password',
			LabelConfirmPassword: 'Confirm Password',
			LabelName: 'Name',
			LabelMobileNo: 'Mobile No.',
			LabelEmail: 'Email Address',
			LabelThankyou: 'THANK YOU',
			AlertCheckOutQuestion: 'Proceed to Checkout ?',
			LabelCheckOut: 'CHECKOUT',
			LabelSorry: 'SORRY',
			LabelBack: 'BACK',
			LabelRetry: 'RETRY',
			LabelWebText1:
				'Your Payment already accepted, We will process your order now',
			LabelWebSorryText:
				'Your Order are fail to proceed, Kindly retry or contact Customer support',
			LabelWebButton: 'View Your Order',

			LabelText1: 'Your Order already submitted, please wait to be served.',
			LabelText2: 'Your Payment already accepted',
			LabelOrderNo: 'YOUR ORDER NO.',
			LabelOrderNoLine: 'Order No.',
			LabelLastUpdate: 'Last Update on : ',
			LabelSelectPickupAddress: 'PLEASE PICKUP ADDRESS',
			LabelSelectDeliveryAddress: 'PLEASE DELIVERY ADDRESS',
			LabelSelectPaymentMode: 'PLEASE SELECT PAYMENT MODE',
			LabelSelectClassification: 'SELECT CLASSIFICATION',
			LabelServiceType: 'SERVICE TYPE',
			LabelPickupAddress: 'PICKUP ADDRESS',
			LabelDeliveryAddress: 'DELIVERY ADDRESS',
			LabelTable: 'TABLE NO.',
			LabelSave: 'SAVE',
			LabelRemark: 'REMARK',
			LabelDiscount: 'DISCOUNT',
			LabelSpecialRequest: 'Enter your special remark...',
			LabelAdj: 'ROUND ADJ',
			LabelContinue: 'CONTINUE',
			AlertNoMessage: 'No Order Found !!!',
			AlertWait: 'Please wait while loading...',
			AlertSubmit: 'Please wait while submitting...',
			AlertUpdate: 'Updating in progress...',
			AlertFailSubmit: 'Fail to Submit, Please try again later...',
			AlertFailSubmitRetry: 'Fail to Submit, Please try again later...',
			AlertConfirmBack:
				'You still have order not submitted, are you sure to abort ?',
			AlertTitle: 'Alert !!!',
			AlertQuestionTitle: 'Question',
			AlertQuestion: 'Confirm to Submit this order ?',

			LabelOrderDate: 'Order Date',
		},
		{
			AlertUpdate: '更新中,请稍候...',
			LabelOrderNoLine: '订单号码.',
			LabelLastUpdate: '最后更新时间 : ',
			AlertConfirmBack: '您的购物车中还有订单，你确定要放弃吗 ?',
			AlertQuestionTitle: '问题',
			AlertQuestion: '确认提交此订单 ?',
			AlertTitle: '注意 !!!',
			AlertNoMessage: '没有订单 !!!',
			AlertWait: '载入中，请稍候...',
			AlertSubmit: '提交中，请稍候......',
			AlertFailSubmit: '提交失败，请稍后再试...',
			ButtonSelectCondiment: '请选择您的调料',
			ButtonSelectModifier: '请选择您的添加品',
			ButtonMyTableCaption: '我的桌子号码是',
			ButtonMyHistoryCaption: '我的订单',
			ButtonOrderCaption: '开始订购',
			ButtonDeliveryCaption: '打包',
			ButtonSelfCaption: '自取',
			ButtonDineInCaption: '堂吃',
			ButtonTakeAwayCaption: '外买',
			ButtonCancelCaption: '取消',
			ButtonSelectLanguage: '选择语言',
			ButtonOrderingMethod: '请选择您的订购方式',
			ButtonSelectCategory: '请选择类别',
			ButtonAddCaption: '加入',
			ButtonEditCaption: '修改',
			ButtonTotalCaption: '合计',
			ButtonComfirmCaption: '确认',
			ButtonAddCart: '确认',
			LabelOutStock: '没有存货',
			LabelSave: '确认',
			LabelInformation: '订单详情',
			LabelDetails: '订单详情',
			LabelSummary: '总结',
			LabelSubtotal: '小计',
			LabelSpecialRequest: '请输入您的特别备注...',
			LabelTax: '服务税',
			LabelSvc: '服务费',
			LabelGross: '税前',
			LabelDeliveryCharges: '运输费',
			LabelTotal: '合计',
			LabelDue: '共计',
			LabelSignIn: '登入',
			LabelSignup: '注册',
			LabelPassword: '密码',
			LabelConfirmPassword: '确认密码',
			LabelName: '名字',
			LabelMobileNo: '手机号码',
			LabelEmail: '电邮',
			LabelThankyou: '谢谢',
			LabelWebText1:
				'Your Order already submitted, We will process your food now',
			LabelWebButton: 'View Your Order',
			LabelText1: '您的订单已提交，请等侍候',
			LabelText2: '您的付款已被接受',
			LabelOrderNo: '您的订单号',
			LabelSelectPickupAddress: '请选择接收的地点',
			LabelSelectDeliveryAddress: '请选择接送餐地点',
			LabelSelectPaymentMode: '请选择付款方式',
			LabelSelectClassification: '请选择食物分组',
			LabelServiceType: '服务类型',
			LabelPickupAddress: '接收地址',
			LabelDeliveryAddress: '送餐地址',
			LabelTable: '台号码',
			LabelDiscount: '折扣',
			LabelAdj: '进位调整',
			LabelContinue: '继续',
			LabelRemark: '备注',
		},
	];

	return lang;
}

export function ConvertFormat(e) {
	if (e === undefined || e === null) {
		e = 0;
	}

	return e.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function RoundingAdj(zAmount) {
	let _TempAmt = parseFloat(zAmount);

	if (_TempAmt < 0) {
		_TempAmt = Math.abs(zAmount);
	}

	let costInCents = _TempAmt * 100;

	if (costInCents === 0) {
		return 0;
	}

	let roundedCostInCents = costInCents - ((costInCents - 3) % 5) + 2;
	let roundedCostInDollars = roundedCostInCents / 100;

	if (zAmount < 0) {
		roundedCostInDollars = 0 - roundedCostInDollars;
	}
	return parseFloat(roundedCostInDollars);
}

function CalLine(zValue) {
	let _Gb = getGlobal();

	//  let _Condiment = ''

	let _RefComp = _Gb.OutletInfo.RefComp;
	let _RefService = _Gb.Default.SelectedServiceInfo.RefService;

	let _ItemInfo = zValue.ItemInfo;
	let _UnitPrice = zValue.UnitPrice;
	let _Qty = zValue.Qty;

	let _Subtotal = _Qty * _UnitPrice;
	let _TaxRate = _ItemInfo.TaxRate;
	let _SvcRate = _ItemInfo.SvcRate;
	let _SvcTaxRate = _ItemInfo.SvcTaxRate;

	let _Condiment = zValue.Condiment;
	let _Modifier = zValue.Modifier;
	let _ComboSet = zValue.ComboSet;

	//console.log('CalLine - Modifier', _Modifier)

	let IsUpSell = 0;
	if (zValue.UpSellData !== undefined) {
		//	console.log('UpSell CalLine', zValue.UpSellData);
		IsUpSell = zValue.UpSellData.IsUpSell;
	}

	let _TaxMethod = 'E';
	let _SvcTaxInclude = 0;
	// [G1] Enable Tax
	if (_RefComp.substr(0, 1) !== 'O') {
		_TaxRate = 0;
	}

	// [S2] Enable Service Charge
	if (_RefService.substr(1, 1) !== 'O') {
		_SvcRate = 0;
	}

	// [S23] Enable Tax Inclusive
	if (_RefService.substr(23, 1) === 'O') {
		_TaxMethod = 'I';
	}

	// [S3] Tax Include on Service Charge
	if (_RefService.substr(2, 1) === 'O') {
		_SvcTaxInclude = 1;
	}

	let _LineDiscRate = 0;
	let _LineDiscAmt = 0;
	let _DiscRemark = '';

	//   console.log('IsSubItem---', zValue.IsSubItem)
	if (zValue.IsSubItem === 0) {
		//     console.log('Yes', _ItemInfo.Code)
		//      console.log('Subtotal + Qty', _Subtotal, _Qty)
		let _DiscValue;

		if (IsUpSell === 1) {
			_DiscValue = GetUpSellPromItem(_ItemInfo.Code, zValue.UpSellData);
		} else {
			_DiscValue = GetPromItem(_ItemInfo.Code);
		}

		if (_DiscValue.IsSuccess === 1) {
			_DiscRemark = _DiscValue.Remark;
			switch (_DiscValue.Type) {
				case 'D':
					if (_DiscValue.DiscType === 'P') {
						_LineDiscRate = _DiscValue.Rate;
						_LineDiscAmt = (_LineDiscRate / 100) * _Subtotal;
					} else {
						_LineDiscAmt = _DiscValue.Amt;
					}
					break;
				case 'P':
					_UnitPrice = _DiscValue.Amt;
					_Subtotal = _Qty * _UnitPrice;
					break;

				default:
					break;
			}
		}
	}

	let _GrossAmt = _Subtotal - _LineDiscAmt;
	let _SvcAmt = (_SvcRate / 100) * _GrossAmt;
	let _SvcTaxAmt = _GrossAmt;
	let _TotalTaxAmt = 0;

	//console.log("Tax Method", _TaxMethod);

	if (_SvcTaxInclude === 1) {
		_SvcTaxAmt = _GrossAmt + _SvcAmt;
	} else {
		_SvcTaxRate = 0;
	}

	let _TaxAmt = 0;
	if (_TaxMethod === 'E') {
		_TaxAmt = (_TaxRate / 100) * _SvcTaxAmt;

		//  _SvcAmt = _SvcTaxAmt;
		//  _SvcTaxAmt = 0;

		//  console.log("TaxRate", _TaxRate);
		//  console.log("SvcAmt", _SvcAmt);
		//  console.log("TaxAmt", _TaxAmt);

		_TotalTaxAmt = _TaxAmt;
		_TaxAmt = _TotalTaxAmt;

		//  _SvcTaxAmt = _SvcAmt;
	} else {
		if (_SvcRate === 0) {
			_SvcTaxRate = 0;
		} else {
			_SvcTaxRate = _SvcTaxRate / _SvcRate;
		}

		let _IncTaxRate = 100 + (_TaxRate + _SvcRate + _SvcTaxRate);

		let _Clear = (_GrossAmt / _IncTaxRate) * 100;

		//   console.log("SvcRate", _SvcRate);
		//   console.log("SvcTaxRate", _SvcTaxRate);
		//  console.log("TaxRate", _TaxRate);

		//  console.log("IncRate", _IncTaxRate);
		//  console.log('IncTaxAmt', _IncTaxAmt)
		// console.log("Clear", _Clear);

		_Subtotal = _GrossAmt;
		_GrossAmt = _Clear;

		_SvcAmt = (_SvcRate / 100) * _GrossAmt;
		_SvcTaxAmt = (_SvcTaxRate / 100) * _GrossAmt;
		_TaxAmt = (_TaxRate / 100) * _GrossAmt;

		// console.log("GrossAmt", _GrossAmt);
		// console.log("SvcAmt", _SvcAmt);
		// console.log("SvcTaxAmt", _SvcTaxAmt);
		// console.log("TaxAmt", _TaxAmt);

		_TotalTaxAmt = _TaxAmt + _SvcTaxAmt;
		_TaxAmt = _TotalTaxAmt;
	}

	//  let _Nett = _GrossAmt + (_TaxAmt + _SvcTaxAmt);
	// _TaxAmt = _TaxAmt + _SvcAmt;

	let _Nett = _GrossAmt + _SvcAmt + _TaxAmt;

	let _Item = [];
	_Item.push(_ItemInfo);
	let _Cart = {
		MenuCode: _ItemInfo.Code,
		MenuName: _ItemInfo.Name,
		Qty: _Qty,
		UnitPrice: _UnitPrice,
		Subtotal: _Subtotal,
		LineDiscRate: _LineDiscRate,
		LineDiscAmt: _LineDiscAmt,
		LineDiscRemark: _DiscRemark,
		TaxRate: _TaxRate,
		GrossAmt: _GrossAmt,
		TaxAmt: _TaxAmt,
		SvcRate: _SvcRate,
		SvcTaxRate: _SvcTaxRate,
		SvcAmt: _SvcAmt,
		Nett: _Nett,
		Condiment: _Condiment,
		Modifier: _Modifier,
		ItemInfo: _ItemInfo,
		ItemInfo2: _Item,
		ModiQty: zValue.ModiQty,
		ComboQty: zValue.ComboQty,
		Remark: zValue.Remark,
		IsModifier: zValue.IsModifier,
		IsSubItem: zValue.IsSubItem,
		TaxCode: _ItemInfo.TaxCode,
		SvcTaxCode: _ItemInfo.SvcTaxCode,
		SvcCode: _ItemInfo.SvcCode,
		ComboSet: _ComboSet,
	};

	return _Cart;
}

export function SaveCart(e) {
	let _Gb = getGlobal();

	/*    console.log('RefComp', _RefComp)
	   console.log('RefOutlet', _RefOutlet)
	   console.log('RefService', _RefService)
	   console.log('e', e) */

	//	console.log('e', e);

	let _ItemInfo = {};
	let _TempValue = {};
	let _Condiment = '';
	let _Cart = {};
	let _Modifier = [];
	let _ComboSet = [];
	let _Remark = e.Remark;
	let _IsModifier = 0;

	let _IsUpSell = 0;

	if (e.UpSellData !== undefined) {
		_IsUpSell = e.UpSellData.IsUpSell;
	}

	switch (e.Type) {
		case 'ADD':
			_ItemInfo = e.ItemInfo;
			_IsModifier = 0;

			//	console.log('e', e);
			// Condiment
			if (e.Condiment.length !== 0) {
				let k = 0;
				for (k = 0; k < e.Condiment.length; k++) {
					let k1 = e.Condiment[k].Desc;
					if (k1 !== '') {
						_Condiment = _Condiment + k1 + ',';
					}
				}
				if (_Condiment.length >= 1) {
					_Condiment = _Condiment.substring(0, _Condiment.length - 1);
				}
			}

			//	_Condiment = e.Condiment;

			// Modifier

			if (e.Modifier.length !== 0) {
				//	console.log('e.Modifier', e.Modifier);
				let k = 0;
				for (k = 0; k < e.Modifier.length; k++) {
					let kCode = e.Modifier[k].Code;
					let kQty = e.Modifier[k].Qty;
					let _Rmk = e.Modifier[k].Remark;
					let kData = lod.filter(_Gb.ItemAll, q => q.Code === kCode);
					//	console.log('kCode', kCode);
					//	console.log('kData - ItemInfoAll', _Gb.ItemInfoAll);
					//console.log('kData - Modifier', kData);
					if (kData.length !== 0) {
						_IsModifier = 1;
						_TempValue = {
							IsModifier: 0,
							IsSubItem: 1,
							Condiment: '',
							Qty: kQty * e.Qty,
							Remark: _Rmk,
							ModiQty: kQty,
							UnitPrice: kData[0].PosPrice,
							ItemInfo: kData[0],
						};

						//	console.log('TempValue', _TempValue)

						_Cart = CalLine(_TempValue);
						_Modifier.push(_Cart);
					}
				}
			}

			// ComboSet

			//	console.log('IitemInfoAll', _Gb.ItemInfoAll);
			if (e.ComboSet.length !== 0) {
				// console.log('Original', e.Modifier)
				let z = 0;
				for (z = 0; z < e.ComboSet.length; z++) {
					let kCode = e.ComboSet[z].MenuCode;
					let kQty = e.ComboSet[z].Qty;
					let kData = lod.filter(_Gb.ItemAll, q => q.Code === kCode);
					//	console.log('kData - kCode', kCode);
					//	console.log('kData - ComboSet', kData);
					if (kData.length !== 0) {
						_TempValue = {
							IsModifier: 0,
							IsSubItem: 1,
							ComboQty: kQty,
							Condiment: '',
							Qty: kQty * e.Qty,
							UnitPrice: 0,
							ItemInfo: kData[0],
						};
						_Cart = CalLine(_TempValue);
						_ComboSet.push(_Cart);
					}
				}
			}
			_TempValue = {
				IsModifier: _IsModifier,
				IsSubItem: 0,
				Condiment: _Condiment,
				Modifier: _Modifier,
				ComboSet: _ComboSet,
				Qty: e.Qty,
				UnitPrice: e.ItemInfo.PosPrice,
				ItemInfo: e.ItemInfo,
				Remark: _Remark,
				UpSellData: e.UpSellData,
			};
			//	console.log('TempValue', _TempValue);
			_Cart = CalLine(_TempValue);
			break;

		case 'EDIT':
			_ItemInfo = _Gb.LiveData.BillDetails[e.Row].ItemInfo;
			_Condiment = _Gb.LiveData.BillDetails[e.Row].Condiment;
			_ComboSet = _Gb.LiveData.BillDetails[e.Row].ComboSet;
			_Modifier = _Gb.LiveData.BillDetails[e.Row].Modifier;
			_Remark = _Gb.LiveData.BillDetails[e.Row].Remark;
			_IsModifier = 0;

			// Modifier
			if (_Modifier.length !== 0) {
				_IsModifier = 1;
				let k = 0;
				for (k = 0; k < _Modifier.length; k++) {
					//  let kCode = _Modifier[k].Code
					let kQty = _Modifier[k].ModiQty;
					let _Rmk = _Modifier[k].Remark;
					//  let kData = lod.filter(_Gb.ItemInfo, (q) => q.Code === kCode)

					_TempValue = {
						IsModifier: 0,
						IsSubItem: 1,
						Condiment: _Condiment,
						Qty: kQty * e.Qty,
						UnitPrice: _Modifier[k].UnitPrice,
						ItemInfo: _Modifier[k].ItemInfo,
						Remark: _Rmk,
					};

					//  console.log('Edit Qty', _TempValue)
					_Cart = CalLine(_TempValue);

					lod.set(_Modifier[k], 'Qty', _Cart.Qty);
					lod.set(_Modifier[k], 'Subtotal', _Cart.Subtotal);
					lod.set(_Modifier[k], 'GrossAmt', _Cart.GrossAmt);
					lod.set(_Modifier[k], 'TaxAmt', _Cart.TaxAmt);
					lod.set(_Modifier[k], 'SvcAmt', _Cart.SvcAmt);
					lod.set(_Modifier[k], 'Nett', _Cart.Nett);
				}
			}

			// ComboSet
			if (_ComboSet.length !== 0) {
				let k = 0;

				for (k = 0; k < _ComboSet.length; k++) {
					//  let kCode = _Modifier[k].Code
					let kQty = _ComboSet[k].ComboQty;
					//  let kData = lod.filter(_Gb.ItemInfo, (q) => q.Code === kCode)

					//    console.log('Qty', kQty, e.Qty)
					_TempValue = {
						ComboQty: kQty,
						IsModifier: 0,
						IsSubItem: 1,
						Condiment: _Condiment,
						Qty: kQty * e.Qty,
						UnitPrice: 0,
						ItemInfo: _ComboSet[k].ItemInfo,
						Remark: '',
					};

					//  console.log('Edit Qty', _TempValue)
					_Cart = CalLine(_TempValue);

					lod.set(_ComboSet[k], 'Qty', _Cart.Qty);
				}
			}

			_TempValue = {
				IsModifier: _IsModifier,
				IsSubItem: 0,
				ComboSet: _ComboSet,
				Condiment: _Condiment,
				Modifier: _Modifier,
				Qty: e.Qty,
				UnitPrice: _ItemInfo.PosPrice,
				ItemInfo: _ItemInfo,
				//    Remark: _Remark,
			};

			_Cart = CalLine(_TempValue);
			break;
		default:
			break;
	}

	// Save Details
	let _Det = _Gb.LiveData.BillDetails;

	let _Qty = 0;
	let _Subtotal = 0;
	let _TaxAmt = 0;
	let _SvcAmt = 0;
	let _GrossAmt = 0;
	let _Nett = 0;

	switch (e.Type) {
		case 'ADD':
			//  console.log('_Det',_Det)
			lod.set(_Cart, 'Remark', e.Remark);
			_Det.push(_Cart);
			break;
		case 'EDIT':
			if (e.Qty === 0) {
				_Det.splice(e.Row, 1);
			} else {
				lod.set(_Det[e.Row], 'Qty', _Cart.Qty);
				lod.set(_Det[e.Row], 'LineDiscRate', _Cart.LineDiscRate);
				lod.set(_Det[e.Row], 'LineDiscAmt', _Cart.LineDiscAmt);
				lod.set(_Det[e.Row], 'Subtotal', _Cart.Subtotal);
				lod.set(_Det[e.Row], 'GrossAmt', _Cart.GrossAmt);
				lod.set(_Det[e.Row], 'TaxAmt', _Cart.TaxAmt);
				lod.set(_Det[e.Row], 'SvcAmt', _Cart.SvcAmt);
				lod.set(_Det[e.Row], 'Nett', _Cart.Nett);
			}
			break;

		default:
			break;
	}

	// Save Bills
	_Subtotal = lod.sumBy(_Det, 'Subtotal');
	_Qty = lod.sumBy(_Det, 'Qty');
	_GrossAmt = lod.sumBy(_Det, 'GrossAmt');
	_TaxAmt = lod.sumBy(_Det, 'TaxAmt');
	_SvcAmt = lod.sumBy(_Det, 'SvcAmt');
	_Nett = lod.sumBy(_Det, 'Nett');

	// console.log('Def', _Det)

	let c = 0;

	for (c = 0; c < _Det.length; c++) {
		if (_Det[c].Modifier.length !== 0) {
			let _Subtotal2 = lod.sumBy(_Det[c].Modifier, 'Subtotal');
			let _GrossAmt2 = lod.sumBy(_Det[c].Modifier, 'GrossAmt');
			let _TaxAmt2 = lod.sumBy(_Det[c].Modifier, 'TaxAmt');
			let _SvcAmt2 = lod.sumBy(_Det[c].Modifier, 'SvcAmt');
			let _Nett2 = lod.sumBy(_Det[c].Modifier, 'Nett');

			_Subtotal = _Subtotal + _Subtotal2;
			_TaxAmt = _TaxAmt + _TaxAmt2;
			_SvcAmt = _SvcAmt + _SvcAmt2;
			_GrossAmt = _GrossAmt + _GrossAmt2;
			_Nett = _Nett + _Nett2;
		}
	}

	let _Rounded = RoundingAdj(_Nett);
	let _Adj = _Rounded - _Nett;
	_Adj = Math.round(_Adj * 100) / 100;

	//console.log('Global',_Gb.Default)

	let _DelAmt = _Gb.WebInfo.Charges;

	if (_DelAmt === undefined || _DelAmt === null) {
		_DelAmt = 0;
	}

	let _TotalAmt = _Rounded + parseFloat(_DelAmt);

	let _Bills = {
		ServiceCode: _Gb.Default.AppMode,
		SelectedServiceCode: _Gb.Default.SelectedServiceCode,
		TableCode: _Gb.Default.TableCode,
		TokenId: _Gb.Default.TokenId,
		Subtotal: _Subtotal,
		DiscAmt: 0,
		Qty: _Qty,
		GrossAmt: _GrossAmt,
		TaxAmt: _TaxAmt,
		SvcAmt: _SvcAmt,
		Nett: _Nett,
		Adj: _Adj,
		Rounded: _Rounded,
		DelAmt: _DelAmt,
		TotalAmt: _TotalAmt,
	};

	// console.log('After Sum', _Bills)

	let _LiveData = { Bills: _Bills, BillDetails: _Det };

	//console.log('Final LiveData - BillDetails', _Det)

	setGlobal({ LiveData: _LiveData });

	// console.log('Total', _LiveData)

	return _Cart;
}

function GetPromItem(e) {
	let _TempValue = {
		IsSuccess: 0,
		Type: '',
		Rate: 0,
		Amt: 0,
		DiscType: '',
		Remark: '',
	};

	let _Rate = 0;
	let _Amt = 0;
	let _Type = '';
	let _DiscType = '';
	let _Remark = '';

	let _Gb = getGlobal();
	let _PromItem = lod.filter(_Gb.PromItemInfo, q => q.MenuCode === e);

	//console.log('PromItem', _PromItem);

	if (_PromItem.length === 0) {
		return _TempValue;
	}

	_Type = _PromItem[0].PromType;
	_Remark = _PromItem[0].PromName;
	switch (_PromItem[0].PromType) {
		case 'D':
			_DiscType = _PromItem[0].DiscountType;
			if (_PromItem[0].DiscountType === 'P') {
				_Rate = _PromItem[0].DiscountValue;
			} else {
				_Amt = _PromItem[0].DiscountValue;
			}
			break;

		case 'P':
			switch (_PromItem[0].PriceGroupNo) {
				case '0':
					_Amt = _PromItem[0].PosPrice;
					break;
				case '1':
					_Amt = _PromItem[0].PosPrice1;
					break;
				case '2':
					_Amt = _PromItem[0].PosPrice2;
					break;
				case '3':
					_Amt = _PromItem[0].PosPrice3;
					break;
				case '4':
					_Amt = _PromItem[0].PosPrice4;
					break;
				case '5':
					_Amt = _PromItem[0].PosPrice5;
					break;
				case '6':
					_Amt = _PromItem[0].PosPrice6;
					break;
				case '7':
					_Amt = _PromItem[0].PosPrice7;
					break;
				case '8':
					_Amt = _PromItem[0].PosPrice8;
					break;
				case '9':
					_Amt = _PromItem[0].PosPrice9;
					break;
				case '10':
					_Amt = _PromItem[0].PosPrice10;
					break;

				default:
					_Amt = _PromItem[0].PosPrice;
					break;
			}
			break;

		default:
			break;
	}

	_TempValue = {
		IsSuccess: 1,
		Type: _Type,
		Rate: _Rate,
		Amt: _Amt,
		DiscType: _DiscType,
		Remark: _Remark,
	};

	//  console.log('Discount', _TempValue)

	return _TempValue;
}

function GetUpSellPromItem(e, value) {
	//	console.log('Get UpSellPromItem', value);

	let _TempValue = {
		IsSuccess: 0,
		Type: '',
		Rate: 0,
		Amt: 0,
		DiscType: '',
		Remark: '',
	};

	let _Rate = 0;
	let _Amt = 0;
	let _Type = '';
	let _DiscType = '';
	let _Remark = '';

	switch (value.UpSellData.DiscountType) {
		case 'P':
			_Rate = value.UpSellData.DiscountValue;
			break;
		case 'V':
			_Amt = value.UpSellData.DiscountValue;
			break;

		default:
			break;
	}

	_TempValue = {
		IsSuccess: 1,
		Type: 'D',
		Rate: _Rate,
		Amt: _Amt,
		DiscType: value.UpSellData.DiscountType,
		Remark: value.UpSellData.DiscountRemark,
	};

	//	console.log('UpSell Discount', _TempValue);

	return _TempValue;
}

export function UpdateSelectedPrice(zType, zValue) {
	let _PriceGroupNo = zValue.PriceGroupNo;
	// let _ServiceCode = zValue.ServiceCode
	// let _Type = zType

	let _Gb = getGlobal();

	//	console.log('Update Selected Price', _PriceGroupNo);

	lod.map(_Gb.ItemInfo, val => {
		UpdateValue(_PriceGroupNo, 'PosPrice', val);
	});

	lod.map(_Gb.ItemInfoAll, val => {
		UpdateValue(_PriceGroupNo, 'PosPrice', val);
	});

	lod.map(_Gb.ItemAll, val => {
		UpdateValue(_PriceGroupNo, 'PosPrice', val);
	});

	lod.map(_Gb.SubItemInfo, val => {
		UpdateValue(_PriceGroupNo, 'PosPrice', val);
	});
	//	console.log('first-->', _Gb.ModifierDetailsInfo);
	//console.log('first--> Price Group', _PriceGroupNo);
	if (_PriceGroupNo !== '0') {
		lod.map(_Gb.ModifierDetailsInfo, val => {
			//	console.log('Not Correct');
			UpdateValue(_PriceGroupNo, 'UnitPrice', val);
		});
	}

	let _New = [];
	/* lod.map(_Gb.ModifierDetailsInfo, val => {
		let _data = lod.filter(_Gb.ItemInfoAll, q => q.Code === val.Code);
		console.log('Modifier-Code', val.Code);
		console.log('Modifier', _data);
		if (_data.length != 0) {
			// lod.set(val, "UnitPrice", _data[0].PosPrice);
			// let _data1 = _data[0]

			// if (val.Code === "Z003B") {
			// console.log("object", _data[0]);
			// console.log("Price Group", _PriceGroupNo);
			// }

			UpdateValue(_PriceGroupNo, 'UnitPrice', val, _data[0], 1);
		}
	}); */

	//console.log('New Modifier', _Gb.ModifierDetailsInfo);
}

export function UpdateLanguage(zCode) {
	let _Gb = getGlobal();

	lod.map(_Gb.ItemInfo, val => {
		UpdateLanguageValue(zCode, 'MenuName', val);
	});
	lod.map(_Gb.ItemInfoAll, val => {
		UpdateLanguageValue(zCode, 'MenuName', val);
	});
	lod.map(_Gb.ClassGroupInfo, val => {
		UpdateLanguageValue(zCode, 'Name', val);
	});
	lod.map(_Gb.ClassDetailsInfo, val => {
		UpdateLanguageValue(zCode, 'Name', val);
	});
	lod.map(_Gb.ModifierGroupInfo, val => {
		UpdateLanguageValue(zCode, 'Name', val);
	});
	lod.map(_Gb.ModifierDetailsInfo, val => {
		UpdateLanguageValue(zCode, 'Name', val);
	});

	lod.map(_Gb.ServiceInfo, val => {
		UpdateLanguageValue(zCode, 'Name', val);
	});
	lod.map(_Gb.CondimentGroupInfo, val => {
		UpdateLanguageValue(zCode, 'Name', val);
	});
	lod.map(_Gb.CondimentGroupDetails, val => {
		UpdateLanguageValue(zCode, 'Name', val);
	});
}
function UpdateValue(zPriceGroupNo, zName, val, NewVal, zType) {
	switch (zPriceGroupNo) {
		case '1':
			if (zType === 1) {
				lod.set(val, zName, NewVal.PosPrice1);
			} else {
				lod.set(val, zName, val.PosPrice1);
			}
			return val;
		case '2':
			if (zType === 1) {
				lod.set(val, zName, NewVal.PosPrice2);
			} else {
				lod.set(val, zName, val.PosPrice2);
			}
			return val;
		case '3':
			if (zType === 1) {
				lod.set(val, zName, NewVal.PosPrice3);
			} else {
				lod.set(val, zName, val.PosPrice3);
			}
			return val;
		case '4':
			if (zType === 1) {
				lod.set(val, zName, NewVal.PosPrice4);
			} else {
				lod.set(val, zName, val.PosPrice4);
			}
			return val;
		case '5':
			if (zType === 1) {
				lod.set(val, zName, NewVal.PosPrice5);
			} else {
				lod.set(val, zName, val.PosPrice5);
			}
			return val;
		case '6':
			if (zType === 1) {
				lod.set(val, zName, NewVal.PosPrice6);
			} else {
				lod.set(val, zName, val.PosPrice6);
			}
			return val;
		case '7':
			if (zType === 1) {
				lod.set(val, zName, NewVal.PosPrice7);
			} else {
				lod.set(val, zName, val.PosPrice7);
			}
			return val;
		case '8':
			if (zType === 1) {
				lod.set(val, zName, NewVal.PosPrice8);
			} else {
				lod.set(val, zName, val.PosPrice8);
			}
			return val;
		case '9':
			if (zType === 1) {
				lod.set(val, zName, NewVal.PosPrice9);
			} else {
				lod.set(val, zName, val.PosPrice9);
			}
			return val;
		case '10':
			if (zType === 1) {
				lod.set(val, zName, NewVal.PosPrice10);
			} else {
				lod.set(val, zName, val.PosPrice10);
			}
			return val;
		default:
			if (zType === 1) {
				lod.set(val, zName, NewVal.DefPrice);
			} else {
				lod.set(val, zName, val.DefPrice);
			}

			return val;
	}
}

function UpdateLanguageValue(zLanguageNo, zName, val) {
	switch (zLanguageNo) {
		case '1':
			lod.set(val, zName, val.Name1);
			return val;
		default:
			lod.set(val, zName, val.DefName);
			return val;
	}
}

export function GetAddressDistance(zType, zAddress) {
	let _Gb = getGlobal();

	//  console.log("Address", zAddress);
	Geocode.setApiKey(_Gb.WebInfo.GoogleApiKey);
	Geocode.setLanguage('en');
	Geocode.setRegion('my');
	Geocode.setLocationType('ROOFTOP');
	Geocode.enableDebug();

	let _Rs = {};
	let _Gb2 = _Gb.WebInfo;

	return new Promise(resolve => {
		Geocode.fromAddress(zAddress).then(res => {
			//  console.log("Response", res);

			if (res.status === 'OK') {
				const { lat, lng } = res.results[0].geometry.location;
				//  console.log(lat, lng);

				_Rs = { lat: lat, lng: lng };
				switch (zType) {
					case 'F':
						lod.set(_Gb2, 'FromAddress_lat', _Rs.lat);
						lod.set(_Gb2, 'FromAddress_lng', _Rs.lng);
					case 'T':
						lod.set(_Gb2, 'ToAddress_lat', _Rs.lat);
						lod.set(_Gb2, 'ToAddress_lng', _Rs.lng);
					default:
						break;
				}
				//  console.log("Return", _Rs);
				resolve(_Rs);
			}
		});
	});
}

export function GetUpSell() {
	let _Gb = getGlobal();

	let _UpSellMap = _Gb.UpSellMap;
	let _UpSellCondition = _Gb.UpSellCondition;
	let _Data = _Gb.LiveData.BillDetails;

	//	console.log('Map', _UpSellMap);
	//	console.log('Condition', _UpSellCondition);
	//	console.log('Data', _Data);

	// Find anf Group MajorGroup in the billDetails
	const _MajorGroup = lod(_Data)
		.groupBy('ItemInfo.MajorGroupId')
		.map((items, MajorGroupId) => ({
			MajorGroup: MajorGroupId,
		}))
		.value();

	//	console.log('Got MajorGroup', _MajorGroup);
	//	console.log('New Arr', _MajorGroup[0].MajorGroup);

	let _Match0 = '-';
	let _Match1 = '-';
	let _Match2 = '-';
	let _Match3 = '-';
	let _Match4 = '-';
	let _Match5 = '-';

	for (let i = 0; i < _MajorGroup.length; i++) {
		switch (i) {
			case 0:
				_Match1 = _MajorGroup[i].MajorGroup;
				break;
			case 1:
				_Match2 = _MajorGroup[i].MajorGroup;
				break;
			case 2:
				_Match3 = _MajorGroup[i].MajorGroup;
				break;
			case 3:
				_Match4 = _MajorGroup[i].MajorGroup;
				break;
			case 4:
				_Match5 = _MajorGroup[i].MajorGroup;
				break;
			default:
				break;
		}
	}

	// Match Condition

	let _Map = lod.filter(
		_UpSellCondition,
		q =>
			q.MajorGroupId1 === _Match1 &&
			q.MajorGroupId2 === _Match2 &&
			q.MajorGroupId3 === _Match3 &&
			q.MajorGroupId4 === _Match4 &&
			q.MajorGroupId5 === _Match5
	);

	//	console.log('final filter', _Map);

	let _UpSellData = [];

	if (_Map.length === 0) {
		return _UpSellData;
	}

	_UpSellData = lod.filter(_UpSellMap, q => q.UpCode === _Map[0].UpCode);

	//	console.log('Last', _UpSellData);

	return _UpSellData;
}

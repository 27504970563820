import React, { Component, getGlobal, setGlobal } from "reactn";
import { CallPostApi, StartInit } from "../../functions/General";
import * as Ion from "@ionic/react";
import * as Antd from "antd";
import "../../Css/Style.css";
import Form from "antd/lib/form/Form";

export default class TestForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ReferNumber: null,
      MecId: null,
      Sign: null,
      PaymentID: null,
      PopUp: true,
    };
  }

  _Submit = async () => {
    let _Data = {
      Amount: "12.20",
      CustomerName: "Phang",
      CustomerEmail: "-",
      PaymentID: "2",
    };
    let _Value5 = { Type: "GETREV", Value: _Data };
    let _Result = await CallPostApi(_Value5);
    console.log("Hello", _Result);
    this.setState({
      Data: _Result.RevValue,
      ReferNumber: _Result.RevValue.Reference_Number,
      Sign: _Result.RevValue.Signature,
      PaymentID: _Result.RevValue.Payment_ID,
    });
    console.log(_Result);
  };

  componentDidMount() {
    //  console.log("object", this.global);
    this._Submit();
  }

  _RenderForm_UnUsed = () => {
    let _Style = { float: "right" };

    return (
      <Antd.Card title="Payment Info">
        <Antd.Form
          onFinish={() => console.log("Loggg")}
          action="https://staging-gateway.revpay.com.my/payment"
          acceptCharset="UTF-8"
        >
          <Ion.IonList>
            <Antd.Form.Item label="Merchant ID :">
              <Antd.Input
                type="text"
                name="Revpay_Merchant_ID"
                defaultValue="MER00000004045"
              />
            </Antd.Form.Item>

            <Antd.Form.Item label=" Reference No. :">
              <Antd.Input
                type="text"
                name="Reference_Number"
                defaultValue={this.state.ReferNumber}
              />
            </Antd.Form.Item>

            <Antd.Form.Item label=" Currency :">
              <Antd.Input type="text" name="Currency" defaultValue={"MYR"} />
            </Antd.Form.Item>

            <Antd.Form.Item label=" Amount :">
              <Antd.Input type="text" name="Amount" defaultValue={"12.20"} />
            </Antd.Form.Item>
          </Ion.IonList>

          <Antd.Input type="hidden" name="Payment_ID" defaultValue={"2"} />

          <Antd.Input
            type="hidden"
            name="Transaction_Description"
            defaultValue="INVOICE"
          />
          <Antd.Input
            type="hidden"
            name="Customer_Email"
            defaultValue="johndoe@bigcommerce.com"
          />
          <Antd.Input
            type="hidden"
            name="Customer_Contact"
            defaultValue={"0123456789"}
          />

          <Antd.Input
            type="hidden"
            name="Customer_IP"
            defaultValue="192.168.1.2"
          />
          <Antd.Input type="hidden" name="Key_Index" defaultValue={"1"} />

          <Antd.Input
            type="hidden"
            name="Signature"
            defaultValue={this.state.Sign}
          />

          <Antd.Input
            type="hidden"
            name="Return_URL"
            defaultValue="https://devwebsite.revpay.com.my/return"
          />

          <Antd.Button type="primary" htmlType="submit">
            Submit
          </Antd.Button>
        </Antd.Form>
      </Antd.Card>
    );
  };

  _RenderForm = () => {
    let _Style111 = { float: "right" };
    let _Style = {
      width: "100%",
      padding: "12px 20px",
      margin: "8px 0",
      display: "inline-block",
      border: "1px solid #ccc",
      "border-radius": "4px",
      "box-sizing": "border-box",
    };

    let _Button = {
      width: "100%",
      padding: "14px 20px",
      margin: "8px 0",
      border: "none",
      "border-radius": "4px",
      cursor: "pointer",
      "background-color": "#4CAF50",
      color: "white",
    };

    let _Div = { padding: "20px" };

    return (
      <Ion.IonModal isOpen={this.state.PopUp} backdropDismiss={false}>
        <Antd.Card title="Payment Info - RevPAY">
          <form
            method="post"
            action="https://staging-gateway.revpay.com.my/payment"
            acceptCharset="UTF-8"
          >
            <Ion.IonList>
              <div style={_Div}>
                <label style={this.global.App.TextGrayNormal}>
                  Merchant ID :
                </label>

                <input
                  style={_Style}
                  type="text"
                  name="Revpay_Merchant_ID"
                  defaultValue={this.state.Data.Revpay_Merchant_ID}
                  readonly="readonly"
                />

                <label style={this.global.App.TextGrayNormal}>
                  Reference No. :
                </label>

                <input
                  style={_Style}
                  type="text"
                  name="Reference_Number"
                  defaultValue={this.state.ReferNumber}
                  readonly="readonly"
                />

                <label style={this.global.App.TextGrayNormal}>Currency :</label>
                <input
                  style={_Style}
                  type="text"
                  name="Currency"
                  value="MYR"
                  readonly="readonly"
                />

                <label style={this.global.App.TextGrayNormal}>Amount :</label>
                <input
                  style={_Style}
                  type="text"
                  name="Amount"
                  defaultValue="12.20"
                  readonly="readonly"
                />
                <label style={this.global.App.TextGrayNormal}>
                  Payment ID :
                </label>
                <input
                  style={_Style}
                  type="text"
                  name="Payment_ID"
                  defaultValue={this.state.PaymentID}
                  readonly="readonly"
                />
              </div>
            </Ion.IonList>

            <input
              type="hidden"
              name="Transaction_Description"
              defaultValue="INVOICE"
            />
            <input
              type="hidden"
              name="Customer_Email"
              defaultValue="johndoe@bigcommerce.com"
            />
            <input
              type="hidden"
              name="Customer_Contact"
              defaultValue={"0123456789"}
            />
            <input
              type="hidden"
              name="Customer_Name"
              defaultValue={this.state.Data.Customer_Name}
            />
            <input
              type="hidden"
              name="Customer_IP"
              defaultValue="192.168.1.2"
            />
            <input type="hidden" name="Key_Index" defaultValue={"1"} />

            <input
              type="hidden"
              name="Signature"
              defaultValue={this.state.Sign}
            />

            <input
              type="hidden"
              name="Return_URL"
              defaultValue="https://devwebsite.revpay.com.my/return"
            />

            <input style={_Button} defaultValue="Submit" type="submit" />
          </form>
        </Antd.Card>
      </Ion.IonModal>
    );
  };

  
  render() {
    if (this.state.Sign === null) {
      return null;
    }

    return <div>{this._RenderForm()}</div>;
  }
}

import React, { Component } from 'reactn';
import * as lod from 'lodash';
import { Drawer, Input, Modal, Form, Button, Space, InputNumber } from 'antd';

export default class TestUpSell extends Component {
	constructor(props) {
		super(props);

		let _Data = {
			Bills: {
				ServiceCode: 'D',
				SelectedServiceCode: 'D',
				TableCode: '25',
				TokenId: 'Z325',
				Subtotal: 15,
				DiscAmt: 0,
				Qty: 3,
				GrossAmt: 15,
				TaxAmt: 0.8999999999999999,
				SvcAmt: 1.5000000000000002,
				Nett: 17.400000000000002,
				Adj: 0,
				Rounded: 17.4,
				DelAmt: 0,
				TotalAmt: 17.4,
			},
			BillDetails: [
				{
					MenuCode: 'F02',
					MenuName: 'Greek Salad',
					Qty: 1,
					UnitPrice: 6,
					Subtotal: 6,
					LineDiscRate: 0,
					LineDiscAmt: 0,
					LineDiscRemark: '',
					TaxRate: 6,
					GrossAmt: 6,
					TaxAmt: 0.36,
					SvcRate: 10,
					SvcTaxRate: 0,
					SvcAmt: 0.6000000000000001,
					Nett: 6.96,
					Condiment: '',
					Modifier: [],
					ItemInfo: {
						ImageName:
							'http://localhost:51927/Qr/ProfileData/VS004/ImageFolder/Salad2.jpg',
						ImageName2x:
							'http://localhost:51927/Qr/ProfileData/VS004/ImageFolder/Salad2.jpg',
						ImageName3x:
							'http://localhost:51927/Qr/ProfileData/VS004/ImageFolder/sample1.jpg',
						DefPrice: 6,
						MenuSearch: 'F02 GREEK SALAD',
						Code: 'F02',
						Name: 'Greek Salad',
						DefName: 'Greek Salad',
						OutStock: '@@',
						ClassType: '1',
						ClassCode: 'F1001',
						ClassName: 'Appetizer & Soup',
						MajorGroupId: '1',
						MajorGroupName: 'Food',
						ClassTypeName: 'Food',
						MenuCode: 'F02',
						MenuName: 'Greek Salad',
						Name1: '简体中文测试',
						Name2: 'Greek Salad',
						Name3: 'Greek Salad',
						PosPrice: 6,
						PosPrice1: 5.4,
						PosPrice2: 4.8,
						PosPrice3: 4.2,
						Expr3: '1',
						RefPos: 'DEF',
						PosGroupId: 'S',
						TaxCode: 'ST',
						TaxName: 'Service Tax',
						TaxType: 'S',
						TaxRate: 6,
						SvcName: '10% Service Charge',
						SvcRate: 10,
						SvcCode: 'SVC01',
						ImageName1: null,
						ModifierMapCode: null,
						SvcTaxCode: 'SR',
						SvcTaxRate: 6,
						MaxPrice: 0,
						MinPrice: 0,
						IsNoStk: 0,
						TodateStkBalance: 0,
						DailyStkCount: 0,
						ImageName1x: 'Salad2.jpg',
						ImageName2x1: 'Salad2.jpg',
						ImageName3x1: 'sample1.jpg',
						KitGroupP: 'K2',
						KitGroupO: 'K2',
						KitGroupR: 'K2',
						KitGroupV: 'K2',
						KitGroupN: 'K2',
						RvcGroupCode: 'ALL',
						PosPrice10: 6,
						PosPrice9: 6,
						PosPrice8: 6,
						PosPrice7: 6,
						PosPrice6: 6,
						PosPrice5: 2.99,
						PosPrice4: 3,
						PluCode: 'F02',
						RefDesc: 'XXXXXXXXXXXXXXXXXXOXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
						Expr4: 'Food',
						PeriodCode: 'ALL',
						ComboQty: 0,
						PeriodName: 'ALL Day',
						DateFrom: '2001-01-01T00:00:00',
						DateTo: '2040-12-31T00:00:00',
						TimeFrom: '2019-09-01T00:00:00',
						TimeTo: '2019-09-01T23:59:00',
						DayFactor: 'OOOOOOO',
						IsComboSet: 0,
						ComboPeriodCode: null,
						LongDesc: 'Greek Salad',
						ModifierQty: 0,
						IsModifier: 0,
						ClassCode1: 'F1001',
						ClassCode2: '',
						ClassCode3: ' ',
						ClassCode4: ' ',
						ClassCode5: ' ',
						ClassCode6: ' ',
						ClassCode7: ' ',
						ClassCode8: ' ',
						ClassCode9: ' ',
						ClassCode10: ' ',
						IsModiSum: 0,
						AddonMenuGroupCode: '1',
						IsCondiment: 0,
						AppCode: 'A05',
						FontName: 'Tahoma',
						FontSize: 10,
						IsBold: 0,
						ImageAlign: 0,
						ForeColor: '-1',
						BackColor: '-16768928',
						TextAlign: 2,
						ImageFile1x: null,
						ImageTest: null,
						CurrentStkCount: 0,
						IsProm: 0,
						MenuCaption: null,
						MenuCaption1: null,
						MenuCaption3: null,
						MenuCaption2: null,
						IsDisable: 0,
						IsCheck: 0,
						IsQrDisable: 0,
						IsSubUom: 0,
						IsStock: 0,
						UomQty: 1,
						StockCode: 'F02',
						LastPurCreditorCode: null,
						LastPurPrice: 0,
						CreditorCode: null,
						InputTaxCode: null,
						UomCode: null,
						RefStk: null,
						LastPurDate: null,
						ButtonIndex: 0,
						IsForceCondiment: 0,
						IsStockUpdate: 0,
					},
					ItemInfo2: [
						{
							ImageName:
								'http://localhost:51927/Qr/ProfileData/VS004/ImageFolder/Salad2.jpg',
							ImageName2x:
								'http://localhost:51927/Qr/ProfileData/VS004/ImageFolder/Salad2.jpg',
							ImageName3x:
								'http://localhost:51927/Qr/ProfileData/VS004/ImageFolder/sample1.jpg',
							DefPrice: 6,
							MenuSearch: 'F02 GREEK SALAD',
							Code: 'F02',
							Name: 'Greek Salad',
							DefName: 'Greek Salad',
							OutStock: '@@',
							ClassType: '1',
							ClassCode: 'F1001',
							ClassName: 'Appetizer & Soup',
							MajorGroupId: '1',
							MajorGroupName: 'Food',
							ClassTypeName: 'Food',
							MenuCode: 'F02',
							MenuName: 'Greek Salad',
							Name1: '简体中文测试',
							Name2: 'Greek Salad',
							Name3: 'Greek Salad',
							PosPrice: 6,
							PosPrice1: 5.4,
							PosPrice2: 4.8,
							PosPrice3: 4.2,
							Expr3: '1',
							RefPos: 'DEF',
							PosGroupId: 'S',
							TaxCode: 'ST',
							TaxName: 'Service Tax',
							TaxType: 'S',
							TaxRate: 6,
							SvcName: '10% Service Charge',
							SvcRate: 10,
							SvcCode: 'SVC01',
							ImageName1: null,
							ModifierMapCode: null,
							SvcTaxCode: 'SR',
							SvcTaxRate: 6,
							MaxPrice: 0,
							MinPrice: 0,
							IsNoStk: 0,
							TodateStkBalance: 0,
							DailyStkCount: 0,
							ImageName1x: 'Salad2.jpg',
							ImageName2x1: 'Salad2.jpg',
							ImageName3x1: 'sample1.jpg',
							KitGroupP: 'K2',
							KitGroupO: 'K2',
							KitGroupR: 'K2',
							KitGroupV: 'K2',
							KitGroupN: 'K2',
							RvcGroupCode: 'ALL',
							PosPrice10: 6,
							PosPrice9: 6,
							PosPrice8: 6,
							PosPrice7: 6,
							PosPrice6: 6,
							PosPrice5: 2.99,
							PosPrice4: 3,
							PluCode: 'F02',
							RefDesc: 'XXXXXXXXXXXXXXXXXXOXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
							Expr4: 'Food',
							PeriodCode: 'ALL',
							ComboQty: 0,
							PeriodName: 'ALL Day',
							DateFrom: '2001-01-01T00:00:00',
							DateTo: '2040-12-31T00:00:00',
							TimeFrom: '2019-09-01T00:00:00',
							TimeTo: '2019-09-01T23:59:00',
							DayFactor: 'OOOOOOO',
							IsComboSet: 0,
							ComboPeriodCode: null,
							LongDesc: 'Greek Salad',
							ModifierQty: 0,
							IsModifier: 0,
							ClassCode1: 'F1001',
							ClassCode2: '',
							ClassCode3: ' ',
							ClassCode4: ' ',
							ClassCode5: ' ',
							ClassCode6: ' ',
							ClassCode7: ' ',
							ClassCode8: ' ',
							ClassCode9: ' ',
							ClassCode10: ' ',
							IsModiSum: 0,
							AddonMenuGroupCode: '1',
							IsCondiment: 0,
							AppCode: 'A05',
							FontName: 'Tahoma',
							FontSize: 10,
							IsBold: 0,
							ImageAlign: 0,
							ForeColor: '-1',
							BackColor: '-16768928',
							TextAlign: 2,
							ImageFile1x: null,
							ImageTest: null,
							CurrentStkCount: 0,
							IsProm: 0,
							MenuCaption: null,
							MenuCaption1: null,
							MenuCaption3: null,
							MenuCaption2: null,
							IsDisable: 0,
							IsCheck: 0,
							IsQrDisable: 0,
							IsSubUom: 0,
							IsStock: 0,
							UomQty: 1,
							StockCode: 'F02',
							LastPurCreditorCode: null,
							LastPurPrice: 0,
							CreditorCode: null,
							InputTaxCode: null,
							UomCode: null,
							RefStk: null,
							LastPurDate: null,
							ButtonIndex: 0,
							IsForceCondiment: 0,
							IsStockUpdate: 0,
						},
					],
					Remark: '',
					IsModifier: 0,
					IsSubItem: 0,
					TaxCode: 'ST',
					SvcTaxCode: 'SR',
					SvcCode: 'SVC01',
					ComboSet: [],
				},
				{
					MenuCode: 'F06',
					MenuName: 'Chorizo Chopper Salad',
					Qty: 1,
					UnitPrice: 8,
					Subtotal: 8,
					LineDiscRate: 0,
					LineDiscAmt: 0,
					LineDiscRemark: '',
					TaxRate: 6,
					GrossAmt: 8,
					TaxAmt: 0.48,
					SvcRate: 10,
					SvcTaxRate: 0,
					SvcAmt: 0.8,
					Nett: 9.280000000000001,
					Condiment: '',
					Modifier: [],
					ItemInfo: {
						ImageName:
							'http://localhost:51927/Qr/ProfileData/VS004/ImageFolder/Salad5.jpg',
						ImageName2x:
							'http://localhost:51927/Qr/ProfileData/VS004/ImageFolder/Salad5.jpg',
						ImageName3x:
							'http://localhost:51927/Qr/ProfileData/VS004/ImageFolder/sample1.jpg',
						DefPrice: 8,
						MenuSearch: 'F06 CHORIZO CHOPPER SALAD',
						Code: 'F06',
						Name: 'Chorizo Chopper Salad',
						DefName: 'Chorizo Chopper Salad',
						OutStock: '@@',
						ClassType: '1',
						ClassCode: 'F1001',
						ClassName: 'Appetizer & Soup',
						MajorGroupId: '1',
						MajorGroupName: 'Food',
						ClassTypeName: 'Food',
						MenuCode: 'F06',
						MenuName: 'Chorizo Chopper Salad',
						Name1: '简体中文测试',
						Name2: 'Chorizo Chopper Salad',
						Name3: 'Chorizo Chopper Salad',
						PosPrice: 8,
						PosPrice1: 7.2,
						PosPrice2: 6.4,
						PosPrice3: 5.6,
						Expr3: '1',
						RefPos: 'DEF',
						PosGroupId: 'S',
						TaxCode: 'ST',
						TaxName: 'Service Tax',
						TaxType: 'S',
						TaxRate: 6,
						SvcName: '10% Service Charge',
						SvcRate: 10,
						SvcCode: 'SVC01',
						ImageName1: null,
						ModifierMapCode: null,
						SvcTaxCode: 'SR',
						SvcTaxRate: 6,
						MaxPrice: 0,
						MinPrice: 0,
						IsNoStk: 0,
						TodateStkBalance: 0,
						DailyStkCount: 0,
						ImageName1x: 'Salad5.jpg',
						ImageName2x1: 'Salad5.jpg',
						ImageName3x1: 'sample1.jpg',
						KitGroupP: 'K2',
						KitGroupO: 'K2',
						KitGroupR: 'K2',
						KitGroupV: 'K2',
						KitGroupN: 'K2',
						RvcGroupCode: 'ALL',
						PosPrice10: 8,
						PosPrice9: 8,
						PosPrice8: 8,
						PosPrice7: 8,
						PosPrice6: 8,
						PosPrice5: 2.99,
						PosPrice4: 4,
						PluCode: 'F06',
						RefDesc: 'XXXXXXXXXXXXXXXXXXOXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
						Expr4: 'Food',
						PeriodCode: 'ALL',
						ComboQty: 0,
						PeriodName: 'ALL Day',
						DateFrom: '2001-01-01T00:00:00',
						DateTo: '2040-12-31T00:00:00',
						TimeFrom: '2019-09-01T00:00:00',
						TimeTo: '2019-09-01T23:59:00',
						DayFactor: 'OOOOOOO',
						IsComboSet: 0,
						ComboPeriodCode: null,
						LongDesc: 'Chorizo Chopper Salad',
						ModifierQty: 0,
						IsModifier: 0,
						ClassCode1: 'F1001',
						ClassCode2: '',
						ClassCode3: ' ',
						ClassCode4: ' ',
						ClassCode5: ' ',
						ClassCode6: ' ',
						ClassCode7: ' ',
						ClassCode8: ' ',
						ClassCode9: ' ',
						ClassCode10: ' ',
						IsModiSum: 0,
						AddonMenuGroupCode: '1',
						IsCondiment: 0,
						AppCode: 'A05',
						FontName: 'Tahoma',
						FontSize: 10,
						IsBold: 0,
						ImageAlign: 0,
						ForeColor: '-1',
						BackColor: '-16768928',
						TextAlign: 2,
						ImageFile1x: null,
						ImageTest: null,
						CurrentStkCount: 0,
						IsProm: 0,
						MenuCaption: null,
						MenuCaption1: null,
						MenuCaption3: null,
						MenuCaption2: null,
						IsDisable: 0,
						IsCheck: 0,
						IsQrDisable: 0,
						IsSubUom: 0,
						IsStock: 0,
						UomQty: 1,
						StockCode: 'F06',
						LastPurCreditorCode: null,
						LastPurPrice: 0,
						CreditorCode: null,
						InputTaxCode: null,
						UomCode: null,
						RefStk: null,
						LastPurDate: null,
						ButtonIndex: 0,
						IsForceCondiment: 0,
						IsStockUpdate: 0,
					},
					ItemInfo2: [
						{
							ImageName:
								'http://localhost:51927/Qr/ProfileData/VS004/ImageFolder/Salad5.jpg',
							ImageName2x:
								'http://localhost:51927/Qr/ProfileData/VS004/ImageFolder/Salad5.jpg',
							ImageName3x:
								'http://localhost:51927/Qr/ProfileData/VS004/ImageFolder/sample1.jpg',
							DefPrice: 8,
							MenuSearch: 'F06 CHORIZO CHOPPER SALAD',
							Code: 'F06',
							Name: 'Chorizo Chopper Salad',
							DefName: 'Chorizo Chopper Salad',
							OutStock: '@@',
							ClassType: '1',
							ClassCode: 'F1001',
							ClassName: 'Appetizer & Soup',
							MajorGroupId: '1',
							MajorGroupName: 'Food',
							ClassTypeName: 'Food',
							MenuCode: 'F06',
							MenuName: 'Chorizo Chopper Salad',
							Name1: '简体中文测试',
							Name2: 'Chorizo Chopper Salad',
							Name3: 'Chorizo Chopper Salad',
							PosPrice: 8,
							PosPrice1: 7.2,
							PosPrice2: 6.4,
							PosPrice3: 5.6,
							Expr3: '1',
							RefPos: 'DEF',
							PosGroupId: 'S',
							TaxCode: 'ST',
							TaxName: 'Service Tax',
							TaxType: 'S',
							TaxRate: 6,
							SvcName: '10% Service Charge',
							SvcRate: 10,
							SvcCode: 'SVC01',
							ImageName1: null,
							ModifierMapCode: null,
							SvcTaxCode: 'SR',
							SvcTaxRate: 6,
							MaxPrice: 0,
							MinPrice: 0,
							IsNoStk: 0,
							TodateStkBalance: 0,
							DailyStkCount: 0,
							ImageName1x: 'Salad5.jpg',
							ImageName2x1: 'Salad5.jpg',
							ImageName3x1: 'sample1.jpg',
							KitGroupP: 'K2',
							KitGroupO: 'K2',
							KitGroupR: 'K2',
							KitGroupV: 'K2',
							KitGroupN: 'K2',
							RvcGroupCode: 'ALL',
							PosPrice10: 8,
							PosPrice9: 8,
							PosPrice8: 8,
							PosPrice7: 8,
							PosPrice6: 8,
							PosPrice5: 2.99,
							PosPrice4: 4,
							PluCode: 'F06',
							RefDesc: 'XXXXXXXXXXXXXXXXXXOXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
							Expr4: 'Food',
							PeriodCode: 'ALL',
							ComboQty: 0,
							PeriodName: 'ALL Day',
							DateFrom: '2001-01-01T00:00:00',
							DateTo: '2040-12-31T00:00:00',
							TimeFrom: '2019-09-01T00:00:00',
							TimeTo: '2019-09-01T23:59:00',
							DayFactor: 'OOOOOOO',
							IsComboSet: 0,
							ComboPeriodCode: null,
							LongDesc: 'Chorizo Chopper Salad',
							ModifierQty: 0,
							IsModifier: 0,
							ClassCode1: 'F1001',
							ClassCode2: '',
							ClassCode3: ' ',
							ClassCode4: ' ',
							ClassCode5: ' ',
							ClassCode6: ' ',
							ClassCode7: ' ',
							ClassCode8: ' ',
							ClassCode9: ' ',
							ClassCode10: ' ',
							IsModiSum: 0,
							AddonMenuGroupCode: '1',
							IsCondiment: 0,
							AppCode: 'A05',
							FontName: 'Tahoma',
							FontSize: 10,
							IsBold: 0,
							ImageAlign: 0,
							ForeColor: '-1',
							BackColor: '-16768928',
							TextAlign: 2,
							ImageFile1x: null,
							ImageTest: null,
							CurrentStkCount: 0,
							IsProm: 0,
							MenuCaption: null,
							MenuCaption1: null,
							MenuCaption3: null,
							MenuCaption2: null,
							IsDisable: 0,
							IsCheck: 0,
							IsQrDisable: 0,
							IsSubUom: 0,
							IsStock: 0,
							UomQty: 1,
							StockCode: 'F06',
							LastPurCreditorCode: null,
							LastPurPrice: 0,
							CreditorCode: null,
							InputTaxCode: null,
							UomCode: null,
							RefStk: null,
							LastPurDate: null,
							ButtonIndex: 0,
							IsForceCondiment: 0,
							IsStockUpdate: 0,
						},
					],
					Remark: '',
					IsModifier: 0,
					IsSubItem: 0,
					TaxCode: 'ST',
					SvcTaxCode: 'SR',
					SvcCode: 'SVC01',
					ComboSet: [],
				},
				{
					MenuCode: 'B01-1',
					MenuName: 'TEST Cheese??',
					Qty: 1,
					UnitPrice: 1,
					Subtotal: 1,
					LineDiscRate: 0,
					LineDiscAmt: 0,
					LineDiscRemark: '',
					TaxRate: 6,
					GrossAmt: 1,
					TaxAmt: 0.06,
					SvcRate: 10,
					SvcTaxRate: 0,
					SvcAmt: 0.1,
					Nett: 1.1600000000000001,
					Condiment: '',
					Modifier: [],
					ItemInfo: {
						ImageName:
							'http://localhost:51927/Qr/ProfileData/VS004/ImageFolder/coke1.jpg',
						ImageName2x:
							'http://localhost:51927/Qr/ProfileData/VS004/ImageFolder/Cocacola-L.png',
						ImageName3x:
							'http://localhost:51927/Qr/ProfileData/VS004/ImageFolder/sample2.jpg',
						DefPrice: 1,
						MenuSearch: 'B01-1 TEST CHEESE??',
						Code: 'B01-1',
						Name: 'TEST Cheese??',
						DefName: 'TEST Cheese??',
						OutStock: '@@',
						ClassType: '2',
						ClassCode: 'B1001',
						ClassName: 'Soft Drinks',
						MajorGroupId: '2',
						MajorGroupName: 'Beverage',
						ClassTypeName: 'Beverage',
						MenuCode: 'B01-1',
						MenuName: 'TEST Cheese??',
						Name1: '简体中文测试',
						Name2: 'Coca-Cola',
						Name3: 'Coca-Cola                                         ',
						PosPrice: 1,
						PosPrice1: 1,
						PosPrice2: 1,
						PosPrice3: 1,
						Expr3: '2',
						RefPos: 'COMBO',
						PosGroupId: 'S',
						TaxCode: 'ST',
						TaxName: 'Service Tax',
						TaxType: 'S',
						TaxRate: 6,
						SvcName: '10% Service Charge',
						SvcRate: 10,
						SvcCode: 'SVC01',
						ImageName1: null,
						ModifierMapCode: null,
						SvcTaxCode: 'SR',
						SvcTaxRate: 6,
						MaxPrice: 0,
						MinPrice: 0,
						IsNoStk: 0,
						TodateStkBalance: 0,
						DailyStkCount: 0,
						ImageName1x: 'coke1.jpg',
						ImageName2x1: 'Cocacola-L.png',
						ImageName3x1: 'sample2.jpg',
						KitGroupP: 'K1',
						KitGroupO: 'K1',
						KitGroupR: 'K1',
						KitGroupV: 'K1',
						KitGroupN: 'K1',
						RvcGroupCode: 'ALL',
						PosPrice10: 1,
						PosPrice9: 1,
						PosPrice8: 1,
						PosPrice7: 1,
						PosPrice6: 1,
						PosPrice5: 2.99,
						PosPrice4: 1,
						PluCode: 'B01-1',
						RefDesc: 'XXXXXXXXXXOXXXXXXXOXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
						Expr4: 'Beverage',
						PeriodCode: 'ALL',
						ComboQty: 0,
						PeriodName: 'ALL Day',
						DateFrom: '2001-01-01T00:00:00',
						DateTo: '2040-12-31T00:00:00',
						TimeFrom: '2019-09-01T00:00:00',
						TimeTo: '2019-09-01T23:59:00',
						DayFactor: 'OOOOOOO',
						IsComboSet: 1,
						ComboPeriodCode: null,
						LongDesc: 'Coca-Cola11',
						ModifierQty: 0,
						IsModifier: 0,
						ClassCode1: ' ',
						ClassCode2: 'B1001',
						ClassCode3: '',
						ClassCode4: ' ',
						ClassCode5: '',
						ClassCode6: ' ',
						ClassCode7: ' ',
						ClassCode8: ' ',
						ClassCode9: ' ',
						ClassCode10: ' ',
						IsModiSum: 0,
						AddonMenuGroupCode: '1',
						IsCondiment: 1,
						AppCode: 'A05',
						FontName: 'Tahoma',
						FontSize: 10,
						IsBold: 0,
						ImageAlign: 0,
						ForeColor: '-1',
						BackColor: '-16768928',
						TextAlign: 2,
						ImageFile1x: null,
						ImageTest: null,
						CurrentStkCount: 0,
						IsProm: 0,
						MenuCaption: null,
						MenuCaption1: null,
						MenuCaption3: null,
						MenuCaption2: null,
						IsDisable: 0,
						IsCheck: 0,
						IsQrDisable: 0,
						IsSubUom: 0,
						IsStock: 0,
						UomQty: 1,
						StockCode: 'B01',
						LastPurCreditorCode: null,
						LastPurPrice: 0,
						CreditorCode: null,
						InputTaxCode: null,
						UomCode: null,
						RefStk: null,
						LastPurDate: null,
						ButtonIndex: 0,
						IsForceCondiment: 1,
						IsStockUpdate: 0,
					},
					ItemInfo2: [
						{
							ImageName:
								'http://localhost:51927/Qr/ProfileData/VS004/ImageFolder/coke1.jpg',
							ImageName2x:
								'http://localhost:51927/Qr/ProfileData/VS004/ImageFolder/Cocacola-L.png',
							ImageName3x:
								'http://localhost:51927/Qr/ProfileData/VS004/ImageFolder/sample2.jpg',
							DefPrice: 1,
							MenuSearch: 'B01-1 TEST CHEESE??',
							Code: 'B01-1',
							Name: 'TEST Cheese??',
							DefName: 'TEST Cheese??',
							OutStock: '@@',
							ClassType: '2',
							ClassCode: 'B1001',
							ClassName: 'Soft Drinks',
							MajorGroupId: '2',
							MajorGroupName: 'Beverage',
							ClassTypeName: 'Beverage',
							MenuCode: 'B01-1',
							MenuName: 'TEST Cheese??',
							Name1: '简体中文测试',
							Name2: 'Coca-Cola',
							Name3: 'Coca-Cola                                         ',
							PosPrice: 1,
							PosPrice1: 1,
							PosPrice2: 1,
							PosPrice3: 1,
							Expr3: '2',
							RefPos: 'COMBO',
							PosGroupId: 'S',
							TaxCode: 'ST',
							TaxName: 'Service Tax',
							TaxType: 'S',
							TaxRate: 6,
							SvcName: '10% Service Charge',
							SvcRate: 10,
							SvcCode: 'SVC01',
							ImageName1: null,
							ModifierMapCode: null,
							SvcTaxCode: 'SR',
							SvcTaxRate: 6,
							MaxPrice: 0,
							MinPrice: 0,
							IsNoStk: 0,
							TodateStkBalance: 0,
							DailyStkCount: 0,
							ImageName1x: 'coke1.jpg',
							ImageName2x1: 'Cocacola-L.png',
							ImageName3x1: 'sample2.jpg',
							KitGroupP: 'K1',
							KitGroupO: 'K1',
							KitGroupR: 'K1',
							KitGroupV: 'K1',
							KitGroupN: 'K1',
							RvcGroupCode: 'ALL',
							PosPrice10: 1,
							PosPrice9: 1,
							PosPrice8: 1,
							PosPrice7: 1,
							PosPrice6: 1,
							PosPrice5: 2.99,
							PosPrice4: 1,
							PluCode: 'B01-1',
							RefDesc: 'XXXXXXXXXXOXXXXXXXOXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
							Expr4: 'Beverage',
							PeriodCode: 'ALL',
							ComboQty: 0,
							PeriodName: 'ALL Day',
							DateFrom: '2001-01-01T00:00:00',
							DateTo: '2040-12-31T00:00:00',
							TimeFrom: '2019-09-01T00:00:00',
							TimeTo: '2019-09-01T23:59:00',
							DayFactor: 'OOOOOOO',
							IsComboSet: 1,
							ComboPeriodCode: null,
							LongDesc: 'Coca-Cola11',
							ModifierQty: 0,
							IsModifier: 0,
							ClassCode1: ' ',
							ClassCode2: 'B1001',
							ClassCode3: '',
							ClassCode4: ' ',
							ClassCode5: '',
							ClassCode6: ' ',
							ClassCode7: ' ',
							ClassCode8: ' ',
							ClassCode9: ' ',
							ClassCode10: ' ',
							IsModiSum: 0,
							AddonMenuGroupCode: '1',
							IsCondiment: 1,
							AppCode: 'A05',
							FontName: 'Tahoma',
							FontSize: 10,
							IsBold: 0,
							ImageAlign: 0,
							ForeColor: '-1',
							BackColor: '-16768928',
							TextAlign: 2,
							ImageFile1x: null,
							ImageTest: null,
							CurrentStkCount: 0,
							IsProm: 0,
							MenuCaption: null,
							MenuCaption1: null,
							MenuCaption3: null,
							MenuCaption2: null,
							IsDisable: 0,
							IsCheck: 0,
							IsQrDisable: 0,
							IsSubUom: 0,
							IsStock: 0,
							UomQty: 1,
							StockCode: 'B01',
							LastPurCreditorCode: null,
							LastPurPrice: 0,
							CreditorCode: null,
							InputTaxCode: null,
							UomCode: null,
							RefStk: null,
							LastPurDate: null,
							ButtonIndex: 0,
							IsForceCondiment: 1,
							IsStockUpdate: 0,
						},
					],
					Remark: '',
					IsModifier: 0,
					IsSubItem: 0,
					TaxCode: 'ST',
					SvcTaxCode: 'SR',
					SvcCode: 'SVC01',
					ComboSet: [],
				},
			],
		};

		this.state = {
			Data: _Data,
		};
	}

	_TestUpSell = () => {
		let _UpSellMap = this.global.UpSellMap;
		let _UpSellCondition = this.global.UpSellCondition;

		console.log('Map', _UpSellMap);
		console.log('Condition', _UpSellCondition);
		console.log('Data', this.state.Data);

		let _Data = this.state.Data.BillDetails;

		//	console.log('Data', _Data[0].ItemInfo.MajorGroupId);

		var _MajorGroup = lod(_Data)
			.groupBy('ItemInfo.MajorGroupId')
			.map((items, MajorGroupId) => ({
				MajorGroup: MajorGroupId,
			}))
			.value();

		console.log('MajorGroup', _MajorGroup);
		console.log('New Arr', _MajorGroup[0].MajorGroup);

		let _Match0 = '-';
		let _Match1 = '-';
		let _Match2 = '-';
		let _Match3 = '-';
		let _Match4 = '-';
		let _Match5 = '-';

		for (let i = 0; i < _MajorGroup.length; i++) {
			switch (i) {
				case 0:
					_Match1 = _MajorGroup[i].MajorGroup;
					break;
				case 1:
					_Match2 = _MajorGroup[i].MajorGroup;
					break;
				case 2:
					_Match3 = _MajorGroup[i].MajorGroup;
					break;
				case 3:
					_Match4 = _MajorGroup[i].MajorGroup;
					break;
				case 4:
					_Match5 = _MajorGroup[i].MajorGroup;
					break;
				default:
					break;
			}
		}

		let _Map = lod.filter(
			_UpSellCondition,
			q =>
				q.MajorGroupId1 === _Match1 &&
				q.MajorGroupId2 === _Match2 &&
				q.MajorGroupId3 === _Match3 &&
				q.MajorGroupId4 === _Match4 &&
				q.MajorGroupId5 === _Match5
		);

		console.log('final filter', _Map);

		let _UpSellData = [];

		if (_Map.length === 0) {
			return _UpSellData;
		}

		_UpSellData = lod.filter(_UpSellMap, q => q.UpCode === _Map[0].UpCode);

		console.log('Last', _UpSellData);
	};

	render() {
		//	console.log('global', this.global);

		return (
			<div>
				TestUpSell
				<Button onClick={() => this._TestUpSell()}>Take the Shot!</Button>
				<Input prefix="￥" suffix="RMB" />
				<InputNumber
					status="warning"
					//	autoFocus
					//placeholder="Enter Mobile No"
					//	prefix="$"
				/>
			</div>
		);
	}
}

import React, { Component } from "react";
import { GetQuotation } from "../../functions/Lalamove";

export default class ProcessQuotation extends Component {
  componentDidMount() {
    GetQuotation();
  }

  render() {
    return <div></div>;
  }
}

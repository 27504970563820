import React, { Component } from 'reactn';
import Main from './components/Main';
import { CallGetApi, CallPostApi, StartInit } from './functions/General';
import {
	Result as Res,
	Drawer,
	Modal,
	Spin,
	Button,
	Typography,
	Space,
} from 'antd';
import WebResult from './components/Web/WebResult';
import ViewOrder from './components/ViewOrder';
import TestUpSell from './components/TestUpSell';

export default class Init extends Component {
	constructor(props) {
		super(props);
		this.state = {
			IsDone: false,
			IsLoading: true,
			IsError: false,
			IsRetry: false,
		};
	}

	_RenderInit = async () => {
		let _ProfileCode = localStorage.getItem('ProfileCode');
		let _BasedUrl = localStorage.getItem('BasedUrl');
		let _AppMode = localStorage.getItem('ServiceCode');
		let _TableCode = localStorage.getItem('TableCode');
		let _TokenId = localStorage.getItem('Token');
		let _RvcCode = localStorage.getItem('RvcCode');

	//	console.log('ProfileCode', _ProfileCode);

		this.setGlobal({
			BasedUrl: _BasedUrl,
			ProfileCode: _ProfileCode,
		});

		let _Value2 = {
			TableCode: _TableCode,
			TokenId: _TokenId,
			ServiceCode: _AppMode,
			RvcCode: _RvcCode,
		};
		let _Value = { Type: 'QUERY', QueryType: 'VALIDATION', Value: _Value2 };

		//	console.log('object', _Value);

		let _Result = await CallPostApi(_Value);

		//	console.log('Return', _Result);

		if (_Result.IsSuccess === 1) {
			StartInit(_Result);
			this.setState({ IsDone: true, IsLoading: false, IsError: false });
		} else {
			this.setState({ IsError: true, ErrorMessage: _Result.ErrorMessage });
		}
	};

	componentDidMount() {
		this._RenderInit();
	}

	_RenderLoading = () => {
		const _Style = { 'text-align': 'center' };
		return (
			<Modal
				centered
				visible={this.state.IsLoading}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
				footer={false}
				closable={false}
				width={200}
			>
				<div style={_Style}>
					<Spin tip={'Loading...'} />
				</div>
			</Modal>
		);
	};

	_RenderError = () => {
		const _Style = { 'text-align': 'center' };
		const { Paragraph, Text } = Typography;

		return (
			<Modal
				centered
				visible={this.state.IsError}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
				footer={false}
				closable={false}
			>
				<Res
					status="warning"
					title={'Alert'}
					subTitle={''}
					extra={[
						<Button
							type="primary"
							key="Retry"
							size="large"
							block
							onClick={() => this.setState({ IsRetry: true })}
						>
							Retry
						</Button>,
					]}
				/>
				<div className="desc">
					<Paragraph>
						<Text strong style={{ color: 'red', fontSize: 16 }}>
							Message
						</Text>
					</Paragraph>
					<Paragraph>{this.state.ErrorMessage}</Paragraph>
				</div>
			</Modal>
		);
	};

	render() {
	//	console.log('Global-Return......', this.global);

		if (this.global.ReturnStatus !== undefined) {
			if (this.global.ReturnStatus.ModuleType === 'RETURN') {
			//	console.log('Global-Return', this.global);
				return <WebResult />;
			}
		}

		if (this.state.IsDone === true) {
			//  console.log("Global", this.global);
			return <Main />;
		}

		if (this.state.IsRetry === true) {
			this.setState({ IsRetry: false, IsLoading: true, IsError: false });
			this._RenderInit();
		}

		return (
			<div>
				{this._RenderLoading()}
				{this._RenderError()}
			</div>
		);
	}
}

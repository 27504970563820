import React, { Component } from 'reactn';
import * as Ion from '@ionic/react';
import '../Css/Style.css';
import PosOrder from './PosOrder';
import * as lod from 'lodash';
import {
	caretForward,
	arrowBack,
	cart,
	trashBin,
	reader,
	grid,
	person,
	menu,
	chevronBack,
	checkmark,
	caretBack,
	caretForwardCircle,
} from 'ionicons/icons';
import {
	Result as Res,
	Drawer,
	Modal,
	Spin,
	Button,
	Typography,
	Space,
} from 'antd';
import { CallGetApi, CallPostApi, ConvertFormat } from '../functions/General';
import Main from './Main';

export default class ViewOrder extends Component {
	constructor(props) {
		super(props);

		let _TableCode =
			this.global.LanguagePack[this.global.LanguageNo].LabelTable +
			' ' +
			localStorage.getItem('TableName');

		this.state = {
			IsSelectService: false,
			IsLoading: true,
			IsStart: false,
			IsError: false,
			TableCode: _TableCode,
		};
	}

	_FetchData = async () => {
		let _TableCode = localStorage.getItem('TableCode');
		let _TokenId = localStorage.getItem('Token');
		let _ServiceCode = localStorage.getItem('ServiceCode');

		//	console.log('ServiceCode', _ServiceCode);

		let _Value2 = { TableCode: _TableCode, TokenId: _TokenId };
		let _Value = { Type: 'READORDER', Value: _Value2, FetchType: 'D' };

		//	console.log('Global', this.global.Default.AppMode);
		//	console.log('Global-22', this.global);

		let _TrsNo7 = localStorage.getItem('TrsNo');
		//	console.log('TrsNo', _TrsNo7);

		if (this.global.Default.AppMode === 'O') {
			//   console.log("Hello",this.global);
			let _TrsNo3 = localStorage.getItem('TrsNo');
			_Value2 = { WebType: 'O', TrsNo: encodeURIComponent(_TrsNo3) };
			//  let _TrsNo = "A01#VS004-01#RVC-A#ONL#20211229104656#ONL0054";
			//  _Value2 = { WebType: "O", TrsNo: encodeURIComponent(_TrsNo) };
			_Value = { Type: 'READORDER', Value: _Value2, FetchType: 'D' };
		}

		if (this.global.Default.AppMode === 'D') {
			//   console.log("Hello",this.global);
			let _TrsNo2 = localStorage.getItem('TrsNo');
			_Value2 = {
				WebType: 'D',
				TrsNo: encodeURIComponent(_TrsNo2),
				TokenId: _TokenId,
				TableCode: _TableCode,
			};
			//  let _TrsNo = "A01#VS004-01#RVC-A#ONL#20211229104656#ONL0054";
			//  _Value2 = { WebType: "O", TrsNo: encodeURIComponent(_TrsNo) };
			_Value = { Type: 'READORDER', Value: _Value2, FetchType: 'D' };
		}

		//	console.log('Value', _Value);

		let _Result = await CallGetApi(_Value);
		//console.log('Return', _Result);
		if (_Result.IsSuccess === 1) {
			this.setState({ Data: _Result, IsError: false, IsLoading: false });
		} else {
			this.setState({
				Data: _Result,
				IsError: true,
				ErrorMessage: _Result.ErrorMessage,
				IsLoading: false,
			});
		}
	};

	_Action = (zType, zRow) => {
		let _Data = [];

		switch (zType) {
			case 'SHOWREMARK':
				if (this.state.Data === null || this.state.Data === undefined) {
					return null;
				}

				if (this.state.Data.items.length === 0) {
					return null;
				}

				_Data = this.state.Data.items;

				//	console.log('remark', _Data);

				if (_Data[zRow].Remark !== '') {
					return (
						<Ion.IonText color={this.global.Color}>
							<br />
							<span style={this.global.App.TextGrayNormal}>
								{_Data[zRow].Remark}
							</span>
						</Ion.IonText>
					);
				}
				break;

			case 'SHOWDISCOUNT':
				if (this.state.Data === null || this.state.Data === undefined) {
					return null;
				}

				if (this.state.Data.items.length === 0) {
					return null;
				}
				_Data = this.state.Data.items;

				if (_Data[zRow].LineDiscAmt !== 0) {
					return (
						<Ion.IonText color={this.global.Color}>
							<span>{_Data[zRow].LineDiscRmk}</span>
							<span className="MyDisplayRight2">
								{ConvertFormat(_Data[zRow].LineDiscAmt)}
							</span>
							<br />
						</Ion.IonText>
					);
				}
				break;

			case 'SHOWSERVICE':
				if (this.state.Data === null || this.state.Data === undefined) {
					return null;
				}

				if (this.state.Data.items.length === 0) {
					return null;
				}
				_Data = this.state.Data.items;
				if (_Data[zRow].ServiceRemark !== '') {
					return (
						<Ion.IonText color={this.global.Color}>
							<br />
							<span>{_Data[zRow].ServiceRemark}</span>
						</Ion.IonText>
					);
				}
				break;

			case 'SHOWCONDIMENTDETAILS':
				if (this.state.Data === null || this.state.Data === undefined) {
					return null;
				}

				if (this.state.Data.items.length === 0) {
					return null;
				}
				_Data = this.state.Data.items;
				if (_Data[zRow].Condiment !== '') {
					return (
						<Ion.IonText color={this.global.Color}>
							<br />
							<span style={this.global.App.TextRedNormal}>
								{_Data[zRow].Condiment}
							</span>
						</Ion.IonText>
					);
				}
				break;

			case 'SHOWCOMBOSET':
				if (this.state.Data === null || this.state.Data === undefined) {
					return null;
				}

				if (this.state.Data.items.length === 0) {
					return null;
				}
				_Data = this.state.Data.items;
				if (_Data[zRow].ComboSet !== undefined) {
					if (_Data[zRow].ComboSet.length !== 0) {
						let data = _Data[zRow].ComboSet;
						return (
							<Ion.IonText style={this.global.App.TextNormal}>
								<br />
								<Ion.IonList>
									{data.map((c, index) => (
										<div>
											<span>- {c.MenuName}</span>
											<br />
										</div>
									))}
								</Ion.IonList>
							</Ion.IonText>
						);
					}
				}
				break;

			case 'SHOWMODIFIERDETAILS':
				//	console.log(this.state.Data);

				if (this.state.Data === null || this.state.Data === undefined) {
					return null;
				}

				if (this.state.Data.items.length === 0) {
					return null;
				}
				_Data = this.state.Data.items;

				if (_Data[zRow].ModifierData !== undefined) {
					if (_Data[zRow].ModifierData.length !== 0) {
						let data = _Data[zRow].ModifierData;

						return (
							<Ion.IonText style={this.global.App.TextNormal}>
								<br />
								<Ion.IonList>
									{data.map((c, index) => (
										<div>
											<span>
												- {c.Qty} x {c.MenuName}
											</span>
											<span className="MyDisplayRight2">
												{ConvertFormat(c.Subtotal)}
											</span>
											<br />
										</div>
									))}
								</Ion.IonList>
							</Ion.IonText>
						);
					}
				}
				break;

			default:
				break;
		}
	};

	componentDidMount() {
		this._FetchData();
	}

	_RenderCartOrder = () => {
		let data = this.state.Data;

		//   console.log('data',data)
		if (data === null || data === undefined) {
			return null;
		}

		/*  if (this.global.Default.AppMode === "O") {
	  return this._RenderWebCartOrder();
	}
 */
		if (data.items === null) {
			return null;
		}

		if (data.IsSuccess === 0) {
			return null;
		}

		let _ShowLine = 'full';

		let _Header =
			'Order No. ' +
			this.state.Data.OrderNo +
			' / ' +
			this.state.Data.ServiceCode +
			'  ';

		return (
			<div>
				<Ion.IonList>
					<Ion.IonListHeader>
						<Ion.IonText color={this.global.Color}>Order Details</Ion.IonText>
					</Ion.IonListHeader>
					{data.items.map((c, index) => (
						<Ion.IonItemGroup>
							<Ion.IonItem lines={_ShowLine}>
								<Ion.IonLabel style={this.global.App.TextNormal}>
									<span style={this.global.App.TextGraySmall}>
										{_Header} {c.DateCreated}
									</span>
									<br />
									<span>
										{c.MenuCode} - {c.MenuName}
									</span>
									<span className="MyDisplayRight2">
										{c.Qty} x {ConvertFormat(c.UnitPrice)}
									</span>
									<br />
									<span></span>
									{this._Action('SHOWDISCOUNT', index)}
									<span className="MyDisplayRight2">
										{ConvertFormat(c.GrossAmt)}
									</span>
									{this._Action('SHOWSERVICE', index)}
									{this._Action('SHOWREMARK', index)}
									{this._Action('SHOWCOMBOSET', index)}
									{this._Action('SHOWCONDIMENTDETAILS', index)}
									{this._Action('SHOWMODIFIERDETAILS', index)}
								</Ion.IonLabel>
							</Ion.IonItem>
						</Ion.IonItemGroup>
					))}
				</Ion.IonList>
				{this._RenderTotal(data)}
			</div>
		);
	};

	_RenderDelCharge = data => {
		let _Total = parseFloat(data.DelCharges) + data.Rounded;

		if (data.DelCharges === 0) {
			return (
				<Ion.IonItem lines="none">
					<Ion.IonText
						style={this.global.App.TextBold}
						color="danger"
						slot="start"
					>
						{this.global.LanguagePack[this.global.LanguageNo].LabelDue}
					</Ion.IonText>
					<Ion.IonText
						style={this.global.App.TextBold}
						color="danger"
						slot="end"
					>
						RM {ConvertFormat(data.Rounded)}
					</Ion.IonText>
				</Ion.IonItem>
			);
		} else {
			return (
				<div>
					<Ion.IonItem lines="full">
						<Ion.IonLabel style={this.global.App.TextGrayNormal}>
							<span>
								{this.global.LanguagePack[this.global.LanguageNo].LabelDue}
							</span>
							<span className="MyDisplayRight2">
								{ConvertFormat(data.Rounded)}
							</span>
							<br />
							<span>
								{
									this.global.LanguagePack[this.global.LanguageNo]
										.LabelDeliveryCharges
								}
							</span>
							<span className="MyDisplayRight2">
								{ConvertFormat(data.DelCharges)}
							</span>
						</Ion.IonLabel>
					</Ion.IonItem>

					<Ion.IonItem lines="none">
						<Ion.IonText
							style={this.global.App.TextBold}
							color="danger"
							slot="start"
						>
							{this.global.LanguagePack[this.global.LanguageNo].LabelTotal}
						</Ion.IonText>
						<Ion.IonText
							style={this.global.App.TextBold}
							color="danger"
							slot="end"
						>
							RM {ConvertFormat(_Total)}
						</Ion.IonText>
					</Ion.IonItem>
				</div>
			);
		}
	};

	_RenderTotal = data => {
		//console.log('object',data)
		return (
			<Ion.IonList>
				<Ion.IonItem lines="full">
					<Ion.IonText style={this.global.App.TextBoldGrayNormal} slot="start">
						{this.global.LanguagePack[this.global.LanguageNo].LabelSubtotal}
					</Ion.IonText>
					<Ion.IonText style={this.global.App.TextBoldGrayNormal} slot="end">
						{ConvertFormat(data.GrossAmt)}
					</Ion.IonText>
				</Ion.IonItem>
				<Ion.IonItem lines="full">
					<Ion.IonLabel style={this.global.App.TextGrayNormal}>
						<span>
							{this.global.LanguagePack[this.global.LanguageNo].LabelGross}
						</span>
						<span className="MyDisplayRight2">
							{ConvertFormat(data.GrossAmt)}
						</span>
						<br />
						<span>
							{this.global.LanguagePack[this.global.LanguageNo].LabelSvc}
						</span>
						<span className="MyDisplayRight2">
							{ConvertFormat(data.SvcAmt)}
						</span>
						<br />
						<span>
							{this.global.LanguagePack[this.global.LanguageNo].LabelTax}
						</span>
						<span className="MyDisplayRight2">
							{ConvertFormat(data.TaxAmt)}
						</span>
						<br />
						<span>
							{this.global.LanguagePack[this.global.LanguageNo].LabelAdj}
						</span>
						<span className="MyDisplayRight2">{ConvertFormat(data.Adj)}</span>
						<br />
					</Ion.IonLabel>
				</Ion.IonItem>
				{this._RenderDelCharge(data)}
			</Ion.IonList>
		);
	};

	_RenderAddress = () => {
		if (this.global.Default.AppMode != 'O') {
			return null;
		}

		let data = this.state.Data;

		//  console.log("object", data);

		if (data === null || data === undefined) {
			return null;
		}
		data = this.state.Data;

		let _Add = data.CollectionAddress;

		return (
			<Ion.IonList>
				<Ion.IonListHeader>
					<Ion.IonText color={this.global.Color}>Delivery Info</Ion.IonText>
				</Ion.IonListHeader>

				<Ion.IonItem lines="full">
					<Ion.IonLabel style={this.global.App.TextNormal}>
						<span>
							{
								this.global.LanguagePack[this.global.LanguageNo]
									.LabelServiceType
							}
						</span>
						<span className="MyDisplayRight2">{data.WebServiceName}</span>
						<br />
						<span>
							{this.global.LanguagePack[this.global.LanguageNo].LabelOrderDate}
						</span>
						<span className="MyDisplayRight2">{data.DateCreated}</span>
						<br />
						<span>
							{this.global.LanguagePack[this.global.LanguageNo].LabelMobileNo}
						</span>
						<span className="MyDisplayRight2">{data.MemberCode}</span>
						<br />
						<span>
							{this.global.LanguagePack[this.global.LanguageNo].LabelName}
						</span>
						<span className="MyDisplayRight2">{data.MemberName}</span>
						<br />
						<span>
							{
								this.global.LanguagePack[this.global.LanguageNo]
									.LabelOrderNoLine
							}
						</span>
						<span className="MyDisplayRight2">{data.OrderNo}</span>
					</Ion.IonLabel>
				</Ion.IonItem>

				<Ion.IonItem>
					<Ion.IonLabel position="stacked">Outlet</Ion.IonLabel>
					<Ion.IonTextarea
						style={this.global.App.TextNormal}
						required
						disabled
						autoGrow
						value={data.CollectionOutletName}
					></Ion.IonTextarea>
				</Ion.IonItem>

				<Ion.IonItem>
					<Ion.IonLabel position="stacked">From</Ion.IonLabel>
					<Ion.IonTextarea
						style={this.global.App.TextNormal}
						required
						disabled
						autoGrow
						value={_Add}
					></Ion.IonTextarea>
				</Ion.IonItem>

				<Ion.IonItem>
					<Ion.IonLabel position="stacked">Destination</Ion.IonLabel>
					<Ion.IonTextarea
						style={this.global.App.TextNormal}
						disabled
						required
						autoGrow
						type="text"
						value={data.DeliveryAddress}
					></Ion.IonTextarea>
				</Ion.IonItem>
			</Ion.IonList>
		);
	};

	_RenderPickupAddress = () => {
		if (this.global.Default.AppMode != 'O') {
			return null;
		}
		let data = this.state.Data;

		//console.log("object", data);

		//  let _Gb = this.global.WebInfo;
		let _Add = data.CollectionAddress;

		//  if (_Gb.ServiceCode !== "S") {
		//    return null;
		//  }

		return (
			<Ion.IonList>
				<Ion.IonListHeader>
					<Ion.IonText color={this.global.Color}>Pickup Info</Ion.IonText>
				</Ion.IonListHeader>

				<Ion.IonItem>
					<Ion.IonLabel position="stacked">Outlet</Ion.IonLabel>
					<Ion.IonTextarea
						required
						disabled
						autoGrow
						value={data.CollectionOutletName}
					></Ion.IonTextarea>
				</Ion.IonItem>

				<Ion.IonItem>
					<Ion.IonLabel position="stacked">Address </Ion.IonLabel>
					<Ion.IonTextarea
						required
						disabled
						autoGrow
						value={_Add}
					></Ion.IonTextarea>
				</Ion.IonItem>
			</Ion.IonList>
		);
	};

	_RenderWebCartOrder = () => {
		let data = this.state.Data;

		//   console.log('data',data)
		if (data === null || data === undefined) {
			return null;
		}

		if (data.items === null) {
			return null;
		}

		if (data.IsSuccess === 0) {
			return null;
		}

		let _ShowLine = 'full';

		return (
			<div>
				<Ion.IonList>
					{data.items.map((c, index) => (
						<Ion.IonItemGroup>
							<Ion.IonItem lines={_ShowLine}>
								<Ion.IonLabel style={this.global.App.TextNormal}>
									<span>
										{c.MenuCode} - {c.MenuName}
									</span>
									<span className="MyDisplayRight2">
										{c.Qty} x {ConvertFormat(c.UnitPrice)}
									</span>
									<br />
									<span></span>
									{this._Action('SHOWDISCOUNT', index)}
									<span className="MyDisplayRight2">
										{ConvertFormat(c.GrossAmt)}
									</span>
									{this._Action('SHOWSERVICE', index)}
									{this._Action('SHOWREMARK', index)}
									{this._Action('SHOWCOMBOSET', index)}
									{this._Action('SHOWCONDIMENTDETAILS', index)}
									{this._Action('SHOWMODIFIERDETAILS', index)}
								</Ion.IonLabel>
							</Ion.IonItem>
						</Ion.IonItemGroup>
					))}
				</Ion.IonList>
				{this._RenderTotal(data)}
			</div>
		);
	};

	_RenderHeader() {
		let _Title = this.state.TableCode;

		if (this.global.Default.AppMode === 'O') {
			_Title = '';
		}

		return (
			<Ion.IonHeader>
				<Ion.IonToolbar color={this.global.Color}>
					<Ion.IonButtons slot="start">
						<Ion.IonButton onClick={() => this.setState({ IsBack: true })}>
							<Ion.IonIcon icon={chevronBack}></Ion.IonIcon>
						</Ion.IonButton>
					</Ion.IonButtons>
					<Ion.IonTitle>{_Title}</Ion.IonTitle>
				</Ion.IonToolbar>
			</Ion.IonHeader>
		);
	}

	_RenderError = () => {
		const _Style = { 'text-align': 'center' };
		const { Paragraph, Text } = Typography;

		return (
			<Modal
				centered
				visible={this.state.IsError}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
				footer={false}
				closable={false}
			>
				<Res
					status="warning"
					title={this.global.LanguagePack[this.global.LanguageNo].AlertTitle}
					subTitle={
						this.global.LanguagePack[this.global.LanguageNo].AlertNoMessage
					}
					extra={[
						<Space>
							<Button
								type="primary"
								key="Retry"
								size="large"
								block
								onClick={() => this._FetchData()}
							>
								Retry
							</Button>
							<Button
								key="Back"
								size="large"
								block
								onClick={() =>
									this.setState({ IsBack: true, IsLoading: false })
								}
							>
								Back
							</Button>
						</Space>,
					]}
				/>
			</Modal>
		);
	};

	_RenderLoading = () => {
		const _Style = { 'text-align': 'center' };
		return (
			<Modal
				centered
				visible={this.state.IsLoading}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
				footer={false}
				closable={false}
				width={200}
			>
				<div style={_Style}>
					<Spin
						tip={this.global.LanguagePack[this.global.LanguageNo].AlertWait}
					/>
				</div>
			</Modal>
		);
	};

	render() {
		if (this.state.IsBack === true) {
			return <Main />;
		}

		//console.log('Order', this.state.Data);

		return (
			<Ion.IonApp>
				{this._RenderHeader()}
				<Ion.IonContent fullscreen>
					{this._RenderLoading()}
					{this._RenderError()}
					{this._RenderAddress()}
					{this._RenderCartOrder()}
				</Ion.IonContent>
			</Ion.IonApp>
		);
	}
}

import React, { Component, getGlobal, setGlobal } from 'reactn';
import * as Ion from '@ionic/react';
import { chevronBack, checkmark, navigate, search } from 'ionicons/icons';
import * as Antd from 'antd';
import PosOrder from '../PosOrder';
import { CallPostApi, ConvertFormat } from '../../functions/General';
import * as lod from 'lodash';
import { DollarCircleTwoTone, CreditCardTwoTone } from '@ant-design/icons';

export default class CheckOut extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ErrorMessage: null,
			IsBack: false,
			IsSuccess: false,
			IsError: false,
			IsSubmit: false,
			OutletData: null,
			IsPayment: false,
			IsPopPayment: false,
			SelectedPaymentData: null,
			IsEWalletPop: false,
			IsRevPopup: false,
			RevData: null,
		};

		//   console.log("Global", this.global);
	}

	_RenderHeader() {
		return (
			<Ion.IonHeader>
				<Ion.IonToolbar color={this.global.Color}>
					<Ion.IonButtons slot="start">
						<Ion.IonButton onClick={() => this.setState({ IsBack: true })}>
							<Ion.IonIcon icon={chevronBack}></Ion.IonIcon>
						</Ion.IonButton>
					</Ion.IonButtons>
					<Ion.IonTitle>Check out Summary</Ion.IonTitle>
					<Ion.IonButtons slot="end">
						<Ion.IonButton>Edit</Ion.IonButton>
					</Ion.IonButtons>
				</Ion.IonToolbar>
			</Ion.IonHeader>
		);
	}

	_RenderBills = () => {
		let _Gb = this.global.WebInfo;

		return (
			<Ion.IonList>
				<Ion.IonListHeader>
					<Ion.IonText color={this.global.Color}>Contact Details</Ion.IonText>
				</Ion.IonListHeader>
				<Ion.IonItem>
					<Ion.IonLabel position="stacked">Mobile Phone</Ion.IonLabel>
					<Ion.IonInput
						required
						disabled
						type="number"
						placeholder="e.g 60121223456"
						value={_Gb.MobileNo}
					></Ion.IonInput>
				</Ion.IonItem>

				<Ion.IonItem>
					<Ion.IonLabel position="stacked">Name</Ion.IonLabel>
					<Ion.IonInput
						disabled
						required
						type="text"
						placeholder=""
						value={_Gb.CustomerName}
					></Ion.IonInput>
				</Ion.IonItem>
				<Ion.IonItem>
					<Ion.IonLabel position="stacked">Address</Ion.IonLabel>
					<Ion.IonTextarea
						disabled
						required
						type="text"
						placeholder=""
						value={_Gb.Remark}
					></Ion.IonTextarea>
				</Ion.IonItem>
			</Ion.IonList>
		);
	};

	_RenderAddress = () => {
		let _Gb = this.global.WebInfo;

		if (_Gb.ServiceCode !== 'E') {
			return null;
		}

		let _Add = _Gb.FromAddress;

		return (
			<Ion.IonList>
				<Ion.IonListHeader>
					<Ion.IonText color={this.global.Color}>Delivery Info</Ion.IonText>
				</Ion.IonListHeader>

				<Ion.IonItem>
					<Ion.IonLabel position="stacked">Outlet</Ion.IonLabel>
					<Ion.IonTextarea
						required
						disabled
						autoGrow
						value={_Gb.OutletName}
					></Ion.IonTextarea>
				</Ion.IonItem>

				<Ion.IonItem>
					<Ion.IonLabel position="stacked">From</Ion.IonLabel>
					<Ion.IonTextarea
						required
						disabled
						autoGrow
						value={_Add}
					></Ion.IonTextarea>
				</Ion.IonItem>

				<Ion.IonItem>
					<Ion.IonLabel position="stacked">Destination</Ion.IonLabel>
					<Ion.IonTextarea
						disabled
						required
						autoGrow
						type="text"
						value={_Gb.ToAddress}
					></Ion.IonTextarea>
				</Ion.IonItem>
			</Ion.IonList>
		);
	};

	_RenderPickupAddress = () => {
		let _Gb = this.global.WebInfo;
		let _Add = _Gb.FromAddress;

		if (_Gb.ServiceCode !== 'S') {
			return null;
		}

		return (
			<Ion.IonList>
				<Ion.IonListHeader>
					<Ion.IonText color={this.global.Color}>Pickup Info</Ion.IonText>
				</Ion.IonListHeader>

				<Ion.IonItem>
					<Ion.IonLabel position="stacked">Outlet</Ion.IonLabel>
					<Ion.IonTextarea
						required
						disabled
						autoGrow
						value={_Gb.OutletName}
					></Ion.IonTextarea>
				</Ion.IonItem>

				<Ion.IonItem>
					<Ion.IonLabel position="stacked">Address </Ion.IonLabel>
					<Ion.IonTextarea
						required
						disabled
						autoGrow
						value={_Add}
					></Ion.IonTextarea>
				</Ion.IonItem>
			</Ion.IonList>
		);
	};

	_StartExe_RevPayment = async x => {
		let _ModuleCode = 'V8REVPAY';

		_ModuleCode = this.global.WebInfo.WebPaymentCode;
		//console.log('_Module', _ModuleCode)
		//	let _WebPaymentCode = this.global.OutletDataRow.ItemArray[28];

		if (_ModuleCode === undefined) {
			_ModuleCode = this.global.OutletInfo.WebPaymentCode;
		}
		//	console.log('Interface', this.global.OutletInfo.WebPaymentCode);

		let _Gb = this.global.InterfaceConfig;
		let _Data = lod.filter(_Gb, c => c.ModuleCode === _ModuleCode);

		//	console.log('Module', this.global.WebInfo);
		//console.log('Interface', _Gb);

		if (_Data.length === 0) {
			alert('No Payment Code found');
			return null;
		}

		//	console.log('Data', _Data);
		let _Url; // = _Data[0].BasedUrl;

		let _Value3 = {
			ModuleCode: _ModuleCode,
			QrParameter: this.global.Url,
			ServiceCode: this.global.Default.AppMode,
			Amount: this.global.LiveData.Bills.TotalAmt,
			CustomerName: this.global.WebInfo.CustomerName,
			MobileNo: this.global.WebInfo.MobileNo,
			ProfileCode: this.global.ProfileCode,
			Url: _Url,
			Mechantid: _Data[0].UserName,
			vKey: _Data[0].Password,
			ReturnUrl: _Data[0].DummyCode5,
			TrsNo: this.global.TrsNo,
			LalamoveBody: this.global.WebInfo.OrderRaw,
			LalamoveParameter: this.global.WebInfo.Parameter,
			PaymentID: x,
		};

		//console.log('Global', this.global.WebInfo);
		//	console.log('Passing Value Payment', _Value3);

		let _Value = { Type: 'GETREV', Value: _Value3 };
		let _Result = await CallPostApi(_Value);

		//	console.log('Return Rev', _Result);

		localStorage.setItem('WebTokenId', _Result.RevValue.Reference_Number);
		this.setState({
			IsPopPayment: false,
			IsRevPopup: true,
			RevData: _Result.RevValue,
			ReferNumber: _Result.RevValue.Reference_Number,
			Sign: _Result.RevValue.Signature,
			PaymentID: _Result.RevValue.Payment_ID,
		});
	};

	_StartExePayment = async (x, y) => {
		/* 
	let _Value2 = {
	  Amount: this.global.LiveData.Bills.TotalAmt,
	  CustomerName: this.global.WebInfo.CustomerName,
	  MobileNo: this.global.WebInfo.MobileNo,
	  ProfileCode: this.global.ProfileCode,
	  Url: this.state.SelectedPaymentData.BasedUrl,
	  Mechantid: this.state.SelectedPaymentData.UserName,
	  vKey: this.state.SelectedPaymentData.Password,
	};
 */

		let _Value2 = this._RenderMol(x, y);

		let _Value = { Type: 'GETMOL', QueryType: 'GETMOL', Value: _Value2 };
		//console.log('Passing Value Payment', _Value);

		let _Result = await CallPostApi(_Value);
		// console.log("Return", _Result);
		let _TokenId = _Result.orderid;
		localStorage.setItem('WebTokenId', _TokenId);
		localStorage.setItem('WebAmount', _Value2.Amount);

		let _Bills = this.global.WebData;
		lod.set(_Bills, 'TokenID', _TokenId);
		let _NewBills = [];
		_NewBills.push(_Bills);
		let _Data = {
			Bills: _NewBills,
			BillDetails: this.global.LiveData.BillDetails,
		};

		// let _Value5 = { Type: "SAVEDATA", Value: _Data };

		// localStorage.setItem("WebData", JSON.stringify(_Value5));
		//console.log("Hahahah");
		//window.open(_Result.Url, '_self');
	};

	_CheckPayment = x => {
		switch (x) {
			case 'C':
				//this.setState({ IsEWalletPop: , IsPopPayment: false });
				this._StartExe_RevPayment('31');
				break;
			case 'W':
				this.setState({ IsEWalletPop: true, IsPopPayment: false });
				break;

			default:
				switch (this.global.OutletInfo.WebPaymentCode) {
					case 'V8MOLPAY':
						this._StartExePayment('', x);
						break;
					case 'V8REVPAY':
						this._StartExe_RevPayment();
						break;
					default:
						break;
				}

				break;
		}
	};

	_RenderPaymentMode = () => {
		let _Gb = this.global.WebInfo;
		let _Add = _Gb.FromAddress;

		let _Def = {
			height: '180px',
			width: '100%',
		};

		let _PayMode = [
			{
				Code: 'C',
				Name: 'Credit Card',
				ImageName: 'https://staging.vpos.com.my/NewWebOrder/Cc.png',
			},
			{
				Code: 'W',
				Name: 'E-Wallet',
				ImageName: 'https://staging.vpos.com.my/NewWebOrder/Tng.png',
			},
		];

		return (
			<Antd.Drawer
				title="Select Payment Mode"
				visible={this.state.IsPopPayment}
				placement="bottom"
				onClose={() => this.setState({ IsPopPayment: false })}
				key={'bottom'}
			>
				<Antd.List
					itemLayout="horizontal"
					dataSource={_PayMode}
					renderItem={item => (
						<Antd.List.Item>
							<Antd.List.Item.Meta
								avatar={
									<Antd.Avatar shape="square" size="large" src={item.ImageName}>
										{/* <CreditCardTwoTone /> */}
									</Antd.Avatar>
								}
								title={item.Name}
								onClick={() => this._CheckPayment(item.Code)}
							/>
						</Antd.List.Item>
					)}
				></Antd.List>
			</Antd.Drawer>
		);
	};

	_RenderDeliveryTotal = () => {
		let _Gb = this.global.LiveData.Bills;
		let _DelAmt = _Gb.DelAmt;
		if (_Gb.DelAmt === undefined || _Gb.DelAmt === null) {
			_DelAmt = 0;
		}

		if (_DelAmt === 0) {
			return (
				<div>
					<Ion.IonItem lines="none">
						<Ion.IonText
							style={this.global.App.TextBold}
							color="danger"
							slot="start"
						>
							{this.global.LanguagePack[this.global.LanguageNo].LabelDue}
						</Ion.IonText>
						<Ion.IonText
							style={this.global.App.TextBold}
							color="danger"
							slot="end"
						>
							RM {ConvertFormat(_Gb.Rounded)}
						</Ion.IonText>
					</Ion.IonItem>
				</div>
			);
		}

		return (
			<div>
				<Ion.IonItem lines="none">
					<Ion.IonLabel style={this.global.App.TextGrayNormal}>
						<span>
							{this.global.LanguagePack[this.global.LanguageNo].LabelTotal}
						</span>
						<span className="MyDisplayRight2">
							{ConvertFormat(_Gb.Rounded)}
						</span>
						<span>
							<br />
							{
								this.global.LanguagePack[this.global.LanguageNo]
									.LabelDeliveryCharges
							}
						</span>
						<span className="MyDisplayRight2">{ConvertFormat(_DelAmt)}</span>
					</Ion.IonLabel>
				</Ion.IonItem>
				<Ion.IonItem lines="none">
					<Ion.IonText
						style={this.global.App.TextBold}
						color="danger"
						slot="start"
					>
						{this.global.LanguagePack[this.global.LanguageNo].LabelDue}
					</Ion.IonText>
					<Ion.IonText
						style={this.global.App.TextBold}
						color="danger"
						slot="end"
					>
						RM {ConvertFormat(_Gb.TotalAmt)}
					</Ion.IonText>
				</Ion.IonItem>
			</div>
		);
	};

	_RenderTotal = () => {
		let _Gb = this.global.LiveData.Bills;

		return (
			<Ion.IonList>
				<Ion.IonListHeader>
					<Ion.IonText color={this.global.Color}>Payment Summary</Ion.IonText>
				</Ion.IonListHeader>

				<Ion.IonItem lines="full">
					<Ion.IonText style={this.global.App.TextBoldGrayNormal} slot="start">
						{this.global.LanguagePack[this.global.LanguageNo].LabelSubtotal}
					</Ion.IonText>
					<Ion.IonText style={this.global.App.TextBoldGrayNormal} slot="end">
						{ConvertFormat(_Gb.GrossAmt)}
					</Ion.IonText>
				</Ion.IonItem>
				<Ion.IonItem lines="full">
					<Ion.IonLabel style={this.global.App.TextGrayNormal}>
						<span>
							{this.global.LanguagePack[this.global.LanguageNo].LabelGross}
						</span>
						<span className="MyDisplayRight2">
							{ConvertFormat(_Gb.GrossAmt)}
						</span>
						<br />
						<span>
							{this.global.LanguagePack[this.global.LanguageNo].LabelSvc}
						</span>
						<span className="MyDisplayRight2">{ConvertFormat(_Gb.SvcAmt)}</span>
						<br />
						<span>
							{this.global.LanguagePack[this.global.LanguageNo].LabelTax}
						</span>
						<span className="MyDisplayRight2">{ConvertFormat(_Gb.TaxAmt)}</span>
						<br />
						<span>
							{this.global.LanguagePack[this.global.LanguageNo].LabelAdj}
						</span>
						<span className="MyDisplayRight2">{ConvertFormat(_Gb.Adj)}</span>
						<br />
					</Ion.IonLabel>
				</Ion.IonItem>
				{this._RenderDeliveryTotal()}
			</Ion.IonList>
		);
	};

	_RenderMol = (x, y) => {
		let _ModuleCode = 'V8MOLPAY';
		let _Gb = this.global.InterfaceConfig;
		let _Data = lod.filter(_Gb, c => c.ModuleCode === _ModuleCode);
		let _Url = _Data[0].BasedUrl + _Data[0].UserName;

		//console.log("Gb", _Gb);
		//console.log("Data", _Data);
		//console.log("Url", _Url);

		if (y !== 'C') {
			let _Gb2 = this.global.WebPaymentInfo;
			let _Data2 = lod.filter(_Gb2, c => c.Code === x.Code);

			if (_Data2[0].Index != '') {
				_Url = _Url + '/' + _Data2[0].Index;
				//  console.log("Data2", _Data2);
				//  console.log("Url2", _Url);
			}

			// console.log("Gb", _Gb);
		}

		let _ReturnUrl = window.location.href.split('?')[0];

		let _Value = {
			Amount: this.global.LiveData.Bills.TotalAmt,
			CustomerName: this.global.WebInfo.CustomerName,
			MobileNo: this.global.WebInfo.MobileNo,
			ProfileCode: this.global.ProfileCode,
			Url: _Url,
			Mechantid: _Data[0].UserName,
			vKey: _Data[0].Password,
			ReturnUrl: _ReturnUrl,
			TrsNo: this.global.TrsNo,
		};

		return _Value;
	};

	_RenderListEWallet = () => {
		let _Data = this.global.WebPaymentInfo;
		//console.log('Web Payment', _Data);

		let _Def = {
			height: '180px',
			width: '100%',
		};

		return (
			<Ion.IonModal isOpen={this.state.IsEWalletPop} backdropDismiss={false}>
				<Ion.IonHeader>
					<Ion.IonToolbar color={this.global.Color}>
						<Ion.IonButtons slot="start">
							<Ion.IonButton
								onClick={() => this.setState({ IsEWalletPop: false })}
							>
								<Ion.IonIcon icon={chevronBack}></Ion.IonIcon>
							</Ion.IonButton>
						</Ion.IonButtons>
						<Ion.IonTitle>Select Type</Ion.IonTitle>
					</Ion.IonToolbar>
				</Ion.IonHeader>
				<Ion.IonContent>
					<Antd.List
						grid={{ gutter: 16, column: 2 }}
						itemLayout="horizontal"
						dataSource={_Data}
						renderItem={item => (
							<Antd.List.Item
								onClick={() => this._StartExe_RevPayment(item.PayCode)}
							>
								<Antd.Card title={item.Name}>
									<Antd.Image preview={false} src={item.ImageName}></Antd.Image>
								</Antd.Card>
							</Antd.List.Item>
						)}
					></Antd.List>
				</Ion.IonContent>
			</Ion.IonModal>
		);
	};

	_RenderRevPay = () => {
		let _Style = {
			width: '100%',
			padding: '12px 20px',
			margin: '8px 0',
			display: 'inline-block',
			border: '1px solid #ccc',
			'border-radius': '4px',
			'box-sizing': 'border-box',
		};

		let _Button = {
			width: '100%',
			padding: '14px 20px',
			margin: '8px 0',
			border: 'none',
			'border-radius': '4px',
			cursor: 'pointer',
			'background-color': '#4CAF50',
			color: 'white',
		};

		let _Div = { padding: '20px' };

		if (this.state.RevData === null) {
			return null;
		}

		//let _PayBasedUrl = this.global.WebInfo.PaymentInfo.BasedUrl;
		//	console.log('Payment Link', _PayBasedUrl);

		let _ModuleCode = 'V8REVPAY';

		_ModuleCode = this.global.WebInfo.WebPaymentCode;

		//	let _WebPaymentCode = this.global.OutletDataRow.ItemArray[28];

		if (_ModuleCode === undefined) {
			_ModuleCode = this.global.OutletInfo.WebPaymentCode;
		}

		//	console.log('Interface', this.global.OutletInfo.WebPaymentCode);

		let _Gb = this.global.InterfaceConfig;
		let _Data = lod.filter(_Gb, c => c.ModuleCode === _ModuleCode);
		let _PayBasedUrl = _Data[0].BasedUrl;

		//	WebInfo.PaymentInfo;

		return (
			<Ion.IonModal isOpen={this.state.IsRevPopup} backdropDismiss={false}>
				<form method="post" action={_PayBasedUrl} acceptCharset="UTF-8">
					<Antd.Card
						title="Payment Info - RevPAY"
						extra={
							<input style={_Button} defaultValue="Submit" type="submit" />
						}
					>
						<Ion.IonList>
							<div style={_Div}>
								<label style={this.global.App.TextGrayNormal}>
									Merchant ID :
								</label>

								<input
									style={_Style}
									type="text"
									name="Revpay_Merchant_ID"
									defaultValue={this.state.RevData.Revpay_Merchant_ID}
									readonly="readonly"
								/>

								<label style={this.global.App.TextGrayNormal}>
									Reference No. :
								</label>

								<input
									style={_Style}
									type="text"
									name="Reference_Number"
									defaultValue={this.state.RevData.Reference_Number}
									readonly="readonly"
								/>

								<label style={this.global.App.TextGrayNormal}>Currency :</label>
								<input
									style={_Style}
									type="text"
									name="Currency"
									value="MYR"
									readonly="readonly"
								/>

								<label style={this.global.App.TextGrayNormal}>Amount :</label>
								<input
									style={_Style}
									type="text"
									name="Amount"
									defaultValue={this.state.RevData.Amount}
									readonly="readonly"
								/>
								<label style={this.global.App.TextGrayNormal}>
									Payment ID :
								</label>
								<input
									style={_Style}
									type="text"
									name="Payment_ID"
									defaultValue={this.state.RevData.Payment_ID}
									readonly="readonly"
								/>
							</div>

							<input
								type="hidden"
								name="Transaction_Description"
								defaultValue="INVOICE"
							/>
							<input
								type="hidden"
								name="Customer_Email"
								defaultValue="test@gmail.com"
							/>
							<input
								type="hidden"
								name="Customer_Contact"
								defaultValue={'0123456789'}
							/>
							<input
								type="hidden"
								name="Customer_Name"
								defaultValue={this.state.RevData.Customer_Name}
							/>
							<input
								type="hidden"
								name="Customer_IP"
								defaultValue="127.0.0.1"
							/>
							<input type="hidden" name="Key_Index" defaultValue={'1'} />

							<input
								type="hidden"
								name="Signature"
								defaultValue={this.state.Sign}
							/>

							<input
								type="hidden"
								name="Return_URL"
								defaultValue={this.state.RevData.Return_URL}
							/>
						</Ion.IonList>
					</Antd.Card>
				</form>
			</Ion.IonModal>
		);
	};

	render() {
		if (this.state.IsBack === true) {
			return <PosOrder />;
		}

		if (this.state.IsSuccess === true) {
		}

		//console.log('This global - Check Out',this.global.LiveData)

		return (
			<Ion.IonApp>
				{this._RenderHeader()}
				<Ion.IonContent fullscreen>
					<Antd.Card>
						{this._RenderBills()}
						{this._RenderAddress()}
						{this._RenderPickupAddress()}
						{this._RenderTotal()}
					</Antd.Card>
					{this._RenderPaymentMode()}
					{this._RenderListEWallet()}
					{this._RenderRevPay()}
				</Ion.IonContent>
				<Ion.IonFooter>
					<Ion.IonButton
						color={this.global.Color}
						expand="full"
						size="large"
						onClick={() => this.setState({ IsPopPayment: true })}
					>
						{
							this.global.LanguagePack[this.global.LanguageNo]
								.ButtonComfirmCaption
						}
					</Ion.IonButton>
				</Ion.IonFooter>
			</Ion.IonApp>
		);
	}
}

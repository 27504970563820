import React, { Component } from 'reactn';
import {
	Result as Res,
	Drawer,
	Modal,
	Spin,
	Button,
	Typography,
	Space,
} from 'antd';
import * as lod from 'lodash';
import * as Ion from '@ionic/react';
import '../Css/Style.css';
import { CallPostApi, ConvertFormat, SaveCart } from '../functions/General';
import PosOrder from './PosOrder';
import WebResult from './Web/WebResult';
import Result from './Result';
import CheckOut from './Web/CheckOut';

export default class SaveData extends Component {
	constructor(props) {
		super(props);

		this.state = {
			IsError: false,
			IsWebSave: false,
			IsSaveDone: false,
			IsLoading: true,
			ErrorMessage: null,
			IsPayStatus: this.props.IsPayStatus,
		};

		//console.log('SaveData - PayStatus',this.props.IsPayStatus)
	}

	_SaveData = async () => {
		let _Bills = [];

		let _Det = this.global.LiveData.BillDetails;

		lod.forEach(_Det, function (s, i) {
			lod.set(_Det[i], 'ItemInfo', _Det[i].ItemInfo2);

			if (_Det[i].Modifier.length !== 0) {
				lod.forEach(_Det[i].Modifier, function (v, k) {
					lod.set(
						_Det[i].Modifier[k],
						'ItemInfo',
						_Det[i].Modifier[k].ItemInfo2
					);
				});
			}
			if (_Det[i].ComboSet.length !== 0) {
				lod.forEach(_Det[i].ComboSet, function (v, m) {
					lod.set(
						_Det[i].ComboSet[m],
						'ItemInfo',
						_Det[i].ComboSet[m].ItemInfo2
					);
				});
			}
		});

		_Bills.push(this.global.LiveData.Bills);

		let _PayType = '';
		if (this.state.IsPayStatus === 'NOW') {
			_PayType = 'P';
		}

		let _WebData = [];
		_WebData.push(this.global.WebInfo);

		let queryString = window.location.search.substring(1);
		const urlParams = new URLSearchParams(queryString);
		const _TableCode = urlParams.get('zTableCode');
		const _Token = urlParams.get('Token');
		const _ServiceCode = urlParams.get('zServiceCode');

		//	let _NewTable = localStorage.getItem('NewTableCode');
		//	let _NewToken = localStorage.getItem('NewToken');

		/* 	console.log('URL', queryString);
		console.log('TableCode', _TableCode);
		console.log('tOKENid', _Token);
 */
		let _NewTable = _TableCode;
		let _NewToken = _Token;
		let _Url = queryString;

		let _Data = {
			TableCode: _NewTable,
			TokenId: _NewToken,
			ServiceCode: _ServiceCode,
			SelectedServiceCode: _ServiceCode,
			URL: _Url,
			PayType: _PayType,
			WebData: _WebData,
			Bills: _Bills,
			BillDetails: this.global.LiveData.BillDetails,
		};

		//localStorage.setItem('WebTokenId', _NewToken)
		//console.log('Submit', _Data);
		this.setState({ IsError: false, IsLoading: true });

		let _Value5 = { Type: 'SAVEDATA', Value: _Data };

		//	console.log('Hello ---> 1111', _Value5);
		//  console.log("Prp", this.props.SaveType);

		let _Result = await CallPostApi(_Value5);

		if (_Result.IsSuccess === 1) {
			let _LiveData = { Bills: {}, BillDetails: [] };

			localStorage.setItem('TrsNo', _Result.TrsNo);
			if (this.props.SaveType === 'BEFORE') {
				// localStorage.setItem("WebData", JSON.stringify(_Value5));
				//		console.log("After Save Result---->", _Result);
				localStorage.setItem("ReturnOrderNo", _Result.OrderNo);
				//  this.setGlobal({ WebData: _Bills[0] });
				this.setGlobal({
					TrsNo: _Result.TrsNo,
					OrderNo: _Result.OrderNo,
					//   LiveData: _LiveData,
				});
				this.setState({
					IsError: false,
					IsSaveDone: true,
					IsWebSave: true,
					IsLoading: false,
				});
			} else {
				this.setGlobal({
					TrsNo: _Result.TrsNo,
					OrderNo: _Result.OrderNo,
					LiveData: _LiveData,
				});
				this.setState({ IsError: false, IsSaveDone: true, IsLoading: false });
			}
		} else {
			this.setState({
				ErrorMessage: _Result.ErrorMessage,
				IsError: true,
				IsSaveDone: false,
				IsLoading: false,
			});
		}
	};

	_SaveData_UnUsed = async () => {
		let _Bills = [];

		let _Det = this.global.LiveData.BillDetails;

		lod.forEach(_Det, function (s, i) {
			lod.set(_Det[i], 'ItemInfo', _Det[i].ItemInfo2);

			if (_Det[i].Modifier.length !== 0) {
				lod.forEach(_Det[i].Modifier, function (v, k) {
					lod.set(
						_Det[i].Modifier[k],
						'ItemInfo',
						_Det[i].Modifier[k].ItemInfo2
					);
				});
			}
			if (_Det[i].ComboSet.length !== 0) {
				lod.forEach(_Det[i].ComboSet, function (v, m) {
					lod.set(
						_Det[i].ComboSet[m],
						'ItemInfo',
						_Det[i].ComboSet[m].ItemInfo2
					);
				});
			}
		});

		_Bills.push(this.global.LiveData.Bills);

		let _WebData = [];
		_WebData.push(this.global.WebInfo);
		let _Data = {
			WebData: _WebData,
			Bills: _Bills,
			BillDetails: this.global.LiveData.BillDetails,
		};

		//    console.log('Submit', this.global.LiveData.BillDetails)
		this.setState({ IsError: false, IsLoading: true });

		let _Value5 = { Type: 'SAVEDATA', Value: _Data };

		// console.log("Hello ---> 1111", _Value5);
		// console.log("Prp", this.props.SaveType);
		if (this.props.SaveType === 'BEFORE') {
			// localStorage.setItem("WebData", JSON.stringify(_Value5));

			this.setGlobal({ WebData: _Bills[0] });
			this.setState({ IsError: false, IsSaveDone: true, IsLoading: false });
		} else {
			let _Result = await CallPostApi(_Value5);
			//   console.log('Save Result', _Result)
			if (_Result.IsSuccess === 1) {
				let _LiveData = { Bills: {}, BillDetails: [] };
				this.setGlobal({ OrderNo: _Result.OrderNo, LiveData: _LiveData });
				this.setState({ IsError: false, IsSaveDone: true, IsLoading: false });
			} else {
				this.setState({
					ErrorMessage: _Result.ErrorMessage,
					IsError: true,
					IsSaveDone: false,
					IsLoading: false,
				});
			}
		}
	};

	_RenderError = () => {
		const _Style = { 'text-align': 'center' };
		const { Paragraph, Text } = Typography;

		return (
			<Modal
				centered
				visible={this.state.IsError}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
				footer={false}
				closable={false}
			>
				<Res
					status="warning"
					title={this.global.LanguagePack[this.global.LanguageNo].AlertTitle}
					subTitle={
						this.global.LanguagePack[this.global.LanguageNo]
							.AlertFailSubmitRetry
					}
					extra={[
						<Space>
							<Button
								type="primary"
								key="Retry"
								size="large"
								block
								onClick={() => this._SaveData()}
							>
								Retry
							</Button>
							<Button
								key="Back"
								size="large"
								block
								onClick={() =>
									this.setState({
										IsError: false,
										IsLoading: false,
										IsBack: true,
									})
								}
							>
								Back
							</Button>
						</Space>,
					]}
				/>
				<div className="desc">
					<Paragraph>
						<Text strong style={{ color: 'red', fontSize: 16 }}>
							Message
						</Text>
					</Paragraph>
					<Paragraph>{this.state.ErrorMessage}</Paragraph>
				</div>
			</Modal>
		);
	};

	_RenderLoading = () => {
		const _Style = { textAlign: 'center' };
		return (
			<Modal
				centered
				visible={this.state.IsLoading}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
				footer={false}
				closable={false}
				width={200}
			>
				<div style={_Style}>
					<Spin
						tip={this.global.LanguagePack[this.global.LanguageNo].AlertSubmit}
					/>
				</div>
			</Modal>
		);
	};

	componentDidMount() {
		this._SaveData();
	}

	render() {
		if (this.state.IsBack === true) {
			return <PosOrder />;
		}

		if (this.state.IsSaveDone === true) {
			// console.log('IsPayStatus',this.props.IsPayStatus)
			//  console.log('AppMode',this.global.Default.AppMode)
			// console.log('Save',this.props.SaveType)

			if (this.props.IsPayStatus === 'NOW') {
				return <CheckOut />;
			}

			if (this.global.Default.AppMode === 'O') {
				if (this.props.SaveType === 'BEFORE') {
					return <CheckOut />;
				}
			}
			return <Result />;
		}

		return (
			<div>
				{this._RenderLoading()}
				{this._RenderError()}
			</div>
		);
	}
}

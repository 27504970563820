import React, { Component, getGlobal, setGlobal } from 'reactn';
import * as Ion from '@ionic/react';
import { chevronBack, checkmark, navigate, search } from 'ionicons/icons';
import Main from '../Main';
import CurrentLocation from 'react-current-location-address';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
//import "react-google-places-autocomplete/dist/assets/index.css";
import Geocode from 'react-geocode';

import {
	Result as Res,
	Drawer,
	Modal,
	Spin,
	Button,
	Typography,
	Space,
} from 'antd';

import {
	CallGetApi,
	CallPostApi,
	ConvertFormat,
	GetAddressDistance,
	Geo,
} from '../../functions/General';

import * as lod from 'lodash';
import PosOrder from '../PosOrder';
import ProcessQuotation from './ProcessQuotation';
import { GetQuotation } from '../../functions/Lalamove';

export default class WebStart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			IsSelectOutlet: false,
			IsListOutlet: false,
			ErrorMessage: null,
			IsBack: false,
			IsSuccess: false,
			IsError: false,
			IsSubmit: false,
			IsPopupAddress: false,
			Address: '',
			DeliveryAddress: '',
			OutletData: null,
			MobileNo: this.global.WebInfo.MobileNo,
			UnitNo: this.global.WebInfo.UnitNo,
			CustomerName: this.global.WebInfo.CustomerName,
			Remark: '',
			// MobileNo: "60125226912",
			// CustomerName: "Testing",
		};
		this.myLoc = React.createRef();
	}

	_ValidateValue = async () => {
		let res = true;
		let ErrMessage = '';

		let _Rs = {};

		if (this.state.MobileNo === undefined || this.state.MobileNo === null) {
			ErrMessage = 'Invalid Mobile No !!!';
			res = false;
			_Rs = { IsError: true, ErrMessage: ErrMessage };
			return _Rs;
		} else {
			let _txt = this.state.MobileNo;
			var res1 = _txt.substring(0, 1);
			//  console.log("res1", res1);
			if (res1 !== '6') {
				ErrMessage =
					'Invalid Mobile No format, Mobile No should start with 6 !!!';
				res = false;
				_Rs = { IsError: true, ErrMessage: ErrMessage };
				return _Rs;
			}
		}

		if (
			this.state.CustomerName === undefined ||
			this.state.CustomerName === null
		) {
			ErrMessage = 'Invalid Customer Name !!!';
			res = false;
			_Rs = { IsError: true, ErrMessage: ErrMessage };
			return _Rs;
		}

		if (this.global.Default.SelectedServiceCode === 'E') {
			if (
				this.state.Address === undefined ||
				this.state.Address === null ||
				this.state.Address === ''
			) {
				ErrMessage = 'Invalid Address !!!';
				res = false;
				_Rs = { IsError: true, ErrMessage: ErrMessage };
				return _Rs;
			}
		}

		_Rs = { IsError: false, ErrMessage: ErrMessage };
		return _Rs;
	};

	_RenderContactNo = () => {
		return (
			<div>
				<Ion.IonList>
					<Ion.IonListHeader>
						<Ion.IonText color={this.global.Color}>Contact Details</Ion.IonText>
					</Ion.IonListHeader>

					<Ion.IonItem>
						<Ion.IonLabel position="stacked">Mobile Phone</Ion.IonLabel>
						<Ion.IonInput
							required
							type="number"
							placeholder="e.g 60121223456"
							value={this.state.MobileNo}
							onIonChange={e => {
								this.setState({ MobileNo: e.target.value });
							}}
						></Ion.IonInput>
					</Ion.IonItem>

					<Ion.IonItem>
						<Ion.IonLabel position="stacked">Name</Ion.IonLabel>
						<Ion.IonInput
							required
							type="text"
							placeholder=""
							value={this.state.CustomerName}
							onIonChange={e => {
								this.setState({ CustomerName: e.target.value });
							}}
						></Ion.IonInput>
					</Ion.IonItem>

					<Ion.IonItem>
						<Ion.IonLabel position="stacked">Address</Ion.IonLabel>
						<Ion.IonTextarea
							required
							type="text"
							placeholder=""
							value={this.state.Remark}
							onIonChange={e => {
								this.setState({ Remark: e.target.value });
							}}
						></Ion.IonTextarea>
					</Ion.IonItem>
				</Ion.IonList>
			</div>
		);
	};

	_SetAddress = () => {
		//console.log('first.....')

		let _Gb = this.global.WebInfo;
		let _NewAddress = this.state.DeliveryAddress;
		if (this.state.UnitNo !== '') {
			_NewAddress = this.state.UnitNo + ',' + this.state.DeliveryAddress;
			//	console.log('first', _NewAddress);
			this.setState({ Address: _NewAddress, IsPopupAddress: false });
			lod.set(_Gb, 'DeliveryAddress', _NewAddress);
		} else {
			this.setState({
				IsPopupAddress: false,
				Address: this.state.DeliveryAddress,
			});
		}
	};

	_RenderPopupAddress = () => {
		const _Def = {
			height: '100vh',
			'border-style': 'none',
			border: 'none',
		};

		return (
			<Ion.IonModal isOpen={this.state.IsPopupAddress} backdropDismiss={false}>
				<Ion.IonHeader>
					<Ion.IonToolbar color={this.global.Color}>
						<Ion.IonButtons slot="start">
							<Ion.IonButton
								onClick={() => this.setState({ IsPopupAddress: false })}
							>
								<Ion.IonIcon icon={chevronBack}></Ion.IonIcon>
							</Ion.IonButton>
						</Ion.IonButtons>
						<Ion.IonButtons slot="end">
							<Ion.IonButton onClick={() => this._SetAddress()}>
								<Ion.IonIcon icon={checkmark}></Ion.IonIcon>
							</Ion.IonButton>
						</Ion.IonButtons>
					</Ion.IonToolbar>
				</Ion.IonHeader>
				<Ion.IonContent>
					<Ion.IonCard style={_Def}>
						<Ion.IonListHeader>
							<Ion.IonText color={this.global.Color}>
								Delivery Address
							</Ion.IonText>
						</Ion.IonListHeader>
						<Ion.IonContent>
							<Ion.IonItem>
								<Ion.IonLabel position="stacked">
									Condo / Unit / Lot / Block / Apartment No.
								</Ion.IonLabel>
								<Ion.IonInput
									required
									type="text"
									placeholder="e.g A-11-7"
									value={this.state.UnitNo}
									onIonChange={e => {
										this.setState({ UnitNo: e.target.value });
									}}
								></Ion.IonInput>
							</Ion.IonItem>

							<br />

							<Ion.IonText>Street Address</Ion.IonText>

							<br />

							<GooglePlacesAutocomplete
								style={_Def}
								apiKey={this.global.WebInfo.GoogleApiKey}
								autocompletionRequest={{
									componentRestrictions: { country: ['my'] },
								}}
								//  selectProps={{ onChange: (e) => console.log('gggg',e.label) }}
								selectProps={{
									onChange: e => this.setState({ DeliveryAddress: e.label }),
								}}
							/>
						</Ion.IonContent>
					</Ion.IonCard>
				</Ion.IonContent>
			</Ion.IonModal>
		);
	};

	_RenderGetCurrentAddress = () => {
		if (this.global.Default.SelectedServiceCode === 'S') {
			return null;
		}

		return (
			<div>
				<Ion.IonList>
					<Ion.IonListHeader>
						<CurrentLocation
							onFetchAddress={results => {
								this.setState({ Address: results[0].formatted_address });
							}}
							onError={(type, status) => { }}
						>
							{({ getCurrentLocation, loading }) => (
								<Ion.IonButton
									size="small"
									fill="outline"
									color={this.global.Color}
									onClick={getCurrentLocation}
								>
									<Ion.IonIcon icon={navigate}></Ion.IonIcon>
									Get Current Location
								</Ion.IonButton>
							)}
						</CurrentLocation>
						<Ion.IonButton
							size="small"
							fill="outline"
							color={this.global.Color}
							onClick={() => this.setState({ IsPopupAddress: true })}
						>
							<Ion.IonIcon icon={search}></Ion.IonIcon>
							Search
						</Ion.IonButton>
					</Ion.IonListHeader>

					<Ion.IonItem>
						<Ion.IonLabel position="stacked"></Ion.IonLabel>
						<Ion.IonTextarea
							required
							autoGrow
							type="text"
							value={this.state.Address}
						></Ion.IonTextarea>
					</Ion.IonItem>
				</Ion.IonList>
			</div>
		);
	};

	_RenderHeader() {
		return (
			<Ion.IonHeader>
				<Ion.IonToolbar color={this.global.Color}>
					<Ion.IonButtons slot="start">
						<Ion.IonButton onClick={() => this.setState({ IsBack: true })}>
							<Ion.IonIcon icon={chevronBack}></Ion.IonIcon>
						</Ion.IonButton>
					</Ion.IonButtons>
					<Ion.IonTitle>
						{this.global.Default.SelectedServiceInfo.Name}
					</Ion.IonTitle>
					<Ion.IonButtons slot="end">
						<Ion.IonButton onClick={() => this._RenderValidation()}>
							<Ion.IonIcon icon={checkmark}></Ion.IonIcon>
						</Ion.IonButton>
					</Ion.IonButtons>
				</Ion.IonToolbar>
			</Ion.IonHeader>
		);
	}

	_RenderValidation = async () => {
		let _Rs = await this._ValidateValue();

		if (_Rs.IsError === true) {
			this.setState({ IsError: true, ErrorMessage: _Rs.ErrMessage });
			return null;
		}

		this._RenderFetchOutlet();
	};

	_GetAdd = (x, zAddress) => {
		let _Rs = GetAddressDistance('F', zAddress);
	};

	_SetSelectedValue = async (
		zOutletCode,
		zOutletName,
		zAddress,
		zOutletData
	) => {
		let _Gb = this.global.WebInfo;
		//  console.log("Start--->1");
		// let _Rs = GetAddressDistance("F", zAddress);
		//  let _Rs = this._GetAdd("F", zAddress);
		// console.log("Start--->2", _Rs);
		// _Rs = GetAddressDistance("T", this.state.Address);
		//_Rs = this._GetAdd("T", this.state.Address);
		//	console.log('Start--->3', zOutletData.DataRow.ItemArray[28]);

		if (this.global.Default.SelectedServiceCode === 'E') {
			let _Rs = await GetAddressDistance('F', zAddress);
			//console.log("Return----111");
			_Rs = await GetAddressDistance('T', this.state.Address);
			//console.log("Return----222");
		}

		let _WebPaymentCode = zOutletData.DataRow.ItemArray[28];

		lod.set(_Gb, 'OutletCode', zOutletCode);
		lod.set(_Gb, 'OutletName', zOutletName);
		lod.set(_Gb, 'MobileNo', this.state.MobileNo);
		lod.set(_Gb, 'ServiceCode', this.global.Default.SelectedServiceCode);
		lod.set(_Gb, 'FromAddress', zAddress);
		lod.set(_Gb, 'ToAddress', this.state.Address);
		lod.set(_Gb, 'CustomerName', this.state.CustomerName);
		lod.set(_Gb, 'Remark', this.state.Remark);
		lod.set(_Gb, 'WebPaymentCode', _WebPaymentCode);
		//console.log("This-> Global", this.global.WebInfo);

		this.setState({ IsSuccess: true });
	};

	_RenderListOutlet = () => {
		let _Def = {
			height: '180px',
			width: '100%',
		};

		if (this.state.OutletData === null) {
			return null;
		}

		//    console.log('Location', this.state.DeliveryAddressDistance)

		return (
			<Ion.IonModal isOpen={this.state.IsListOutlet} backdropDismiss={false}>
				<Ion.IonHeader>
					<Ion.IonToolbar color={this.global.Color}>
						<Ion.IonButtons slot="start">
							<Ion.IonButton
								onClick={() =>
									this.setState({ IsListOutlet: false, IsSelectOutlet: true })
								}
							>
								<Ion.IonIcon icon={chevronBack}></Ion.IonIcon>
							</Ion.IonButton>
						</Ion.IonButtons>
					</Ion.IonToolbar>
				</Ion.IonHeader>
				<Ion.IonContent>
					<Ion.IonList>
						<Ion.IonListHeader>
							<Ion.IonText>Select Outlet</Ion.IonText>
						</Ion.IonListHeader>
						<br />

						{this.state.OutletData.map(item => (
							<div>
								<Ion.IonItem
									onClick={() =>
										this._SetSelectedValue(
											item.Code,
											item.Name,
											item.Address,
											item
										)
									}
								>
									<Ion.IonThumbnail slot="start">
										<Ion.IonImg
											src={item.ImageName}
											alt=""
											style={_Def}
											className="MyList"
										></Ion.IonImg>
									</Ion.IonThumbnail>
									<Ion.IonLabel style={this.global.App.TextNormal}>
										{item.Name}
										<br />
										<br />
										<Ion.IonText
											className="ion-text-wrap"
											style={this.global.App.TextNormal}
										>
											{item.Address}
										</Ion.IonText>
										<br />
										<br />
										<Ion.IonText style={this.global.App.TextRedNormal}>
											{item.Distance}
										</Ion.IonText>
									</Ion.IonLabel>
								</Ion.IonItem>
							</div>
						))}
					</Ion.IonList>
				</Ion.IonContent>
			</Ion.IonModal>
		);
	};

	_RenderFetchOutlet = async () => {
		let _Value2 = {
			Address: encodeURIComponent(this.state.Address),
			zGoogleApiKey: this.global.WebInfo.GoogleApiKey,
		};
		let _Value = { Type: 'GETOUTLET', QueryType: 'GETOUTLET', Value: _Value2 };

		//	console.log('object', _Value);

		let _Result = await CallGetApi(_Value);
		//console.log('Return', _Result);

		if (_Result.IsSuccess === 0) {
			this.setState({
				IsSubmit: false,
				IsError: true,
				ErrorMessage: _Result.ErrorMessage,
			});
		} else {
			this.setState({
				IsSubmit: false,
				IsError: false,
				IsListOutlet: true,
				OutletData: _Result.ListOutlet,
			});
		}
	};

	_RenderError = () => {
		const _Style = { 'text-align': 'center' };
		const { Paragraph, Text } = Typography;

		return (
			<Modal
				centered
				visible={this.state.IsError}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
				footer={false}
				closable={false}
			>
				<Res
					status="warning"
					title={'Alert'}
					subTitle={''}
					extra={[
						<Button
							type="primary"
							key="Retry"
							size="large"
							block
							onClick={() => this.setState({ IsError: false })}
						>
							Ok
						</Button>,
					]}
				/>
				<div className="desc">
					<Paragraph>
						<Text strong style={{ color: 'red', fontSize: 16 }}>
							Message
						</Text>
					</Paragraph>
					<Paragraph>{this.state.ErrorMessage}</Paragraph>
				</div>
			</Modal>
		);
	};

	_AlertError = x => {
		return (
			<Ion.IonAlert
				backdropDismiss={false}
				isOpen={this.state.IsError}
				onDidDismiss={() => this.setState({ IsError: false })}
				message={this.state.ErrorMessage}
				buttons={['OK']}
			/>
		);
	};

	_RenderRemarkTxt = () => {
		return (
			<Ion.IonTextarea
				value={this.state.Remark}
				onIonChange={e => this.setState({ Remark: e.detail.value })}
			/>
		);
	};
	render() {
		//console.log('Global-Done', this.state);

		if (this.state.IsBack === true) {
			return <Main />;
		}

		if (this.state.IsSuccess === true) {
			// console.log("Global-Done", this.global);

			if (
				this.global.Default.SelectedServiceCode === 'E' &&
				this.global.WebInfo.IsLinkLalamove === 1
			) {
				GetQuotation();
			}

			return <PosOrder />;
		}

		return (
			<Ion.IonApp>
				{this._RenderHeader()}
				<Ion.IonContent fullscreen>
					{this._RenderContactNo()}
					{this._RenderGetCurrentAddress()}
					{this._RenderPopupAddress()}
					{this._AlertError()}
					{this._RenderListOutlet()}
				</Ion.IonContent>
			</Ion.IonApp>
		);
	}
}

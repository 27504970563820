import React, { Component } from "react";
import { Form, Input, Button, Checkbox } from "antd";
import { CallPostApi, ConvertFormat, SaveCart } from "../../functions/General";

export default class TestRev extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Data: null,
    };
  }

  _Submit = async () => {
    let _Data = {
      Amount: "12.2",
      CustomerName: "Phang",
      CustomerEmail: "-",
      PaymentID: "1",
    };
    let _Value5 = { Type: "GETREV", Value: _Data };
    let _Result = await CallPostApi(_Value5);
    console.log(_Result);
    // console.log('object',_Result.RevValue.URL)

    window.open(_Result.RevValue.URL, "_self");
     this.setState({ Data: _Result.RevValue });

    // this._Submit2(_Result.RevValue);
  };

  _Submit2 = async (x) => {
    //  let _Data = x
    //   let _Value5 = { Type: "POSTREV", Value: _Data };
    //      let _Result = await CallPostApi(_Value5);
    //      console.log( _Result)

    let NewData = JSON.stringify(this.state.Data);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: NewData,
      redirect: "follow",
    };

    await fetch(
      "https://staging-gateway.revpay.com.my/payment_json",
      requestOptions
    )
      .then((res) => res.json())
      .then((json) => {
        console.log("object", json);
      //  window.open(json.URL, "_self");
      });
  };

  componentDidMount() {
    this._Submit();
  }

  _RenderForm = () => {
    return (
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        //  onFinish={onFinish}
        //  onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="remember"
          valuePropName="checked"
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  };

  render() {
    if (this.state.Data != null) {
      this._Submit2(this.state.Data);
    }

    // console.log(object)
    return <div>{this._RenderForm()}</div>;
  }
}

import React, { Component } from 'reactn';
import {
	caretForward,
	arrowBack,
	cart,
	trashBin,
	reader,
	create,
	chevronBack,
	searchOutline,
	createOutline,
	closeCircle,
	saveOutline,
} from 'ionicons/icons';
import * as Ion from '@ionic/react';
import * as lod from 'lodash';
import '../Css/Style.css';
import {
	CallPostApi,
	ConvertFormat,
	SaveCart,
	GetUpSell,
} from '../functions/General';
import Main from './Main';
import {
	Result as Res,
	Drawer,
	Modal,
	Spin,
	Button,
	Typography,
	Space,
	List,
	Card,
	Col,
	Row,
	Tabs,
	Checkbox,
} from 'antd';
import swal from 'sweetalert';
import Result from './Result';
import CheckOut from './Web/CheckOut';
import SaveData from './SaveData';

let _ErrModiCount = 0;

export default class PosOrder extends Component {
	constructor(props) {
		super(props);

		//	console.log('Global->', this.global.WebInfo.PaymentInfo);
		//	console.log('Payment', this.global.WebInfo.PaymentInfo.BasedUrl);
		let data = lod.first(this.global.ClassGroupInfo);
		let data3 = lod.filter(
			this.global.ClassDetailsInfo,
			c => c.ClassType === data.Code
		);
		let data4 = lod.first(data3);
		//	console.log('Log first->', data);

		let _Value = { Qty: 1, Remark: '' };

		//console.log('Global', this.global);
		this.state = {
			ModiCheck: [],
			ModiIndex: 0,
			CondimentCheck: [],
			IsLine: 'full',
			ClassDetailsInfo: null,
			SelectedClassGroupCode: data.Code,
			SelectedClassDetailsCode: data4.Code,
			SelectedItemInfo: null,
			IsConfirmExit: false,
			IsOpenDrawer: false,
			IsOpenDrawer2: false,
			IsOpenRemark2: false,
			IsOpenSubDrawer: false,
			IsOpenModifierRemark: false,
			IsSearch: false,
			IsSubItem: false,
			IsSaveConfirm: false,
			IsCheckOut: false,
			IsItemPopup: false,
			IsOpenRemark: false,
			IsConfirmPopup: false,
			IsBack: false,
			IsAlert: false,
			IsEdit: false,
			IsSave: false,
			IsLoading: false,
			IsError: false,
			Value: _Value,
			LineRow: 0,
			IsButtonDisable: true,
			IsSaveDone: false,
			ErrorMessage: null,
			IsPaymentOption: false,
			IsPayNow: false,
			IsPayLater: true,
			ItemSearch: this.global.ItemInfo,
			SearchName: '~',
			IsUpSellPopup: false,
			SubItemData: null,
			UpSellCondition: null,
			ShowCondiment: false,
			CondimentCode: null,
			CondimentQty: 1,
			ShowModifier: false,
			ModifierCode: null,
			CondimentValue: '',
			ModifierValue: '',
		};
	}

	formRef = React.createRef();
	formRef2 = React.createRef();
	formRef3 = React.createRef();
	formRefCheck = React.createRef();

	_RenderHeader() {
		return (
			<Ion.IonHeader>
				<Ion.IonToolbar color={this.global.Color}>
					<Ion.IonButtons slot="start">
						<Ion.IonButton onClick={() => this._Action('BACK')}>
							<Ion.IonIcon icon={chevronBack}></Ion.IonIcon>
						</Ion.IonButton>
					</Ion.IonButtons>

					<Ion.IonTitle>
						{this.global.Default.SelectedServiceInfo.Name}
					</Ion.IonTitle>

					<Ion.IonButtons slot="end">
						<Ion.IonButton onClick={() => this.setState({ IsSearch: true })}>
							<Ion.IonIcon icon={searchOutline} />
						</Ion.IonButton>
						<Ion.IonButton onClick={() => this._Action('CHECKUPSELL')}>
							<Ion.IonIcon icon={cart} />
							{this._Action('BADGE')}
						</Ion.IonButton>
					</Ion.IonButtons>
				</Ion.IonToolbar>
				{this._RenderClassGroup()}
				{this._RenderClassDetails()}
			</Ion.IonHeader>
		);
	}

	_RenderClassGroup() {
		let data = this.global.ClassGroupInfo;

		//	console.log('Class Groupo', data);

		if (data === null) {
			return null;
		}

		return (
			<Ion.IonSegment color={this.global.Color} scrollable>
				{data.map(s => (
					<Ion.IonSegmentButton
						onClick={() => this._RenderSelectClassGroup(s.Code)}
					>
						<Ion.IonLabel>{s.Name}</Ion.IonLabel>
					</Ion.IonSegmentButton>
				))}
			</Ion.IonSegment>
		);
	}

	_RenderSelectClassGroup = zCode => {
		let data = lod.filter(
			this.global.ClassDetailsInfo,
			c => c.ClassType === zCode
		);

		//	console.log('Group', data);

		if (data.length === 0) {
			return null;
		}
		let data2 = lod.first(data);
		this.setState({
			SelectedClassGroupCode: zCode,
			SelectedClassDetailsCode: data2.Code,
		});
	};
	_RenderClassDetails() {
		let _DefCard = {
			width: '100%',
			height: '50px',
		};

		let data = lod.filter(
			this.global.ClassDetailsInfo,
			c => c.ClassType === this.state.SelectedClassGroupCode
		);

		if (data === null) {
			return null;
		}

		return (
			<div>
				<Ion.IonToolbar>
					<Ion.IonTitle color="dark" style={this.global.App.TextNormal}>
						{
							this.global.LanguagePack[this.global.LanguageNo]
								.ButtonSelectCategory
						}
					</Ion.IonTitle>
				</Ion.IonToolbar>

				<Ion.IonSegment color={this.global.Color} scrollable>
					{data.map(s => (
						<Ion.IonButton
							color="light"
							style={_DefCard}
							layout="icon-end"
							expand="block"
							fill="outline"
							onClick={() =>
								this.setState({ SelectedClassDetailsCode: s.Code })
							}
						>
							<Ion.IonThumbnail>
								<Ion.IonImg
									src={s.ImageName + '?' + Math.random()}
								></Ion.IonImg>
							</Ion.IonThumbnail>
							<Ion.IonLabel className="ion-text-center">
								<Ion.IonText color="dark" className="ion-text-wrap">
									<h6> &nbsp; {s.Name}</h6>
								</Ion.IonText>
							</Ion.IonLabel>
						</Ion.IonButton>
					))}
				</Ion.IonSegment>
			</div>
		);
	}

	_SaveData = async () => {
		let _Bills = [];

		let _Det = this.global.LiveData.BillDetails;

		lod.forEach(_Det, function (s, i) {
			lod.set(_Det[i], 'ItemInfo', _Det[i].ItemInfo2);

			if (_Det[i].Modifier.length !== 0) {
				lod.forEach(_Det[i].Modifier, function (v, k) {
					lod.set(
						_Det[i].Modifier[k],
						'ItemInfo',
						_Det[i].Modifier[k].ItemInfo2
					);
				});
			}
			if (_Det[i].ComboSet.length !== 0) {
				lod.forEach(_Det[i].ComboSet, function (v, m) {
					lod.set(
						_Det[i].ComboSet[m],
						'ItemInfo',
						_Det[i].ComboSet[m].ItemInfo2
					);
				});
			}
		});

		_Bills.push(this.global.LiveData.Bills);

		let _Data = {
			Bills: _Bills,
			BillDetails: this.global.LiveData.BillDetails,
		};

		//    console.log('Submit', this.global.LiveData.BillDetails)
		this.setState({ IsError: false, IsLoading: true });

		let _Value5 = { Type: 'SAVEDATA', Value: _Data };
		let _Result = await CallPostApi(_Value5);
		//   console.log('Save Result', _Result)
		if (_Result.IsSuccess === 1) {
			let _LiveData = { Bills: {}, BillDetails: [] };
			localStorage.setItem('TrsNo', _Result.TrsNo);
			this.setGlobal({ OrderNo: _Result.OrderNo, LiveData: _LiveData });
			this.setState({ IsError: false, IsSaveDone: true, IsLoading: false });
		} else {
			this.setState({
				ErrorMessage: _Result.ErrorMessage,
				IsError: true,
				IsSaveDone: false,
				IsLoading: false,
			});
		}
	};

	_StoreModifierValue = NewData => {
		let _Code = localStorage.getItem('ModifierCode');
		let data = lod.filter(
			this.global.ModifierDetailsInfo,
			c => c.ModifierCode === _Code.trim()
		);
		let _NewValue = '';
		let count = 0;
		console.log('Before --####', NewData, data);
		for (count = 0; count < data.length; count++) {
			console.log('Start.....', data[count].Name);
			if (data[count].IsCheck === true) {
				console.log('Hello', data[count].Name);
				if (data[count].Name !== '') {
					if (data[count].Name !== NewData) {
						_NewValue =
							_NewValue +
							data[count].Name +
							' (' +
							data[count].UnitPrice +
							'),';
					}
				}
			}

			_NewValue = _NewValue + NewData;

			console.log('Final', _NewValue);

			return _NewValue;
		}
	};
	_StoreCondimentValue = NewData => {
		let _Code = localStorage.getItem('CondimentCode');
		let data = lod.filter(
			this.global.CondimentDetailsInfo,
			c => c.Code === _Code.trim()
		);
		let _NewValue = '';
		let count = 0;
		let _Num = 1;
		//	console.log('Before --####', NewData, data)
		for (count = 0; count < data.length; count++) {
			//console.log('Start.....', data[count].Name)
			if (data[count].IsCheck === true) {
				//	console.log('Hello', data[count].Name)
				if (data[count].Name !== '') {
					if (data[count].Name !== NewData) {
						_NewValue = _NewValue + data[count].Name + ',';
						_Num = _Num + 1;
					}
				}
			}

			_NewValue = _NewValue + NewData;
			localStorage.setItem('TotalControlQty', _Num);

			let _Q = localStorage.getItem('TotalControlQty');
			console.log('Final ----++++', _NewValue, _Q);

			let _Ret = { Value: _NewValue, Count: _Num };

			return _Ret;
		}
	};

	_Action = (zType, zCode, zRow, zValue) => {
		let _TempValue = {};
		let _RefOutlet = this.global.OutletInfo.RefOutlet;
		let _Temp = [];

		switch (zType) {
			case 'PAYMENTLATEROPTION':
				let _Gb = this.global.WebInfo;
				if (_Gb.WithPayLater === 1) {
					//console.log('Retun False');
					return 'false';
				} else {
					//	console.log('Retun True');
					return 'true';
				}

			case 'ALERTBUTTON':
				switch (this.global.Default.AppMode) {
					case 'O':
						return this.global.LanguagePack[this.global.LanguageNo]
							.LabelCheckOut;

					default:
						return this.global.LanguagePack[this.global.LanguageNo]
							.ButtonComfirmCaption;
				}

			case 'ALERTTEXT':
				switch (this.global.Default.AppMode) {
					case 'O':
						return this.global.LanguagePack[this.global.LanguageNo]
							.AlertCheckOutQuestion;

					default:
						return this.global.LanguagePack[this.global.LanguageNo]
							.AlertQuestion;
				}

			case 'PAYMENTOPTION':
				switch (this.global.WebInfo.WithPayment) {
					case 1:
						this.setState({ IsPaymentOption: true });

					default:
						this.setState({ IsSaveConfirm: true });
						this.forceUpdate();
				}

			case 'SAVEOPTION':
				switch (this.global.Default.AppMode) {
					case 'O':
						this.setState({ IsSave: true });

						break;
					default:
						// this._SaveData();
						this.setState({ IsSave: true });
				}

			case 'CHECKUPSELL':
				let data19 = this.global.LiveData;

				if (data19.BillDetails.length === 0) {
					return null;
				}

				let _Ret = [];
				_Ret = GetUpSell();

				if (_Ret.length === 0) {
					this.setState({ IsConfirmPopup: true });
					break;
				} else {
					//	console.log('Return Ret', _Ret.length);

					let _Data = _Ret;

					this.setState({ UpSellCondition: _Ret, IsUpSellPopup: true });
					break;
				}

				break;

			case 'CART':
				let data9 = this.global.LiveData;

				if (data9.BillDetails.length === 0) {
					return null;
				}

				this.setState({ IsConfirmPopup: true, IsUpSellPopup: false });

				break;

			case 'SHOWCARTITEM':
				return <div></div>;

			case 'VALIDATECONDIMENTQTY':
				//	console.log('RefOutlet', _RefOutlet)
				//	console.log('Tis State', this.state.CondimentCheck);
				//[F15] Force to Condiment
				if (_RefOutlet.substr(14, 1) === 'O') {
					//console.log('Inside', this.state.CondimentCheck);
					if (this.state.CondimentCheck.length !== 0) {
						let data2 = lod.filter(this.state.CondimentCheck, c => c.Qty >= 1);

						//	console.log('data2', data2);

						if (data2.length === 0) {
							swal('Alert', 'Please select at least one choice !!!');
							return false;
						}

						let data = lod.filter(
							this.state.CondimentCheck,
							c => c.Qty === 0 && c.ControlQty != 0
						);

						if (data.length !== 0) {
							let _Txt = '';
							for (let i = 0; i < data.length; i++) {
								_Txt += data[i].Name + ',';
							}
							swal(
								'Alert',
								"Please select at least one choice for category '" +
									_Txt +
									"' !!!"
							);

							return false;
						} else {
							return true;
						}
					}
				}

				break;

			case 'VALIDATECONDIMENTCHECK':
				//	console.log('Condiment Qty', this.state.CondimentCheck);
				if (this.state.CondimentCheck.length !== 0) {
					let data = lod.filter(
						this.state.CondimentCheck,
						c => c.IsCheck === false
					);

					//	console.log("validatecheck", data)
					if (data.length !== 0) {
						let _Txt = '';
						for (let i = 0; i < data.length; i++) {
							_Txt += data[i].Name + ',';
						}

						swal('Alert', 'You reached the maximum limit for ' + _Txt + ' !!!');
						return false;
					} else {
						return true;
					}
				}
				break;

			case 'VALIDATEMODIFIERCHECK':
				//	console.log('mODIFIER Qty', this.state.ModiCheck);
				if (this.state.ModiCheck.length !== 0) {
					let data = lod.filter(this.state.ModiCheck, c => c.IsCheck === false);
					if (data.length !== 0) {
						let _Txt = '';
						for (let i = 0; i < data.length; i++) {
							_Txt += data[i].Name + ',';
						}

						swal('Alert', 'You reached the maximum limit for ' + _Txt + ' !!!');
						return false;
					} else {
						return true;
					}
				}
				break;

			case 'VALIDATEMODIFIERQTY':
				//[F14] Force to Modifier
				//	console.log('Tis State', this.state.ModiCheck);
				if (_RefOutlet.substr(13, 1) === 'O') {
					if (this.state.ModiCheck.length !== 0) {
						let data2 = lod.filter(this.state.ModiCheck, c => c.Qty >= 1);

						//	console.log('data2', data2);

						if (data2.length === 0) {
							swal('Alert', 'Please select at least one Modifier !!!');
							return false;
						}

						let data = lod.filter(
							this.state.ModiCheck,
							c => c.Qty === 0 && c.ControlQty != 0
						);
						if (data.length !== 0) {
							let _Txt = '';
							for (let i = 0; i < data.length; i++) {
								_Txt += data[i].Name + ',';
							}

							swal(
								'Alert',
								"Please select at least one Modifier for category '" +
									_Txt +
									"' !!!"
							);
							return false;
						} else {
							return true;
						}
					}
				} else {
					return true;
				}
				break;

			case 'GENERATECONDIMENTCHECK':
				let _Group = lod.filter(
					this.global.CondimentGroupInfo,
					c => c.Code === zCode
				);
				if (_Group.length !== 0) {
					for (let i = 0; i < _Group.length; i++) {
						let c;
						c = {
							Code: _Group[i].CondCode,
							Name: _Group[i].CondName,
							ControlQty: _Group[i].ControlQty,
							IsCheck: true,
							Desc: '',
							Qty: 0,
						};
						_Temp.push(c);
					}
				}
				return _Temp;
			case 'GENERATEMODIFIERCHECK':
				let _Group2 = lod.filter(
					this.global.ModifierGroupInfo,
					c => c.MenuCode === zCode
				);

				//	console.log('Modifier---$$$$', _Group2);
				if (_Group2.length !== 0) {
					for (let i = 0; i < _Group2.length; i++) {
						let c;
						c = {
							Code: _Group2[i].Code,
							Name: _Group2[i].Name,
							ControlQty: _Group2[i].ControlQty,
							IsCheck: true,
							Desc: '',
							Qty: 0,
							UnitPrice: 0,
							Remark: '',
						};
						_Temp.push(c);
					}
				}
				return _Temp;

			case 'SAVEREMARK':
				if (zCode === 'CART') {
					lod.set(
						this.global.LiveData.BillDetails[this.state.LineRow],
						'Remark',
						this.state.Remark
					);
					lod.set(this.state.Value, 'Remark', this.state.Remark);
				}
				this.setState({ IsOpenRemark: false });
				this.forceUpdate();
				break;

			case 'REMARK':
				if (this.global.LiveData.BillDetails.length !== 0) {
					let _Remark = this.global.LiveData.BillDetails[zRow].Remark;
					this.setState({
						Remark: _Remark,
						IsOpenRemark2: true,
						LineRow: zRow,
					});
				}

				break;
			case 'SHOWREMARK':
				if (this.global.LiveData.BillDetails[zRow].Remark !== '') {
					return (
						<Ion.IonText color={this.global.Color}>
							<br />
							<span style={this.global.App.TextGrayNormal}>
								{this.global.LiveData.BillDetails[zRow].Remark}
							</span>
						</Ion.IonText>
					);
				}
				break;
			case 'SHOWDISCOUNT':
				if (this.global.LiveData.BillDetails[zRow].LineDiscAmt !== 0) {
					return (
						<Ion.IonText color={this.global.Color}>
							<span>
								{this.global.LiveData.BillDetails[zRow].LineDiscRemark}
							</span>
							<span className="MyDisplayRight2">
								{ConvertFormat(
									0 - this.global.LiveData.BillDetails[zRow].LineDiscAmt
								)}
							</span>
							<br />
						</Ion.IonText>
					);
				}
				break;

			case 'SHOWCONDIMENTDETAILS':
				if (this.global.LiveData.BillDetails[zRow].Condiment !== '') {
					return (
						<Ion.IonText color={this.global.Color}>
							<br />
							<span style={this.global.App.TextRedNormal}>
								{this.global.LiveData.BillDetails[zRow].Condiment}
							</span>
						</Ion.IonText>
					);
				}
				break;

			case 'SHOWCOMBOSET':
				if (this.global.LiveData.BillDetails[zRow].ComboSet !== undefined) {
					if (this.global.LiveData.BillDetails[zRow].ComboSet.length !== 0) {
						let data = this.global.LiveData.BillDetails[zRow].ComboSet;
						return (
							<Ion.IonText style={this.global.App.TextNormal}>
								<br />
								<Ion.IonList>
									{data.map((c, index) => (
										<div>
											<span>- {c.MenuName}</span>
											<br />
										</div>
									))}
								</Ion.IonList>
							</Ion.IonText>
						);
					}
				}
				break;

			case 'SHOWMODIFIERDETAILS':
				//	console.log('modifier', this.global.LiveData.BillDetails[zRow].Modifier)

				const _Remark = x => {
					//	console.log('first Remark', x);
					if (x != undefined || x != '') {
						return (
							<div>
								<span style={this.global.App.TextRedNormal}>{x}</span>
							</div>
						);
					}
				};

				if (this.global.LiveData.BillDetails[zRow].Modifier !== undefined) {
					if (this.global.LiveData.BillDetails[zRow].Modifier.length !== 0) {
						let data = this.global.LiveData.BillDetails[zRow].Modifier;
						return (
							<Ion.IonText style={this.global.App.TextNormal}>
								<br />
								<Ion.IonList>
									{data.map((c, index) => (
										<div>
											<span>
												- {c.Qty} x {c.MenuName}
											</span>
											{_Remark(c.Remark)}

											<span className="MyDisplayRight2">
												{ConvertFormat(c.Subtotal)}
											</span>
											<br />
										</div>
									))}
								</Ion.IonList>
							</Ion.IonText>
						);
					}
				}
				break;

			case 'CONDIMENTCLICK_111':
				let _Index5 = this.global.CondimentDetailsInfo.findIndex(
					obj => obj.Id === zRow
				);

				let _ClickIndex = this.state.CondimentCheck.findIndex(
					obj => obj.Code === zCode
				);

				//	console.log('Condiment click', _Index5);
				if (_Index5 !== -1) {
					let _Check = this.global.CondimentDetailsInfo[_Index5].IsCheck;

					if (zValue !== 0) {
						let data3 = lod.filter(
							this.global.CondimentDetailsInfo,
							c => c.Code === zCode && c.IsCheck === true
						);

						//	console.log('New Global', this.global.CondimentDetailsInfo);
						console.log('zValue', zValue);
						console.log('data3', data3);
						console.log('this state', this.state.CondimentCheck);

						if (data3.length !== -1) {
							let _Index33 = this.state.CondimentCheck.findIndex(
								obj => obj.Code === zCode
							);

							//	console.log('iNDEX33', _Index33);
							console.log('index33-data3', data3);
							console.log(
								'this.state.CondimentCheck[_Index33] : ',
								this.state.CondimentCheck[_Index33]
							);
							console.log('ZValue : ', zValue);
							console.log('data3.length : ', data3.length);
							//	console.log('index33-Global', this.global.CondimentDetailsInfo);
							let _TotalControlQty =
								this.state.CondimentCheck[_Index33].ControlQty + 1;
							let _TotalQty = parseInt(localStorage.getItem('TotalControlQty'));

							console.log('Total ControlQty : ', _TotalControlQty);
							console.log('Total Qty : ', _TotalQty);

							if (_TotalControlQty < _TotalQty) {
								_ErrModiCount = _ErrModiCount + 1;
								lod.set(this.state.CondimentCheck[_Index33], 'IsCheck', false);
								localStorage.setItem('TotalControlQty', _TotalQty - 1);

								let _NewTotalQty = parseInt(
									localStorage.getItem('TotalControlQty')
								);
								console.log('New total Qty', _NewTotalQty);

								swal(
									'Alert',
									'You maximum limit of this category is ' + zValue + ' !!!'
								);
							} else {
								console.log('Update 11111....');

								let _Check = this.global.CondimentDetailsInfo[_Index5].IsCheck;

								lod.set(
									this.global.CondimentDetailsInfo[_Index5],
									'IsCheck',
									!_Check
								);

								let _Ret = this._StoreCondimentValue(
									this.global.CondimentDetailsInfo[_Index5].Name
								);
								lod.set(
									this.state.CondimentCheck[_ClickIndex],
									'Desc',
									_Ret.Value
								);
								lod.set(
									this.state.CondimentCheck[_ClickIndex],
									'Qty',
									_Ret.Count
								);
							}
						} else {
						}
					} else {
						console.log('Update 3333....');
						let _Index33 = this.state.CondimentCheck.findIndex(
							obj => obj.Code === zCode
						);
						lod.set(this.state.CondimentCheck[_Index33], 'Qty', 1);
					}
				}

				break;

			case 'CONDIMENTCLICK_OLD':
				let _Index = this.global.CondimentDetailsInfo.findIndex(
					obj => obj.Id === zRow
				);
				if (_Index !== -1) {
					let _Check = this.global.CondimentDetailsInfo[_Index].IsCheck;
					lod.set(this.global.CondimentDetailsInfo[_Index], 'IsCheck', !_Check);
					let _Index3 = this.state.CondimentCheck.findIndex(
						obj => obj.Code === zCode
					);
					let data4 = lod.filter(
						this.global.CondimentDetailsInfo,
						c => c.Code === zCode && c.IsCheck === true
					);
					if (data4.length === 0) {
						lod.set(this.state.CondimentCheck[_Index3], 'Qty', 0);
					} else {
						lod.set(this.state.CondimentCheck[_Index3], 'Qty', 1);
					}
				}

				break;
			case 'MODIFIERCLICK':
				//     console.log('Click--zRow', zRow)

				//	console.log('Modifier zValue', zValue);
				let _Index2 = this.global.ModifierDetailsInfo.findIndex(
					obj => obj.Id === zRow
				);

				console.log('Modifier click', _Index2);
				if (_Index2 !== -1) {
					let _Check = this.global.ModifierDetailsInfo[_Index2].IsCheck;
					lod.set(this.global.ModifierDetailsInfo[_Index2], 'IsCheck', !_Check);
					console.log('Save 1');

					let _ModiCode = localStorage.getItem('ModifierCode');
					let _Index29 = this.state.ModiCheck.findIndex(
						obj => obj.Code === _ModiCode
					);

					let _Det =
						this.global.ModifierDetailsInfo[_Index2].Name +
						' (' +
						this.global.ModifierDetailsInfo[_Index2].UnitPrice +
						')';
					let _NewValue = this._StoreModifierValue(_Det);
					console.log('NewValue-->1', _NewValue);
					console.log('NewValue-->2', this.state.ModiCheck, _Index29);
					lod.set(this.state.ModiCheck[_Index29], 'Desc', _NewValue);
					lod.set(this.state.ModiCheck[_Index29], 'Qty', 1);
					lod.set(this.state.ModiCheck[_Index29], 'IsCheck', true);

					if (zValue !== 0) {
						let data3 = lod.filter(
							this.global.ModifierDetailsInfo,
							c => c.ModifierCode === zCode && c.IsCheck === true
						);

						if (data3.length !== -1) {
							let _Index3 = this.state.ModiCheck.findIndex(
								obj => obj.Code === zCode
							);

							if (zValue < data3.length) {
								_ErrModiCount = _ErrModiCount + 1;
								lod.set(this.state.ModiCheck[_Index3], 'IsCheck', false);
								swal(
									'Alert',
									'You maximum limit of this category is ' + zValue + ' !!!'
								);
							} else {
								//	let _NewValue = this._StoreModifierValue(this.global.ModifierDetailsInfo[_Index2].Name)
								//	console.log('NewValue-->1', _NewValue)
								//	console.log('NewValue-->2', this.state.ModiCheck, _Index3)
								//	lod.set(this.state.ModiCheck[_Index3], 'Desc', _NewValue);
								lod.set(this.state.ModiCheck[_Index3], 'IsCheck', true);
								lod.set(this.state.ModiCheck[_Index3], 'Qty', 1);
							}
						} else {
						}
					} else {
						console.log('Save 2');
						let _Index3 = this.state.ModiCheck.findIndex(
							obj => obj.Code === zCode
						);
						lod.set(this.state.ModiCheck[_Index3], 'Qty', 1);
					}
				}

				break;

			case 'BACK':
				if (this.global.LiveData.BillDetails.length === 0) {
					this.setState({ IsAlert: false, IsBack: true });
				} else {
					this.setState({ IsAlert: true, IsBack: false });
				}
				break;
			case 'CONFIRMEXIT':
				this.setState({ IsAlert: false, IsConfirmExit: true, IsBack: true });
				break;

			case 'UPSELLITEMCLICK':
				//	console.log('Hello Click', zCode);

				//	console.log('Hello Click', zValue);
				if (zValue !== '@@') {
					//	return null;
				}

				let data33 = lod.filter(this.global.ItemInfo, c => c.Code === zCode);

				let _Code33 = data33[0].MenuCode;

				/* 
				_TempValue = {
					IsSuccess: 1,
					Type: _Type,
					Rate: _Rate,
					Amt: _Amt,
					DiscType: _DiscType,
					Remark: _Remark,
				}; */

				let _UpSellData = { IsUpSell: 1, UpSellData: zRow };

				_TempValue = {
					Type: 'ADD',
					ItemInfo: data33[0],
					ComboSet: [],
					Condiment: [],
					Modifier: [],
					Remark: null,
					UpSellData: _UpSellData,
					Qty: 1,
				};

				//	console.log('UpSell', _TempValue);

				//   console.log("Before Save", _TempValue);
				SaveCart(_TempValue);

				this.setState({ IsConfirmPopup: true, IsUpSellPopup: false });

			case 'CHECKSUBITEM':
				this._GetSubItem(zCode, '');
				break;

			case 'ITEMCLICK':
				if (zValue !== '@@') {
					return null;
				}

				let data = lod.filter(this.global.ItemAll, c => c.Code === zCode);

				//	console.log('Item Data', this.global.ItemAll);
				//	console.log('Item click', zCode);

				let _ModiCheck = this._Action('GENERATEMODIFIERCHECK', data[0].Code);
				let _CondimentCheck = this._Action(
					'GENERATECONDIMENTCHECK',
					data[0].Code
				);

				this.setState({
					IsSubItem: false,
					IsItemPopup: true,
					SelectedItemInfo: data,
					ModiCheck: _ModiCheck,
					CondimentCheck: _CondimentCheck,
					Qty: 0,
				});
				break;

			case 'RESETLINE':
				//	console.log('Reset Line');

				if (this.global.CondimentDetailsInfo !== null) {
					this.global.CondimentDetailsInfo.forEach(e2 => {
						e2.IsCheck = false;
					});
				}

				let _Value = { Qty: 1, Remark: '' };
				lod.set(this.state.Value, 'Qty', 1);
				lod.set(this.state.Value, 'Remark', '');
				lod.set(this.state, 'Remark', '');
				this.setState({
					IsItemPopup: false,
					Value: _Value,
					Remark: '',
					IsEdit: false,
					ShowCondiment: false,
					ShowModifier: false,
					IsConfirmPopup: false,
					CondimentValue: '',
					ModifierValue: '',
				});

				if (this.global.ModifierDetailsInfo !== null) {
					this.global.ModifierDetailsInfo.forEach(el => {
						el.IsCheck = false;
						el.ModiRemark = '';
						el.Remark = '';
					});
				}

				//	console.log('After Reset', this.global.ModifierDetailsInfo)

				break;

			case 'RESETLINE2':
				//	console.log('Reset Line');

				if (this.global.CondimentDetailsInfo !== null) {
					this.global.CondimentDetailsInfo.forEach(e2 => {
						e2.IsCheck = false;
					});
				}

				if (this.state.ModiCheck !== null) {
					this.state.ModiCheck.forEach(e3 => {
						e3.IsCheck = false;
						e3.Desc = '';
						e3.Qty = 0;
					});
				}

				if (this.state.CondimentCheck !== null) {
					this.state.CondimentCheck.forEach(e4 => {
						e4.IsCheck = false;
						e4.Desc = '';
						e4.Qty = 0;
					});
				}

				if (this.global.ModifierDetailsInfo !== null) {
					this.global.ModifierDetailsInfo.forEach(el => {
						el.IsCheck = false;
						el.ModiRemark = '';
						el.Remark = '';
					});
				}

				this.setState({
					ShowCondiment: false,
					ShowModifier: false,
					CondimentValue: '',
					ModifierValue: '',
				});
				break;

			case 'EDITQTY':
				//[F13] Accept only 1 qty
				if (_RefOutlet.substr(12, 1) === 'O') {
					return null;
				}

				let x = this.state.Value.Qty;
				if (zCode === '+') {
					x = x + 1;
				} else {
					x = x - 1;
					if (x === 0) {
						x = 1;
					}
				}
				lod.set(this.state.Value, 'Qty', x);
				this.forceUpdate();
				break;

			case 'SHOWCAPTION':
				if (zCode === '') {
					return null;
				}

				return (
					<Ion.IonItem lines="none">
						<Ion.IonLabel className="ion-text-wrap">
							<Ion.IonText>
								<h6>{zCode}</h6>
							</Ion.IonText>
						</Ion.IonLabel>
					</Ion.IonItem>
				);

			case 'SHOWCONDIMENTDET':
				let _NewValue = '';
				let count = 0;
				let data12 = this.state.CondimentCheck;
				for (count = 0; count < data12.length; count++) {
					//console.log('Start.....', data[count].Name)
					if (data12[count].IsCheck === true) {
						if (data12[count].Desc !== '') {
							_NewValue = _NewValue + data12[count].Desc + ',';
						}
						//	console.log('Hello', data[count].Name)
					}
				}

				return (
					<Ion.IonItem lines="none">
						<Ion.IonLabel className="ion-text-wrap">
							<Ion.IonText>
								<h6>{_NewValue}</h6>
							</Ion.IonText>
						</Ion.IonLabel>
					</Ion.IonItem>
				);

			case 'SHOWMODIFIERDET':
				//console.log('Before Modifier-->', this.state.ModiCheck);

				let _ModData2 = lod.filter(
					this.global.ModifierDetailsInfo,
					z => z.IsCheck === true
				);

				//	console.log('_ModData66', _ModData66);

				let _ModData00 = lod.filter(this.state.ModiCheck, z => z.Qty != 0);

				//	console.log('After  Modifier-->', this.state.ModiCheck);
				if (_ModData2.length != 0) {
					//console.log('Hello', _ModData2);

					const _Remark = x => {
						//	console.log('first Remark', x);
						if (x != undefined || x != '') {
							return (
								<div>
									<span style={this.global.App.TextRedNormal}>{x}</span>
								</div>
							);
						}
					};

					return (
						<Ion.IonItem lines="none">
							<Ion.IonLabel style={this.global.App.TextNormal}>
								<Ion.IonList>
									{_ModData2.map((c, index) => (
										<div>
											<span>
												- {c.Qty} x {c.Name}
											</span>
											<span className="MyDisplayRight2">
												{ConvertFormat(c.UnitPrice)}
											</span>
											{/* 	<br />
											<span style={this.global.App.TextGrayNormal}>
												* {c.Desc}
											</span> */}
											<br />

											{_Remark(c.ModiRemark)}
										</div>
									))}
								</Ion.IonList>
							</Ion.IonLabel>
						</Ion.IonItem>
					);
				} else {
					return <div></div>;
				}

			/* 
				for (count2 = 0; count2 < data24.length; count2++) {
					if (data24[count2].IsCheck === true) {
						if (data24[count2].Name !== '') {
							_NewValue2 = _NewValue2 + data24[count2].Name + ',';
						}
					}
				}

				return (
					<Ion.IonItem lines="none">
						<Ion.IonLabel className="ion-text-wrap">
							<Ion.IonText>
								<h6>{_NewValue2}</h6>
							</Ion.IonText>
						</Ion.IonLabel>
					</Ion.IonItem>
				); */

			case 'SAVECART':
				//	console.log('Cart - Condiment', this.state.CondimentCheck);
				//	console.log('Cart - CondimentValue', this.state.CondimentValue);

				//	let _ConData = lod.filter(
				//		this.global.CondimentDetailsInfo,
				//		c => c.IsCheck === true
				//	);

				let _ModData = lod.filter(
					this.global.ModifierDetailsInfo,
					z => z.IsCheck === true
				);
				//		console.log('Before Save---->', _ModData)

				let _ComboData = [];

				let _ConData = this.state.CondimentCheck;

				if (_ConData !== -1) {
					if (this._ValidateSubmitObj(1) === false) {
						return null;
					}
				}

				if (_ModData !== -1) {
					if (this._ValidateSubmitObj(0) === false) {
						return null;
					}
					/* 	if (this._Action('VALIDATEMODIFIERCHECK') === false) {
						return null;
					} */
				}

				let _Code = this.state.SelectedItemInfo[0].MenuCode;
				if (this.state.SelectedItemInfo[0].IsComboSet === 1) {
					_ComboData = lod.filter(
						this.global.ComboSetInfo,
						b => b.ComboMenuCode === _Code
					);
				}

				_TempValue = {
					Type: 'ADD',
					ItemInfo: this.state.SelectedItemInfo[0],
					ComboSet: _ComboData,
					Condiment: _ConData,
					Modifier: _ModData,
					Remark: this.state.Value.Remark,
					Qty: this.state.Value.Qty,
				};

				//	console.log('Before Save', _TempValue);
				SaveCart(_TempValue);
				this._Action('RESETLINE');

				//	console.log('Live ', this.global.LiveData);

				break;

			case 'BADGE':
				if (this.global.LiveData.BillDetails.length !== 0) {
					return (
						<Ion.IonBadge color="danger">
							{this.global.LiveData.BillDetails.length}
						</Ion.IonBadge>
					);
				} else {
					return <Ion.IonBadge color="danger"></Ion.IonBadge>;
				}

			case 'EDITLINE':
				let _NewQty = this.global.LiveData.BillDetails[zRow].Qty;

				switch (zCode) {
					case 'ADD':
						//[F13] Accept only 1 qty
						if (_RefOutlet.substr(12, 1) === 'O') {
							return null;
						}

						_NewQty = _NewQty + 1;
						break;
					case 'MINUS':
						//[F13] Accept only 1 qty
						if (_RefOutlet.substr(12, 1) === 'O') {
							return null;
						}

						_NewQty = _NewQty - 1;
						break;
					case 'REMOVE':
						_NewQty = 0;
						break;
					default:
						break;
				}

				if (_NewQty <= 0) {
					_NewQty = 0;
				}

				_TempValue = {
					Type: 'EDIT',
					Row: zRow,
					Qty: _NewQty,
				};

				SaveCart(_TempValue);

				break;

			default:
				break;
		}
	};

	_RenderOpenRemark2 = () => {
		//	console.log('Render this.state', this.state)

		if (this.global.LiveData.BillDetails !== undefined) {
			//	console.log('this.LiveData.BillDetails', this.global.LiveData.BillDetails)
			//	console.log('this.state.LineRow --->', this.state.LineRow)
			//	this.formRef3.current.textContent = ''
		}

		const _Remarkclick = () => {
			let _row = this.state.LineRow;

			//	let _Index5 = this.global.LiveData.BillDetails.findIndex(
			//		obj => obj.Id === this.state.LineRow
			//	);

			//	console.log('this.global.LiveData.BillDetails[_row]', this.global.LiveData.BillDetails[_row])

			let _Code1 = this.formRef3.current.textContent;

			//	console.log('formRef2', _Code1)
			//	this.setState({ IsOpenRemark2: false, IsOpenRemark: false, IsOpenModifierRemark: false, Remark: '' });
			//	this.setState({ IsOpenRemark2: false, Remark: '' });
			this.setState({ IsOpenRemark2: false, Remark: '' });

			lod.set(this.global.LiveData.BillDetails[_row], 'Remark', _Code1);

			//	console.log('this.state', this.state)
		};

		return (
			<Drawer
				title={this.global.LanguagePack[this.global.LanguageNo].LabelRemark}
				placement="bottom"
				key="top"
				onClose={() => this.setState({ IsOpenRemark2: false })}
				closable={true}
				visible={this.state.IsOpenRemark2}
			>
				<Ion.IonCard>
					<Ion.IonTextarea
						ref={this.formRef3}
						//onIonChange={e => this.setState({ Remark: e.detail.value })}
						//value={this.state.Remark}
						value={''}
						placeholder={
							this.global.LanguagePack[this.global.LanguageNo]
								.LabelSpecialRequest
						}
					/>
				</Ion.IonCard>
				<Ion.IonButton
					color={this.global.Color}
					expand="full"
					size="large"
					onClick={() => _Remarkclick()}
				>
					{this.global.LanguagePack[this.global.LanguageNo].LabelSave}
				</Ion.IonButton>
			</Drawer>
		);
	};

	_RenderOutText = c => {
		let _Out = { 'text-decoration': 'line-through', color: 'red' };
		if (c.OutStock === c.MenuCode) {
			return (
				<h3>
					<div style={_Out}>
						[{c.MenuCode}] - {c.MenuName}
					</div>
				</h3>
			);
		} else {
			return (
				<h3>
					[{c.MenuCode}] - {c.MenuName}
				</h3>
			);
		}
	};

	_RenderSearch = () => {
		let _Def = {
			height: '200px',
			width: '100%',
			objectFit: 'contain',
		};
		let _SizeButton = {
			width: '100%',
		};

		let _Out = { 'text-decoration': 'line-through', color: 'red' };

		let _Search = this.state.SearchName;

		//	console.log('Searc', _Search);

		if (_Search === '' || _Search === undefined) {
			_Search = '~';
		} else {
			//_Search = _Search.perCase;
		}

		let _Txt = _Search.toLocaleUpperCase();
		//	console.log('Upper', _Txt);

		let _Arr = this.global.ItemInfoAll;

		//console.log('Items', _Arr);
		let _Item = _Arr.filter(x => x.MenuSearch.includes(_Txt));
		//console.log('first 111', _Item);

		if (_Item === undefined) {
			//return null;
		}
		//	console.log('first', _Search);

		return (
			<Ion.IonModal isOpen={this.state.IsSearch} backdropDismiss={false}>
				<Ion.IonHeader>
					<Ion.IonToolbar color={this.global.Color}>
						<Ion.IonButtons slot="start">
							<Ion.IonButton onClick={() => this.setState({ IsSearch: false })}>
								<Ion.IonIcon icon={chevronBack}></Ion.IonIcon>
							</Ion.IonButton>
						</Ion.IonButtons>
						<Ion.IonTitle></Ion.IonTitle>
					</Ion.IonToolbar>
					<Ion.IonItem>
						<Ion.IonLabel position="stacked">Description</Ion.IonLabel>
						<Ion.IonInput
							placeholder="Enter Item description"
							name="Name"
							onIonChange={e => this.setState({ SearchName: e.detail.value })}
						>
							{' '}
						</Ion.IonInput>
					</Ion.IonItem>
				</Ion.IonHeader>
				<Ion.IonContent fullscreen>
					<Ion.IonList>
						{_Item.map((c, index) => (
							<Ion.IonItem
								//onClick={() => this._Action('ITEMCLICK', c.Code, 0, c.OutStock)}
								onClick={() => this._GetSubItem(c.Code, c)}
							>
								<Ion.IonAvatar slot="start">
									<Ion.IonImg loading="eager" src={c.ImageName} alt="" />
								</Ion.IonAvatar>
								<Ion.IonLabel>
									{this._RenderOutText(c)}
									<p>
										<h6 style={this.global.App.TextBold}>
											RM {ConvertFormat(c.PosPrice)}
										</h6>
									</p>
								</Ion.IonLabel>
							</Ion.IonItem>
						))}
					</Ion.IonList>
				</Ion.IonContent>
			</Ion.IonModal>
		);
	};

	_RenderViewItem = () => {
		if (this.state.SelectedItemInfo === null) {
			return null;
		}

		let data = this.state.SelectedItemInfo[0];

		//	console.log('View Item', data);
		let _Def = {
			height: '200px',
			width: '100%',
			objectFit: 'contain',
			//position: 'fixed',
			top: 0,
			left: 0,
		};

		let _Def2 = {
			'margin-top': '30%',
			height: '200px',
			width: '100%',
			//objectFit: 'contain',
		};

		let _SizeButton = {
			width: '100%',
		};

		let _Total = this.state.Value.Qty * data.PosPrice;
		//let _Total = this.state.Value.Qty * data.DefPrice;

		const _CheckCondiment = () => {
			//	console.log('Force Condiment', data.IsForceCondiment);
			//	console.log('Condiment', data.IsCondiment);

			if (data.IsForceCondiment === 1 || data.IsCondiment === 1) {
				//some condition
				return 1;
			} else {
				return 0;
			}
		};

		const _CheckModifier = () => {
			//	console.log('Force Condiment', data.IsForceCondiment);
			//	console.log('Condiment', data.IsCondiment);

			if (data.IsModifier === 1) {
				//some condition
				return 1;
			} else {
				return 0;
			}
		};

		let _Modi = _CheckModifier();
		let _Cond = _CheckCondiment();

		//console.log('Hello', _ModData2);

		const _Remark = x => {
			let _ModData2 = lod.filter(this.state.ModiCheck, z => z.Qty != 0);

			if ((_ModData2.length = 0)) {
				//	console.log('first Remark', x);
				if (x != undefined || x != '') {
					return (
						<div>
							<span style={this.global.App.TextRedNormal}>
								{this.state.Remark}
							</span>
						</div>
					);
				}
			}
		};

		return (
			<Ion.IonModal isOpen={this.state.IsItemPopup} backdropDismiss={false}>
				<Ion.IonHeader>
					<Ion.IonToolbar color={this.global.Color}>
						<Ion.IonButtons slot="start">
							<Ion.IonButton onClick={() => this._Action('RESETLINE')}>
								<Ion.IonIcon icon={chevronBack}></Ion.IonIcon>
							</Ion.IonButton>
						</Ion.IonButtons>
						<Ion.IonTitle>
							{data.Code} - {data.MenuName}
						</Ion.IonTitle>

						<Ion.IonButtons slot="end">
							<Ion.IonButton
							//   onClick={() => this.setState({ IsOpenRemark: true })}
							>
								<Ion.IonIcon icon={create}></Ion.IonIcon>
							</Ion.IonButton>
						</Ion.IonButtons>
					</Ion.IonToolbar>
				</Ion.IonHeader>
				<Ion.IonContent fullscreen>
					<Ion.IonItemGroup>
						<div className="ImageContainer33">
							<img
								loading="eager"
								src={data.ImageName2x + '?' + Math.random()}
								alt=""
								style={_Def}
								//	className="MyImage"
							/>
						</div>
						<div>
							<Ion.IonGrid>
								<Ion.IonRow>
									<Ion.IonCol>
										<Ion.IonButton
											color={this.global.Color}
											shape="round"
											fill="outline"
											style={_SizeButton}
											onClick={() => this._Action('EDITQTY', '-')}
										>
											-
										</Ion.IonButton>
									</Ion.IonCol>
									<Ion.IonCol>
										<Ion.IonButton
											color={this.global.Color}
											style={_SizeButton}
										>
											{this.state.Value.Qty}
										</Ion.IonButton>
									</Ion.IonCol>
									<Ion.IonCol>
										<Ion.IonButton
											color={this.global.Color}
											shape="round"
											fill="outline"
											style={_SizeButton}
											onClick={() => this._Action('EDITQTY', '+')}
										>
											+
										</Ion.IonButton>
									</Ion.IonCol>
								</Ion.IonRow>
							</Ion.IonGrid>
							<Ion.IonItem lines="none">
								<Ion.IonLabel className="ion-text-wrap" slot="start">
									<Ion.IonText>
										{data.MenuCode} - {data.MenuName}
									</Ion.IonText>
									<br />
									{_Remark(this.state.Remark)}
								</Ion.IonLabel>
								<Ion.IonLabel slot="end">
									<Ion.IonText color="danger">
										{ConvertFormat(_Total)}
									</Ion.IonText>
								</Ion.IonLabel>
							</Ion.IonItem>

							{this._Action('SHOWCAPTION', data.MenuCaption)}
							{this._Action('SHOWMODIFIERDET')}
							{this._Action('SHOWCONDIMENTDET')}
						</div>
					</Ion.IonItemGroup>
					{/* 	{this._ShowCondimentButton(_CheckCondiment, data.Code)}
					{this._ShowModifierButton(_CheckModifier, data.Code)} */}
					{/* 	{this._RenderModifier(data.Code, data.IsModifier)} */}

					{this._ShowCard(_Modi, _Cond, data.Code)}
				</Ion.IonContent>

				<Ion.IonFooter>
					<Ion.IonButton
						color={this.global.Color}
						//color={'danger'}
						expand="full"
						size="large"
						shape="round"
						onClick={() => this._Action('SAVECART')}
					>
						{this.global.LanguagePack[this.global.LanguageNo].ButtonAddCart}
					</Ion.IonButton>
				</Ion.IonFooter>
			</Ion.IonModal>
		);
	};

	_ShowCondimentButton = (z, zCode) => {
		if (z === 0) {
			return null;
		}

		return (
			<Ion.IonButton
				color={this.global.Color}
				expand="full"
				size="large"
				onClick={() =>
					this.setState({ ShowCondiment: true, CondimentCode: zCode })
				}
			>
				<Ion.IonIcon slot="start" icon={caretForward}></Ion.IonIcon>
				Choices
			</Ion.IonButton>
		);
	};

	_ShowCard = (zIsModifier, zIsCondiment, zCode) => {
		//	console.log('zIsModifier', zIsModifier);
		//	console.log('zIsCondiment', zIsCondiment);

		const _RenCondiment = () => {
			if (zIsCondiment == 1) {
				return (
					<Col span={12}>
						<Ion.IonButton
							expand="full"
							size="large"
							color="danger"
							onClick={() =>
								this.setState({ ShowCondiment: true, CondimentCode: zCode })
							}
						>
							Choices
						</Ion.IonButton>
					</Col>
				);
			}
		};
		const _RenModifier = () => {
			if (zIsModifier == 1) {
				return (
					<Col span={12}>
						<Ion.IonButton
							expand="full"
							size="large"
							color="danger"
							onClick={() =>
								this.setState({ ShowModifier: true, ModifierCode: zCode })
							}
						>
							Add on
						</Ion.IonButton>
					</Col>
				);
			}
		};
		return (
			<Row gutter={[8, 16]}>
				{_RenCondiment()}
				{_RenModifier()}
			</Row>
		);
	};

	_UpdateCondimentDet = (x, y, q, z) => {
		localStorage.setItem('CondimentCode', x);
		localStorage.setItem('CondimentQty', y);
		localStorage.setItem('CondimentTitle', q);
		localStorage.setItem('CondimentId', z);

		this.setState({ IsOpenDrawer: true });
	};

	_RenderCondimentScreen = () => {
		//	console.log('this.state.CondimentCheck', this.state.CondimentCheck)
		return (
			<Ion.IonModal isOpen={this.state.ShowCondiment} backdropDismiss={false}>
				<Ion.IonHeader>
					<Ion.IonToolbar>
						<Ion.IonButtons slot="start">
							<Ion.IonButton onClick={() => this._Action('RESETLINE2')}>
								<Ion.IonIcon icon={chevronBack}></Ion.IonIcon>
							</Ion.IonButton>
						</Ion.IonButtons>
						<Ion.IonTitle>Choices</Ion.IonTitle>
						{/* 					<Ion.IonButtons slot="end">
							<Ion.IonButton onClick={() => this._ValidateObj(1)}>
								<Ion.IonIcon icon={saveOutline}></Ion.IonIcon> 
								Confirm
							</Ion.IonButton>
						</Ion.IonButtons> */}
					</Ion.IonToolbar>
				</Ion.IonHeader>

				<Ion.IonContent fullscreen>
					<Ion.IonList>
						{this.state.CondimentCheck.map((c, index) => (
							<Ion.IonItem
								onClick={() =>
									this._UpdateCondimentDet(c.Code, c.ControlQty, c.Name, c.Id)
								}
							>
								<Ion.IonLabel>
									<h3>{c.Name}</h3>
									<span style={this.global.App.TextRedNormal}>{c.Desc}</span>
								</Ion.IonLabel>
							</Ion.IonItem>
						))}
					</Ion.IonList>
				</Ion.IonContent>

				<Ion.IonFooter>
					<Ion.IonButton
						color={this.global.Color}
						expand="full"
						size="large"
						onClick={() => this._ValidateObj(1)}
					>
						Confirm
					</Ion.IonButton>
				</Ion.IonFooter>
			</Ion.IonModal>
		);
	};

	_RenderCondimentDrawer = () => {
		let _Code = localStorage.getItem('CondimentCode');
		let _Qty = localStorage.getItem('CondimentQty');
		let _Title = localStorage.getItem('CondimentTitle');
		//	_Code = 'A';
		//console.log('Drawer', _Code);

		if (this.state.IsOpenDrawer === false) {
			return false;
		}

		if (_Code === null) {
			//return null;
		}

		if (_Code === undefined) {
			//return null;
		}

		let data = lod.filter(
			this.global.CondimentDetailsInfo,
			c => c.Code === _Code.trim()
		);

		//	console.log('After', data);

		if (data.length === 0) {
			return null;
		}

		const _CondimentClick = (x, zRow) => {
			let _Index5 = this.global.CondimentDetailsInfo.findIndex(
				obj => obj.Id === zRow
			);

			lod.set(
				this.global.CondimentDetailsInfo[_Index5],
				'IsCheck',
				x.detail.checked
			);
		};

		return (
			<Drawer
				title={_Title}
				placement="right"
				width={340}
				//	closable={false}
				onClose={() => this.setState({ IsOpenDrawer: false })}
				visible={this.state.IsOpenDrawer}
				key="left"
			>
				{data.map((c, index) => (
					<div>
						<Ion.IonItem lines="full">
							<Ion.IonLabel
								className="ion-text-wrap"
								style={this.global.App.TextNormal}
							>
								{c.Name}
								<br />
							</Ion.IonLabel>

							<Ion.IonCheckbox
								slot="start"
								//labelPlacement="start"
								style={this.global.App.TextNormal}
								color={this.global.Color}
								checked={c.IsCheck}
								//	indeterminate={true}
								//onIonChange={() =>this._Action('CONDIMENTCLICK', c.Code, c.Id, _Qty)
								onIonChange={x => _CondimentClick(x, c.Id)}
							></Ion.IonCheckbox>
							<br />
						</Ion.IonItem>
					</div>
				))}
				<br />

				<Space>
					<Button
						type="primary"
						key="Retry"
						block
						size="large"
						onClick={() => this.setState({ IsOpenDrawer: false })}
					>
						Cancel
					</Button>
					<Button
						key="Back"
						size="large"
						block
						onClick={() => this._Submit_CondimentClick()}
					>
						OK
					</Button>
				</Space>
			</Drawer>
		);
	};

	_Submit_CondimentClick = () => {
		let _CondimentCode = localStorage.getItem('CondimentCode');
		let _RefOutlet = this.global.OutletInfo.RefOutlet;

		//	console.log('CondimentCode', _CondimentCode)

		let _Det = lod.filter(
			this.global.CondimentDetailsInfo,
			c => c.Code === _CondimentCode && c.IsCheck == true
		);

		let _SelectedGroupIndex = this.state.CondimentCheck.findIndex(
			obj => obj.Code === _CondimentCode
		);
		let _ControlQty = this.state.CondimentCheck[_SelectedGroupIndex].ControlQty;

		//	console.log('_Det', _Det);

		// Get Selected Value
		let count = 0;
		let _Name = '';
		let _Qty = 0;
		for (count = 0; count < _Det.length; count++) {
			//	if (_Det[count].Name !== '') {
			_Name = _Name + _Det[count].Name + ',';
			_Qty = _Qty + 1;
		}

		//[F15] Force to Condiment
		if (_RefOutlet.substr(14, 1) === 'O') {
			if (_ControlQty != 0) {
				if (_Qty === 0) {
					swal('Alert', 'Please select at least one choice !!!');
					return false;
				}

				if (_Qty > _ControlQty) {
					swal(
						'Alert',
						'The choice you selected are already exceed limit of ' +
							_ControlQty +
							' !!!'
					);
					return false;
				}
			}

			//	console.log('Inside', this.state.CondimentCheck);
		} else {
			if (_ControlQty != 0) {
				if (_Qty > _ControlQty) {
					swal(
						'Alert',
						'The choice you selected are already exceed limit of ' +
							_ControlQty +
							' !!!'
					);
					return false;
				}
			}
		}

		if (_ControlQty != 0) {
			if (_Qty > _ControlQty) {
				swal(
					'Alert',
					'The choice you selected are already exceed limit of ' +
						_ControlQty +
						' !!!'
				);
				return false;
			}
		}

		if (_Name !== '') {
			_Name = _Name.slice(0, -1);
		}
		lod.set(this.state.CondimentCheck[_SelectedGroupIndex], 'Desc', _Name);
		lod.set(this.state.CondimentCheck[_SelectedGroupIndex], 'Qty', _Qty);

		this.setState({ IsOpenDrawer: false });

		//	console.log('New Group', this.state.CondimentCheck[_SelectedGroupIndex]);

		return true;
	};

	_ValidateCondiment = () => {
		let _Det = this.state.CondimentCheck;
		let _RefOutlet = this.global.OutletInfo.RefOutlet;

		//	let _SelectedGroupIndex = this.state.CondimentCheck.findIndex(obj => obj.Code === _CondimentCode);

		console.log('this.state.Condimentcheck', _Det);

		let count = 0;
		let _Name = '';
		let _Qty = 0;
		let _Mess = '';
		let _Error = 0;

		for (count = 0; count < _Det.length; count++) {
			//[F15] Force to Condiment
			if (_RefOutlet.substr(14, 1) !== 'O') {
				if (_Det[count].ControlQty !== 0) {
					if (_Det[count].Qty > _Det[count].ControlQty) {
						_Mess =
							'You have reached the maximum limit of  ' +
							_Det[count].ControlQty +
							' condiment on [' +
							_Det[count].Name +
							']';
						swal('Alert', _Mess);
						_Error = _Error + 1;
						break;
					}

					_Name = _Name + _Det[count].Desc + ',';
					_Qty = _Qty + 1;
				} else {
					if (_Det[count].Desc != '') {
						_Name = _Name + _Det[count].Desc + ',';
						_Qty = _Qty + 1;
					}
				}
			} else {
				if (_Det[count].ControlQty !== 0) {
					if (_Det[count].Qty > _Det[count].ControlQty) {
						_Mess =
							'You have reached the maximum limit of  ' +
							_Det[count].ControlQty +
							' condiment on [' +
							_Det[count].Name +
							']';
						swal('Alert', _Mess);
						_Error = _Error + 1;
						break;
					}

					_Name = _Name + _Det[count].Desc + ',';
					_Qty = _Qty + 1;
				}
			}
		}

		console.log('Error Code', _Error);
		console.log('_Name', _Name);

		if (_Error == 0) {
			if (_Name !== '') {
				_Name = _Name.slice(0, -1);
			}
			this.setState({ CondimentValue: _Name, ShowCondiment: false });
		}
	};

	_ShowModifierButton = (z, zCode) => {
		if (z === 0) {
			return null;
		}

		return (
			<Ion.IonButton
				color={this.global.Color}
				expand="full"
				size="large"
				onClick={() =>
					this.setState({ ShowModifier: true, ModifierCode: zCode })
				}
			>
				<Ion.IonIcon slot="start" icon={caretForward}></Ion.IonIcon>
				Select Modifier
			</Ion.IonButton>
		);
	};

	_UpdateModifierDet = (x, y, q, z) => {
		localStorage.setItem('ModifierCode', x);
		localStorage.setItem('ModifierQty', y);
		localStorage.setItem('ModifierTitle', q);
		localStorage.setItem('ModifierId', z);

		this.setState({ IsOpenDrawer2: true });
	};

	_RenderModifierScreen = () => {
		return (
			<Ion.IonModal isOpen={this.state.ShowModifier} backdropDismiss={false}>
				<Ion.IonHeader>
					<Ion.IonToolbar>
						<Ion.IonButtons slot="start">
							<Ion.IonButton onClick={() => this._Action('RESETLINE2')}>
								<Ion.IonIcon icon={chevronBack}></Ion.IonIcon>
							</Ion.IonButton>
						</Ion.IonButtons>
						<Ion.IonTitle>Add on</Ion.IonTitle>
						{/* 					<Ion.IonButtons slot="end">
							<Ion.IonButton onClick={() => this._ValidateObj(0)}>
								<Ion.IonIcon icon={saveOutline}></Ion.IonIcon> 
								Confirm
							</Ion.IonButton>
						</Ion.IonButtons> */}
					</Ion.IonToolbar>
				</Ion.IonHeader>

				<Ion.IonContent fullscreen>
					<Ion.IonList>
						{this.state.ModiCheck.map((c, index) => (
							<Ion.IonItem
								onClick={() =>
									this._UpdateModifierDet(c.Code, c.Qty, c.Name, c.Id)
								}
							>
								<Ion.IonLabel>
									<h3>{c.Name}</h3>
									<span style={this.global.App.TextRedNormal}>{c.Desc}</span>
								</Ion.IonLabel>
							</Ion.IonItem>
						))}
					</Ion.IonList>
				</Ion.IonContent>

				<Ion.IonFooter>
					<Ion.IonButton
						color={this.global.Color}
						expand="full"
						size="large"
						onClick={() => this._ValidateObj(0)}
					>
						Confirm
					</Ion.IonButton>
				</Ion.IonFooter>
			</Ion.IonModal>
		);
	};

	_RenderModifierDrawer = () => {
		let _Code = localStorage.getItem('ModifierCode');
		let _Qty = localStorage.getItem('ModifierQty');
		let _Title = localStorage.getItem('ModifierTitle');
		//	_Code = 'A';
		//	console.log('Modifier Drawer', _Code);

		if (this.state.IsOpenDrawer2 === false) {
			return false;
		}

		if (_Code === null) {
			//return null;
		}

		if (_Code === undefined) {
			//return null;
		}

		//	console.log('before Modifier', _Code, this.global.ModifierDetailsInfo);

		let data = lod.filter(
			this.global.ModifierDetailsInfo,
			c => c.ModifierCode === _Code.trim()
		);

		//	console.log('After Modifier', data);

		if (data.length === 0) {
			return null;
		}

		const _ModifierClick = (x, zRow) => {
			let _Index5 = this.global.ModifierDetailsInfo.findIndex(
				obj => obj.Id === zRow
			);

			//	console.log('Click Modifier', _Index5);
			//	console.log('this.global.ModifierDetailsInfo[_Index5]', this.global.ModifierDetailsInfo[_Index5]);
			lod.set(
				this.global.ModifierDetailsInfo[_Index5],
				'IsCheck',
				x.detail.checked
			);
		};

		const _ModifierRemarkClick = x => {
			let _Index5 = this.global.ModifierDetailsInfo.findIndex(
				obj => obj.Id === x
			);

			if (this.global.ModifierDetailsInfo[_Index5].IsCheck == false) {
				return false;
			}

			//this.setState({ ModiIndex: x, IsOpenModifierRemark: true });
			this.setState({ ModiIndex: x, IsOpenSubDrawer: true });
		};

		const _RenderRemark = x => {
			return (
				<Ion.IonTextarea
					ref={this.formRef4}
					//onIonChange={e => this.setState({ Remark: e.detail.value })}
					//value={this.state.Remark}
					placeholder={
						this.global.LanguagePack[this.global.LanguageNo].LabelSpecialRequest
					}
				/>
			);
		};

		const _RenderModifierOpenRemark = () => {
			const _Remarkclick = () => {
				let _Code = localStorage.getItem('ModifierCode');

				let _Index5 = this.global.ModifierDetailsInfo.findIndex(
					obj => obj.Id === this.state.ModiIndex
				);

				//	console.log('_Index5', _Index5)

				let data = this.global.ModifierDetailsInfo[_Index5];
				//	lod.set(data, 'Remark', zRemark);

				//	console.log('data', data)

				let _Code1 = this.formRef.current.textContent;
				//	console.log("_Code", _Code1)
				lod.set(data, 'Remark', _Code1);
				this.setGlobal({
					ModiRemark: {
						...this.global.ModifierDetailsInfo[_Index5],
						ModiRemark: _Code1,
					},
				});
				this.setState({ IsOpenSubDrawer: false, Remark: '' });

				//	console.log(this.global.ModifierDetailsInfo[_Index5])
			};

			return (
				<Drawer
					title={_Title}
					placement="right"
					closable={false}
					//	width={340}
					onClose={() => this.setState({ IsOpenSubDrawer: false })}
					visible={this.state.IsOpenSubDrawer}
					key="left"
				>
					<Ion.IonCard>
						<Ion.IonTextarea
							ref={this.formRef}
							//	onIonChange={e => this.setState({ Remark: e.detail.value })}
							//value={this.state.Remark}
							placeholder={
								this.global.LanguagePack[this.global.LanguageNo]
									.LabelSpecialRequest
							}
						/>
					</Ion.IonCard>
					<Ion.IonButton
						color={this.global.Color}
						expand="full"
						size="large"
						onClick={() => _Remarkclick()}
						//onClick={(z) => _Hello()}
					>
						{this.global.LanguagePack[this.global.LanguageNo].LabelSave}
					</Ion.IonButton>
				</Drawer>
			);
		};

		return (
			<div>
				<Drawer
					title={_Title}
					placement="right"
					closable={false}
					width={340}
					onClose={() => this.setState({ IsOpenDrawer2: false })}
					visible={this.state.IsOpenDrawer2}
					key="left"
				>
					{data.map((c, index) => (
						<div>
							<Ion.IonItem lines="full">
								<Ion.IonLabel
									className="ion-text-wrap"
									style={this.global.App.TextNormal}
								>
									{c.Name}
									<br />
									<span style={this.global.App.TextRedNormal}>{c.Remark}</span>
								</Ion.IonLabel>
								<Ion.IonLabel
									className="ion-text-right"
									style={this.global.App.TextNormal}
								>
									{ConvertFormat(c.UnitPrice)}
								</Ion.IonLabel>

								<Ion.IonButton onClick={() => _ModifierRemarkClick(c.Id)}>
									<Ion.IonIcon icon={createOutline}></Ion.IonIcon>
								</Ion.IonButton>

								<Ion.IonCheckbox
									ref={this.formRefCheck}
									slot="start"
									style={this.global.App.TextNormal}
									color={this.global.Color}
									checked={c.IsCheck}
									onIonChange={x => _ModifierClick(x, c.Id)}
								></Ion.IonCheckbox>
								<br />
							</Ion.IonItem>
						</div>
					))}

					<br />

					<Space>
						<Button
							type="primary"
							key="Retry"
							block
							size="large"
							onClick={() =>
								this.setState({
									IsOpenDrawer2: false,
									IsOpenModifierRemark: false,
								})
							}
						>
							Cancel
						</Button>
						<Button
							key="Back"
							size="large"
							block
							onClick={() => this._Submit_ModifierClick()}
						>
							OK
						</Button>
					</Space>
					{_RenderModifierOpenRemark()}
				</Drawer>
			</div>
		);
	};

	_Submit_ModifierClick = () => {
		let _ModifierCode = localStorage.getItem('ModifierCode');
		let _RefOutlet = this.global.OutletInfo.RefOutlet;
		//	console.log('ModifierCode', _ModifierCode);
		//	console.log('this.state.ModiCheck', this.state.ModiCheck);

		let _Det = lod.filter(
			this.global.ModifierDetailsInfo,
			c => c.ModifierCode === _ModifierCode && c.IsCheck == true
		);

		//	console.log('_Det--->', _Det);

		let _SelectedGroupIndex = this.state.ModiCheck.findIndex(
			obj => obj.Code === _ModifierCode
		);
		let _ControlQty = this.state.ModiCheck[_SelectedGroupIndex].ControlQty;

		//	console.log('_ControlQty', _ControlQty);

		// Get Selected Value
		let count = 0;
		let _Name = '';
		let _Qty = 0;
		let _Remark = '';
		let _UnitPrice = 0;
		for (count = 0; count < _Det.length; count++) {
			//	if (_Det[count].Name !== '') {
			_Name = _Name + _Det[count].Name + ',';

			_UnitPrice = _Det[count].UnitPrice;
			_Qty = _Qty + 1;

			if (_Det[count].Remark === undefined) {
				_Remark = '';
			} else {
				_Remark = _Det[count].Remark;
			}
		}

		//[F14] Force to Modifier
		//	console.log('Tis State', this.state);
		if (_RefOutlet.substr(13, 1) === 'O') {
			if (_ControlQty != 0) {
				if (_Qty === 0) {
					swal('Alert', 'Please select at least one Modifier !!!');
					return false;
				}

				if (_Qty > _ControlQty) {
					swal(
						'Alert',
						'The Modifier you selected are already exceed limit of ' +
							_ControlQty +
							' !!!'
					);
					return false;
				}
			}
		} else {
			if (_ControlQty != 0) {
				if (_Qty > _ControlQty) {
					swal(
						'Alert',
						'The Modifier you selected are already exceed limit of ' +
							_ControlQty +
							' !!!'
					);
					return false;
				}
			}
		}

		if (_Name !== '') {
			_Name = _Name.slice(0, -1);
		}
		lod.set(this.state.ModiCheck[_SelectedGroupIndex], 'Desc', _Name);
		lod.set(this.state.ModiCheck[_SelectedGroupIndex], 'Qty', _Qty);
		lod.set(this.state.ModiCheck[_SelectedGroupIndex], 'Remark', _Remark);
		lod.set(this.state.ModiCheck[_SelectedGroupIndex], 'UnitPrice', _UnitPrice);

		this.setState({ IsOpenDrawer2: false });

		//console.log('New Group', this.state.ModiCheck[_SelectedGroupIndex]);
		//	console.log('_Submit_ModifierClick', this.global.ModifierDetailsInfo)

		return true;
	};

	_RenderModifierOpenRemark11111 = () => {
		const _Remarkclick = () => {
			let _Code = localStorage.getItem('ModifierCode');

			let _Index5 = this.global.ModifierDetailsInfo.findIndex(
				obj => obj.Id === this.state.ModiIndex
			);

			//	console.log('_Index5', _Index5)

			let data = this.global.ModifierDetailsInfo[_Index5];
			//	lod.set(data, 'Remark', zRemark);

			//	console.log('data', data)

			let _Code1 = this.formRef.current.textContent;
			//	console.log("_Code", _Code1)
			lod.set(data, 'Remark', _Code1);
			this.setGlobal({
				ModiRemark: {
					...this.global.ModifierDetailsInfo[_Index5],
					ModiRemark: _Code1,
				},
			});
			this.setState({ IsOpenModifierRemark: false, Remark: '' });

			//	console.log(this.global.ModifierDetailsInfo[_Index5])
		};

		return (
			<Modal
				title={this.global.LanguagePack[this.global.LanguageNo].LabelRemark}
				//placement="bottom"
				//key="bottom"
				onCancel={() => this.setState({ IsOpenModifierRemark: false })}
				//	closable={true}
				open={this.state.IsOpenModifierRemark}
				//	getContainer={false}
			>
				<Ion.IonCard>
					<Ion.IonTextarea
						ref={this.formRef}
						//	onIonChange={e => this.setState({ Remark: e.detail.value })}
						//value={this.state.Remark}
						placeholder={
							this.global.LanguagePack[this.global.LanguageNo]
								.LabelSpecialRequest
						}
					/>
				</Ion.IonCard>
				<Ion.IonButton
					color={this.global.Color}
					expand="full"
					size="large"
					onClick={() => _Remarkclick()}
					//onClick={(z) => _Hello()}
				>
					{this.global.LanguagePack[this.global.LanguageNo].LabelSave}
				</Ion.IonButton>
			</Modal>
		);
	};
	_ValidateModifier = () => {
		let _Det = this.state.ModiCheck;

		//	console.log('_Det', _Det);
		let count = 0;
		let _Name = '';
		let _Qty = 0;
		let _Mess = '';
		let _Error = 0;
		for (count = 0; count < _Det.length; count++) {
			if (_Det[count].ControlQty !== 0) {
				if (_Det[count].Qty > _Det[count].ControlQty) {
					_Mess =
						'You have reached the maximum limit ' +
						_Det[count].ControlQty +
						' Modifier on [' +
						_Det[count].Name +
						']';
					swal('Alert', _Mess);
					_Error = _Error + 1;
					break;
				}

				_Name = _Name + _Det[count].Desc + ',';
				_Qty = _Qty + 1;
			}
		}

		//	console.log('Error', _Error);

		if (_Name !== '') {
			_Name = _Name.slice(0, -1);
		}

		if (_Error == 0) {
			this.setState({
				ModifierValue: _Name,
				Remark: _Name,
				ShowModifier: false,
			});
		}

		console.log('_ValidateModifier', this.global.ModifierDetailsInfo);
	};

	_RenderModifier = (zCode, zIsModifier) => {
		if (zIsModifier === 0) {
			return null;
		}

		let data = lod.filter(
			this.global.ModifierGroupInfo,
			c => c.MenuCode === zCode
		);
		if (data.length === 0) {
			return null;
		}

		return (
			<div>
				<Ion.IonItem
					style={this.global.App.TextGrayNormal}
					color={this.global.Color}
				>
					<Ion.IonLabel>
						<h6>
							{
								this.global.LanguagePack[this.global.LanguageNo]
									.ButtonSelectModifier
							}
						</h6>
					</Ion.IonLabel>
				</Ion.IonItem>

				{data.map(s => (
					<div>
						<Ion.IonItem>
							<Ion.IonLabel className="ion-text-center" color="dark">
								<h2 style={this.global.App.TextNormal}>{s.Name}</h2>
							</Ion.IonLabel>
						</Ion.IonItem>
						<br />
						{this._RenderModifierDetails(s.Code, s.ControlQty)}
					</div>
				))}
			</div>
		);
	};
	_RenderModifierDetails = (zCode, zControlQty) => {
		//   console.log('Modifier List',this.global.ModifierDetailsInfo)

		let data = lod.filter(
			this.global.ModifierDetailsInfo,
			c => c.ModifierCode === zCode
		);
		if (data.length === 0) {
			return null;
		}

		return data.map(c => (
			<div>
				<Ion.IonItem lines="full">
					<Ion.IonLabel
						className="ion-text-wrap"
						style={this.global.App.TextNormal}
					>
						{c.Name}
					</Ion.IonLabel>
					<Ion.IonLabel
						className="ion-text-right"
						style={this.global.App.TextNormal}
					>
						{ConvertFormat(c.UnitPrice)}
					</Ion.IonLabel>

					<Ion.IonCheckbox
						slot="start"
						style={this.global.App.TextNormal}
						color={this.global.Color}
						onIonChange={() =>
							this._Action('MODIFIERCLICK', zCode, c.Id, zControlQty)
						}
					></Ion.IonCheckbox>
					<br />
				</Ion.IonItem>
			</div>
		));
	};

	_OutofStock = c => {
		let _Hide = {
			'background-color': 'rgba(0, 0, 0, 0.5)',
			width: '100%',
			color: 'white',
			position: 'absolute',
			top: '30%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
		};

		if (c.OutStock !== '@@') {
			return (
				<div style={_Hide}>
					{this.global.LanguagePack[this.global.LanguageNo].LabelOutStock}
				</div>
			);
		} else {
			return null;
		}
	};

	_GetSubItem = (e, item) => {
		//	console.log('Sub item', item);

		if (item.OutStock === e) {
			return null;
		}

		let data = lod.filter(this.global.SubItemInfo, c => c.MenuCode === e);
		//	console.log('sub Item Data', this.global.SubItemInfo, e);
		//	console.log('sub Item', data, e);
		if (data.length === 0) {
			let data = lod.filter(this.global.ItemAll, c => c.Code === e);
			let _ModiCheck = this._Action('GENERATEMODIFIERCHECK', data[0].Code);
			let _CondimentCheck = this._Action(
				'GENERATECONDIMENTCHECK',
				data[0].Code
			);

			this.setState({
				IsItemPopup: true,
				SelectedItemInfo: data,
				ModiCheck: _ModiCheck,
				CondimentCheck: _CondimentCheck,
				IsSubItem: false,
				SubItemCode: e,
				SubItemData: null,
				Qty: 0,
			});
		} else {
			this.setState({ IsSubItem: true, SubItemCode: e, SubItemData: data });
		}
	};

	_RenderItem = () => {
		let data = lod.filter(
			this.global.ItemInfoAll,
			c =>
				c.ClassType === this.state.SelectedClassGroupCode &&
				c.ClassCode === this.state.SelectedClassDetailsCode
		);

		//	console.log('View', this.global.ItemInfoAll);
		//	console.log('item-1', this.state.SelectedClassGroupCode);
		//	console.log('item-2', this.state.SelectedClassDetailsCode);
		//	console.log('DATA', data);

		if (data === null) {
			return null;
		}

		let _Imagestyle = { height: '200px', 'object-fit': 'contain' };
		let _Cardstyle = {
			'min-height': '40vh',
			height: 'fit-content',
			margin: '2px 1px',
			//	'box-shadow': '0 0px 5px rgba(1, 0, 0, 0.3)',
			margin: '2px 1px',
			'text-align': 'center',
		};
		let _Textstyle = { 'min-height': '45vh', height: 'fit-content' };
		let _Liststyle = {
			'padding-left': '5px',
			'padding-right': '5px',
			'padding-top': '5px',
		};
		let _size = 6;
		let _sm = 4;
		let _md = 2;
		let _lg = 4;
		let _xl = 4;

		return (
			<Ion.IonInfiniteScroll threshold="100px">
				<List
					style={_Liststyle}
					grid={{
						gutter: 16,
						xs: 2,
						sm: 3,
						md: 4,
						lg: 6,
						xl: 6,
						xxl: 3,
					}}
					dataSource={data}
					renderItem={item => (
						<List.Item>
							<Card
								style={_Cardstyle}
								cover={
									<img style={_Imagestyle} alt="example" src={item.ImageName} />
								}
								onClick={() => this._GetSubItem(item.Code, item)}
							>
								{this._OutofStock(item)}
								<div>
									<Ion.IonLabel className="ion-text-center">
										<Ion.IonText className="ion-text-wrap">
											<h6>{item.MenuCode}</h6>
											<h6>{item.MenuName}</h6>
											<h6 style={this.global.App.TextBold}>
												RM {ConvertFormat(item.PosPrice)}
											</h6>
										</Ion.IonText>
									</Ion.IonLabel>
								</div>
							</Card>
						</List.Item>
					)}
				/>
			</Ion.IonInfiniteScroll>
		);
	};

	_RenderItem_UnUsed = () => {
		let data = lod.filter(
			this.global.ItemInfoAll,
			c =>
				c.ClassType === this.state.SelectedClassGroupCode &&
				c.ClassCode === this.state.SelectedClassDetailsCode
		);

		//	console.log('State', this.state);
		//	console.log('Data State', data);
		//	console.log('global', this.global.ItemInfo);

		if (data === null) {
			return null;
		}

		let _Defxx = {
			margin: 'auto',
			display: 'block',
			position: 'absolute',
		};

		let _Defsss = {
			paddingTop: '50px',
			height: '100%',
			width: '100%',
			'object-fit': 'cover',
		};

		let _Def = {};

		let _DefDiv = {
			height: '60px',
			paddingTop: '10px',
		};

		let _size = 6;
		let _sm = 4;
		let _md = 2;
		let _lg = 4;
		let _xl = 4;

		return (
			<Ion.IonInfiniteScroll threshold="100px">
				<Ion.IonGrid>
					<Ion.IonRow>
						{data.map(item => (
							<Ion.IonCol
								size-lg={_lg}
								size-xl={_xl}
								size-md={_md}
								size-sm={_sm}
								size={_size}
							>
								<Ion.IonCard
									className="MyCard"
									//onClick={() =>
									//	this._Action('ITEMCLICK', item.Code, 0, item.OutStock)
									//}
									onClick={() => this._GetSubItem(item.Code, item)}
								>
									<div className="ImageContainer">
										<Ion.IonImg
											loading="eager"
											src={item.ImageName}
											alt=""
											//style={_Def}
											className="MyImage"
										/>
										{this._OutofStock(item)}
									</div>
								</Ion.IonCard>
								<div style={_DefDiv}>
									<Ion.IonLabel className="ion-text-center">
										<Ion.IonText className="ion-text-wrap">
											<h6>
												{item.MenuCode} - {item.MenuName}
											</h6>
											<h6 style={this.global.App.TextBold}>
												RM {ConvertFormat(item.PosPrice)}
											</h6>
										</Ion.IonText>
									</Ion.IonLabel>
								</div>
							</Ion.IonCol>
						))}
					</Ion.IonRow>
				</Ion.IonGrid>
			</Ion.IonInfiniteScroll>
		);
	};

	_RenderCartOrder = () => {
		let data = this.global.LiveData;

		if (data === null) {
			return null;
		}

		if (data.BillDetails.length === 0) {
			return null;
		}

		//	console.log('data', data);
		let _ShowLine = 'full';
		if (this.state.IsEdit === true) {
			_ShowLine = 'none';
		}

		const _onSelect = () => {
			let queryString = window.location.search.substring(1);
			const urlParams = new URLSearchParams(queryString);
			const _ServiceCode = urlParams.get('zServiceCode');

			if (_ServiceCode === 'O') {
				//some condition
				this.setState({ IsPaymentOption: true });
			} else {
				if (this.global.WebInfo.WithPayment === 1) {
					this.setState({ IsPaymentOption: true });
				} else {
					this.setState({ IsSaveConfirm: true });
				}
			}
		};

		return (
			<Ion.IonModal isOpen={this.state.IsConfirmPopup} backdropDismiss={false}>
				<Ion.IonHeader>
					<Ion.IonToolbar color={this.global.Color}>
						<Ion.IonButtons slot="start">
							<Ion.IonButton onClick={() => this._Action('RESETLINE')}>
								<Ion.IonIcon icon={chevronBack}></Ion.IonIcon>
							</Ion.IonButton>
						</Ion.IonButtons>
						<Ion.IonTitle>
							{this.global.Default.SelectedServiceInfo.Name}
						</Ion.IonTitle>
						<Ion.IonButtons slot="end">
							<Ion.IonButton
								onClick={() => this.setState({ IsEdit: !this.state.IsEdit })}
							>
								<Ion.IonText>
									{
										this.global.LanguagePack[this.global.LanguageNo]
											.ButtonEditCaption
									}
								</Ion.IonText>
							</Ion.IonButton>
						</Ion.IonButtons>
					</Ion.IonToolbar>
				</Ion.IonHeader>
				<Ion.IonContent>
					<Ion.IonList>
						{data.BillDetails.map((c, index) => (
							<Ion.IonItemGroup>
								<Ion.IonItem lines={_ShowLine}>
									<Ion.IonLabel style={this.global.App.TextNormal}>
										<span>
											{c.MenuCode} - {c.MenuName}
										</span>
										<span className="MyDisplayRight2">
											{c.Qty} x {ConvertFormat(c.UnitPrice)}
										</span>
										<br />
										<span></span>
										{this._Action('SHOWDISCOUNT', '', index)}
										<span className="MyDisplayRight2">
											{ConvertFormat(c.GrossAmt)}
										</span>
										{this._Action('SHOWREMARK', '', index)}
										{this._Action('SHOWCOMBOSET', '', index)}
										{this._Action('SHOWCONDIMENTDETAILS', '', index)}
										{this._Action('SHOWMODIFIERDETAILS', '', index)}
									</Ion.IonLabel>
								</Ion.IonItem>
								{this._RenderEditLine(index)}
							</Ion.IonItemGroup>
						))}
					</Ion.IonList>
					{this._RenderTotal()}
				</Ion.IonContent>
				<Ion.IonFooter>
					<Ion.IonButton
						color={this.global.Color}
						expand="full"
						size="large"
						//onClick={() => this.setState({ IsSaveConfirm: true })}
						//   onClick={() => this.setState({ IsPaymentOption:true })}
						//onClick={() =>   this._Action("PAYMENTOPTION")}
						onClick={_onSelect}
					>
						{this._Action('ALERTBUTTON')}
					</Ion.IonButton>
				</Ion.IonFooter>
			</Ion.IonModal>
		);
	};

	_RenderEditLine = x => {
		let z = x;

		if (this.state.IsEdit === false) {
			return null;
		}

		const _Remarkclick = z => {
			let _row = z;

			console.log(this.formRef2);

			let _Code1 = '';

			//	state.value

			console.log('_Code1', _Code1);
			console.log('Row', _row);
			this.setState({ IsEdit: false, Remark: '' });

			lod.set(this.global.LiveData.BillDetails[_row], 'Remark', _Code1);
		};

		return (
			<Ion.IonItem>
				<Ion.IonGrid>
					<Ion.IonRow>
						<Ion.IonCol>
							<Ion.IonButton
								fill="none"
								onClick={() => this._Action('REMARK', '', z)}
							>
								<Ion.IonIcon
									color={this.global.Color}
									icon={reader}
								></Ion.IonIcon>
							</Ion.IonButton>
							<Ion.IonButton
								fill="none"
								onClick={() => this._Action('EDITLINE', 'REMOVE', z)}
							>
								<Ion.IonIcon color="danger" icon={trashBin}></Ion.IonIcon>
							</Ion.IonButton>
						</Ion.IonCol>
						<Ion.IonCol size="3">
							<Ion.IonButton
								onClick={() => this._Action('EDITLINE', 'MINUS', z)}
								color={this.global.Color}
								shape="round"
								fill="outline"
								expand="full"
							>
								-
							</Ion.IonButton>
						</Ion.IonCol>
						<Ion.IonCol size="3">
							<Ion.IonButton
								onClick={() => this._Action('EDITLINE', 'ADD', z)}
								color={this.global.Color}
								shape="round"
								fill="outline"
								expand="full"
							>
								+
							</Ion.IonButton>
						</Ion.IonCol>
					</Ion.IonRow>

					{/* 	<Ion.IonRow>
						 <Input
							ref={this.formRef2}
							placeholder={
								this.global.LanguagePack[this.global.LanguageNo]
									.LabelSpecialRequest
							}
						/> 

						<Ion.IonTextarea
							ref={this.formRef2}
							//onIonChange={e => this.setState({ Remark: e.detail.value })}
							//value={this.state.Remark}
							placeholder={
								this.global.LanguagePack[this.global.LanguageNo]
									.LabelSpecialRequest
							}
						/>

						<Ion.IonCol size="3">
							<Ion.IonButton
								onClick={() => _Remarkclick(z)}
								color={this.global.Color}
								shape="round"
								fill="outline"
								expand="full"
							>
								Save
							</Ion.IonButton>
						</Ion.IonCol>
						<Ion.IonCol size="3">
							<Ion.IonButton
								onClick={() => this.setState({ IsEdit: false })}
								color={this.global.Color}
								shape="round"
								fill="outline"
								expand="full"
							>
								Cancel
							</Ion.IonButton>
						</Ion.IonCol>
					</Ion.IonRow> */}
				</Ion.IonGrid>
			</Ion.IonItem>
		);
	};

	_RenderDeliveryTotal = () => {
		let _Gb = this.global.LiveData.Bills;
		let _DelAmt = _Gb.DelAmt;
		if (_Gb.DelAmt === undefined || _Gb.DelAmt === null) {
			_DelAmt = 0;
		}

		if (_DelAmt === 0) {
			return (
				<div>
					<Ion.IonItem lines="none">
						<Ion.IonText
							style={this.global.App.TextBold}
							color="danger"
							slot="start"
						>
							{this.global.LanguagePack[this.global.LanguageNo].LabelDue}
						</Ion.IonText>
						<Ion.IonText
							style={this.global.App.TextBold}
							color="danger"
							slot="end"
						>
							RM {ConvertFormat(_Gb.Rounded)}
						</Ion.IonText>
					</Ion.IonItem>
				</div>
			);
		}

		return (
			<div>
				<Ion.IonItem lines="none">
					<Ion.IonLabel style={this.global.App.TextGrayNormal}>
						<span>
							{this.global.LanguagePack[this.global.LanguageNo].LabelTotal}
						</span>
						<span className="MyDisplayRight2">
							{ConvertFormat(_Gb.Rounded)}
						</span>
						<span>
							<br />
							{
								this.global.LanguagePack[this.global.LanguageNo]
									.LabelDeliveryCharges
							}
						</span>
						<span className="MyDisplayRight2">{ConvertFormat(_DelAmt)}</span>
					</Ion.IonLabel>
				</Ion.IonItem>
				<Ion.IonItem lines="none">
					<Ion.IonText
						style={this.global.App.TextBold}
						color="danger"
						slot="start"
					>
						{this.global.LanguagePack[this.global.LanguageNo].LabelDue}
					</Ion.IonText>
					<Ion.IonText
						style={this.global.App.TextBold}
						color="danger"
						slot="end"
					>
						RM {ConvertFormat(_Gb.TotalAmt)}
					</Ion.IonText>
				</Ion.IonItem>
			</div>
		);
	};

	_RenderTotal = () => {
		let _Gb = this.global.LiveData.Bills;

		return (
			<Ion.IonList>
				<Ion.IonItem lines="full">
					<Ion.IonText style={this.global.App.TextBoldGrayNormal} slot="start">
						{this.global.LanguagePack[this.global.LanguageNo].LabelSubtotal}
					</Ion.IonText>
					<Ion.IonText style={this.global.App.TextBoldGrayNormal} slot="end">
						{ConvertFormat(_Gb.GrossAmt)}
					</Ion.IonText>
				</Ion.IonItem>
				<Ion.IonItem lines="full">
					<Ion.IonLabel style={this.global.App.TextGrayNormal}>
						<span>
							{this.global.LanguagePack[this.global.LanguageNo].LabelGross}
						</span>
						<span className="MyDisplayRight2">
							{ConvertFormat(_Gb.GrossAmt)}
						</span>
						<br />
						<span>
							{this.global.LanguagePack[this.global.LanguageNo].LabelSvc}
						</span>
						<span className="MyDisplayRight2">{ConvertFormat(_Gb.SvcAmt)}</span>
						<br />
						<span>
							{this.global.LanguagePack[this.global.LanguageNo].LabelTax}
						</span>
						<span className="MyDisplayRight2">{ConvertFormat(_Gb.TaxAmt)}</span>
						<br />
						<span>
							{this.global.LanguagePack[this.global.LanguageNo].LabelAdj}
						</span>
						<span className="MyDisplayRight2">{ConvertFormat(_Gb.Adj)}</span>
						<br />
					</Ion.IonLabel>
				</Ion.IonItem>
				{this._RenderDeliveryTotal()}
			</Ion.IonList>
		);
	};

	_Alert = () => {
		return (
			<Ion.IonAlert
				isOpen={this.state.IsAlert}
				onDidDismiss={() => this.setState({ IsAlert: false, IsBack: false })}
				header={this.global.LanguagePack[this.global.LanguageNo].AlertTitle}
				message={
					this.global.LanguagePack[this.global.LanguageNo].AlertConfirmBack
				}
				buttons={[
					{
						text: this.global.LanguagePack[this.global.LanguageNo]
							.ButtonCancelCaption,
						role: 'cancel',
						cssClass: 'secondary',
						handler: blah => () =>
							this.setState({ IsBack: false, IsAlert: false }),
					},
					{
						text: this._Action('ALERTBUTTON'),
						handler: () => this._Action('CONFIRMEXIT'),
					},
				]}
			/>
		);
	};

	_AlertSaveConfirm = () => {
		return (
			<Ion.IonAlert
				isOpen={this.state.IsSaveConfirm}
				onDidDismiss={() => this.setState({ IsSaveConfirm: false })}
				message={this._Action('ALERTTEXT')}
				header={
					this.global.LanguagePack[this.global.LanguageNo].AlertQuestionTitle
				}
				buttons={[
					{
						text: this.global.LanguagePack[this.global.LanguageNo]
							.ButtonCancelCaption,
						role: 'cancel',
						cssClass: 'secondary',
						handler: blah => () => this.setState({ IsSaveConfirm: false }),
					},
					{
						text: this.global.LanguagePack[this.global.LanguageNo]
							.ButtonComfirmCaption,
						handler: () => this._Action('SAVEOPTION'),
					},
				]}
			/>
		);
	};

	_RenderError = () => {
		const _Style = { 'text-align': 'center' };
		const { Paragraph, Text } = Typography;

		return (
			<Modal
				centered
				visible={this.state.IsError}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
				footer={false}
				closable={false}
			>
				<Res
					status="warning"
					title={this.global.LanguagePack[this.global.LanguageNo].AlertTitle}
					subTitle={
						this.global.LanguagePack[this.global.LanguageNo]
							.AlertFailSubmitRetry
					}
					extra={[
						<Space>
							<Button
								type="primary"
								key="Retry"
								size="large"
								block
								onClick={() => this._SaveData()}
							>
								Retry
							</Button>
							<Button
								key="Back"
								size="large"
								block
								onClick={() =>
									this.setState({ IsError: false, IsLoading: false })
								}
							>
								Back
							</Button>
						</Space>,
					]}
				/>
				<div className="desc">
					<Paragraph>
						<Text strong style={{ color: 'red', fontSize: 16 }}>
							Message
						</Text>
					</Paragraph>
					<Paragraph>{this.state.ErrorMessage}</Paragraph>
				</div>
			</Modal>
		);
	};

	_RenderLoading = () => {
		const _Style = { textAlign: 'center' };
		return (
			<Modal
				centered
				visible={this.state.IsLoading}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
				footer={false}
				closable={false}
				width={200}
			>
				<div style={_Style}>
					<Spin
						tip={this.global.LanguagePack[this.global.LanguageNo].AlertSubmit}
					/>
				</div>
			</Modal>
		);
	};

	_Render_PaymentOption = () => {
		//	console.log('State', this.state);

		return (
			<Ion.IonModal isOpen={this.state.IsPaymentOption} backdropDismiss={false}>
				<Ion.IonHeader>
					<Ion.IonToolbar color={this.global.Color}>
						<Ion.IonButtons slot="start">
							<Ion.IonButton
								onClick={() => this.setState({ IsPaymentOption: false })}
							>
								<Ion.IonIcon icon={chevronBack}></Ion.IonIcon>
							</Ion.IonButton>
						</Ion.IonButtons>
					</Ion.IonToolbar>
				</Ion.IonHeader>
				<Ion.IonContent>
					<Ion.IonList>
						<Ion.IonListHeader>
							<Ion.IonText>Select Payment Option</Ion.IonText>
						</Ion.IonListHeader>
						<br />
						<Ion.IonItem lines="none">
							<Ion.IonLabel>
								<Ion.IonButton
									color={this.global.Color}
									size="large"
									expand="full"
									onClick={() =>
										this.setState({
											IsPayNow: true,
											IsPayLater: false,
											IsPaymentOption: false,
											IsSave: true,
											IsSaveConfirm: false,
										})
									}
								>
									Pay Now
								</Ion.IonButton>
							</Ion.IonLabel>
						</Ion.IonItem>

						<Ion.IonItem lines="none">
							<Ion.IonLabel>
								<Ion.IonButton
									disabled={this.global.WebInfo.PayLaterButton}
									color={this.global.Color}
									size="large"
									expand="full"
									onClick={() =>
										this.setState({
											IsPayNow: false,
											IsPayLater: true,
											IsPaymentOption: false,
											IsSave: true,
											IsSaveConfirm: true,
										})
									}
								>
									Pay Later
								</Ion.IonButton>
							</Ion.IonLabel>
						</Ion.IonItem>
					</Ion.IonList>
				</Ion.IonContent>
			</Ion.IonModal>
		);
	};

	_StoreWebInfo = () => {
		let _Gb = this.global.WebInfo;

		if (this.global.Default.SelectedServiceCode != 'E') {
			lod.set(_Gb, 'OutletCode', this.global.OutletInfo.OutletCode);
			lod.set(_Gb, 'OutletName', this.global.OutletInfo.OutletName);
			//	lod.set(_Gb, 'MobileNo', '60121234567');
			lod.set(_Gb, 'ServiceCode', this.global.Default.SelectedServiceCode);
			lod.set(_Gb, 'FromAddress', '');
			lod.set(_Gb, 'ToAddress', '');
			//	lod.set(_Gb, 'CustomerName', 'Testing');
		}
	};

	_UpSellPopSelection = () => {
		let _Data = this.state.UpSellCondition;
		if (_Data === null || _Data === null) {
			return null;
		}

		return (
			<Ion.IonModal isOpen={this.state.IsUpSellPopup} backdropDismiss={false}>
				<Ion.IonHeader>
					<Ion.IonToolbar>
						<Ion.IonButtons slot="start">
							<Ion.IonButton
								onClick={() => this.setState({ IsUpSellPopup: false })}
							>
								<Ion.IonIcon icon={chevronBack}></Ion.IonIcon>
							</Ion.IonButton>
						</Ion.IonButtons>
						<Ion.IonTitle>Up Sell Selection</Ion.IonTitle>
						<Ion.IonButtons slot="end">
							<Ion.IonButton onClick={() => this._Action('CART')}>
								<Ion.IonIcon icon={closeCircle}></Ion.IonIcon>
							</Ion.IonButton>
						</Ion.IonButtons>
					</Ion.IonToolbar>
				</Ion.IonHeader>

				<Ion.IonContent fullscreen>
					<Ion.IonList>
						{_Data.map((c, index) => (
							<Ion.IonItem>
								<Ion.IonAvatar slot="start">
									<Ion.IonImg loading="eager" src={c.ImageName} alt="" />
								</Ion.IonAvatar>
								<Ion.IonLabel>
									<h3>
										[{c.MenuCode}] - {c.MenuName}
									</h3>
									<p>
										<h6 style={this.global.App.TextBold}>{c.DiscountRemark}</h6>
									</p>
								</Ion.IonLabel>
								<Ion.IonButton
									onClick={() =>
										this._Action('UPSELLITEMCLICK', c.MenuCode, c, c.OutStock)
									}
								>
									Select
								</Ion.IonButton>
							</Ion.IonItem>
						))}
					</Ion.IonList>
				</Ion.IonContent>
			</Ion.IonModal>
		);
	};

	_RenderSubItem = () => {
		let data = this.state.SubItemData;

		//	console.log('Selected Item', this.state);
		//	console.log('data', data);

		if (data === null || data === null || data.length === 0) {
			return null;
		}

		let _Def = {
			paddingTop: '0px',
			height: '180px',
			width: '100%',
		};

		let _DefDiv = {
			height: '60px',
			paddingTop: '10px',
		};

		let _Selected = lod.filter(
			this.global.ItemAll,
			c => c.Code === this.state.SubItemCode
		);

		let _size = 6;
		let _sm = 4;
		let _md = 2;
		let _lg = 4;
		let _xl = 4;

		return (
			<Ion.IonModal isOpen={this.state.IsSubItem} backdropDismiss={false}>
				<Ion.IonHeader>
					<Ion.IonToolbar>
						<Ion.IonButtons slot="start">
							<Ion.IonButton
								onClick={() => this.setState({ IsSubItem: false })}
							>
								<Ion.IonIcon icon={chevronBack}></Ion.IonIcon>
							</Ion.IonButton>
						</Ion.IonButtons>
						<Ion.IonTitle>{_Selected[0].Name}</Ion.IonTitle>
						<Ion.IonButtons slot="end">
							<Ion.IonButton onClick={() => this._Action('CART')}>
								<Ion.IonIcon icon={closeCircle}></Ion.IonIcon>
							</Ion.IonButton>
						</Ion.IonButtons>
					</Ion.IonToolbar>
				</Ion.IonHeader>
				<Ion.IonContent>
					<Ion.IonInfiniteScroll threshold="100px">
						<Ion.IonGrid>
							<Ion.IonRow>
								{data.map(item => (
									<Ion.IonCol
										size-lg={_lg}
										size-xl={_xl}
										size-md={_md}
										size-sm={_sm}
										size={_size}
									>
										<Ion.IonCard
											className="MyCard"
											onClick={() =>
												this._Action('ITEMCLICK', item.Code, 0, item.OutStock)
											}
										>
											<div className="ImageContainer">
												<Ion.IonImg
													loading="eager"
													src={item.ImageName}
													alt=""
													style={_Def}
													className="MyImage"
												/>
												{this._OutofStock(item)}
											</div>
										</Ion.IonCard>
										<div style={_DefDiv}>
											<Ion.IonLabel className="ion-text-center">
												<Ion.IonText className="ion-text-wrap">
													<h6>
														{item.Code} - {item.MenuName}
													</h6>
													<h6 style={this.global.App.TextBold}>
														RM {ConvertFormat(item.PosPrice)}
													</h6>
												</Ion.IonText>
											</Ion.IonLabel>
										</div>
									</Ion.IonCol>
								))}
							</Ion.IonRow>
						</Ion.IonGrid>
					</Ion.IonInfiniteScroll>
				</Ion.IonContent>
			</Ion.IonModal>
		);
	};

	_ShowURL = () => {
		let queryString = window.location.search.substring(1);
		const urlParams = new URLSearchParams(queryString);
		const _ProfileCode = urlParams.get('zProfileCode');
		const _ServiceCode = urlParams.get('zServiceCode');
		const _TableCode = urlParams.get('zTableCode');
		const _Token = urlParams.get('Token');
	};

	_ValidateObj = zIsCondiment => {
		let _Det = this.state.CondimentCheck;
		let _RefOutlet = this.global.OutletInfo.RefOutlet;
		let _Force = _RefOutlet.substr(14, 1);
		let _DescName = 'Condiment';

		switch (zIsCondiment) {
			case 1:
				_Det = this.state.CondimentCheck;
				//[F15] Force to Condiment
				_Force = _RefOutlet.substr(14, 1);
				_DescName = 'condiment';
				break;

			default:
				_Det = this.state.ModiCheck;
				//[F14] Force to Modifier
				_Force = _RefOutlet.substr(13, 1);
				_DescName = 'Modifier';
				break;
		}

		//	console.log('this.state.Condimentcheck', _Det);

		let _ModData = lod.filter(
			this.global.ModifierDetailsInfo,
			z => z.IsCheck === true
		);
		//	console.log('ValidateObj---->', _ModData)

		let count = 0;
		let _Name = '';
		let _Qty = 0;
		let _Mess = '';
		let _Error = 0;

		for (count = 0; count < _Det.length; count++) {
			if (_Force !== 'O') {
				if (_Det[count].ControlQty !== 0) {
					if (_Det[count].Qty > _Det[count].ControlQty) {
						_Mess =
							'You have reached the maximum limit of  ' +
							_Det[count].ControlQty +
							_DescName +
							' on [' +
							_Det[count].Name +
							']';
						swal('Alert', _Mess);
						_Error = _Error + 1;
						break;
					}

					_Name = _Name + _Det[count].Desc + ',';
					_Qty = _Qty + 1;
				} else {
					if (_Det[count].Desc != '') {
						_Name = _Name + _Det[count].Desc + ',';
						_Qty = _Qty + 1;
					}
				}
			} else {
				if (_Det[count].ControlQty !== 0) {
					if (_Det[count].Qty > _Det[count].ControlQty) {
						_Mess =
							'You have reached the maximum limit of  ' +
							_Det[count].ControlQty +
							_DescName +
							' on [' +
							_Det[count].Name +
							']';
						swal('Alert', _Mess);
						_Error = _Error + 1;
						break;
					}

					_Name = _Name + _Det[count].Desc + ',';
					_Qty = _Qty + 1;
				}
			}
		}

		//	console.log('Error Code', _Error);
		//	console.log('_Name', _Name);

		if (_Error == 0) {
			if (_Name !== '') {
				_Name = _Name.slice(0, -1);
			}

			if (zIsCondiment === 1) {
				this.setState({ CondimentValue: _Name, ShowCondiment: false });
			}
			if (zIsCondiment === 0) {
				this.setState({
					ModifierValue: _Name,
					Remark: _Name,
					ShowModifier: false,
				});
			}
		}

		let _ModData2 = lod.filter(
			this.global.ModifierDetailsInfo,
			z => z.IsCheck === true
		);
		//	console.log('After ValidateObj Save---->', _ModData2)
	};

	_ValidateSubmitObj = zIsCondiment => {
		let _Det = this.state.CondimentCheck;
		let _RefOutlet = this.global.OutletInfo.RefOutlet;
		let _Force = _RefOutlet.substr(14, 1);
		let _DescName = 'Condiment';
		let _Txt = '';

		//	console.log('ValidateSubmitObj - zIsCondiment', zIsCondiment)
		switch (zIsCondiment) {
			case 1:
				_Det = this.state.CondimentCheck;
				//[F15] Force to Condiment
				_Force = _RefOutlet.substr(14, 1);
				_DescName = 'Choice';
				break;

			default:
				_Det = this.state.ModiCheck;
				//[F14] Force to Modifier
				_Force = _RefOutlet.substr(13, 1);
				_DescName = 'Add-On';
				break;
		}

		//	console.log('ValidateSubmitObj - _Force', _Force)

		if (_Force === 'O') {
			if (_Det.length !== 0) {
				// if ControlQty <> 0
				let _ControlData = lod.filter(
					_Det,
					c => c.Qty === 0 && c.ControlQty != 0
				);
				if (_ControlData.length !== 0) {
					for (let i = 0; i < _ControlData.length; i++) {
						_Txt += _ControlData[i].Name + ',';
					}
					swal(
						'Alert',
						'Please select at least one ' +
							_DescName +
							" for category '" +
							_Txt +
							"' !!!"
					);

					return false;
				} else {
					return true;
				}
			}
		}
	};

	render() {
		//	console.log('Global', this.global.LiveData.BillDetails);
		//console.log('State', this.state);

		/*    if (this.state.IsCheckOut === true) {
	  return <CheckOut />;
	} */

		/*   if (this.state.IsPayNow === true) {
	  return <CheckOut />;
	} */

		if (this.state.IsBack === true || this.state.IsConfirmExit === true) {
			return <Main />;
		}

		let _PayStatus = 'NOW';

		if (this.state.IsSave === true) {
			//if (this.state.IsPayLater === true) {
			let _SaveType = '';
			//console.log("My global", this.global.Default.AppMode);

			if (this.global.Default.AppMode === 'O') {
				_SaveType = 'BEFORE';
			} else {
				if (this.state.IsPayLater === true) {
					_PayStatus = 'LATER';
				}

				if (this.state.IsPayNow === true) {
					_PayStatus = 'NOW';
					_SaveType = 'BEFORE';
				}

				this._StoreWebInfo();
			}

			return <SaveData SaveType={_SaveType} IsPayStatus={_PayStatus} />;
		}

		//	console.log('first', this.global.WebInfo);

		return (
			<Ion.IonApp>
				{this._RenderHeader()}
				<Ion.IonContent>
					{this._RenderItem()}
					{this._RenderViewItem()}
					{this._RenderSubItem()}
					{this._RenderSearch()}
					{this._RenderCartOrder()}
					{this._UpSellPopSelection()}
					{this._Alert()}
					{this._Render_PaymentOption()}
					{this._AlertSaveConfirm()}
					{/* {this._RenderOpenRemark()} */}
					{this._RenderOpenRemark2()}
					{this._RenderCondimentScreen()}
					{this._RenderCondimentDrawer()}
					{this._RenderModifierScreen()}
					{this._RenderModifierDrawer()}
					{/* 	{this._RenderModifierOpenRemark()} */}
					{this._RenderLoading()}
					{this._RenderError()}
				</Ion.IonContent>
			</Ion.IonApp>
		);
	}
}

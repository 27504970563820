import React, { Component } from 'react';
import { CallPostApi } from '../functions/General';
import * as Antd from 'antd';
import * as Ion from '@ionic/react';
import * as lod from 'lodash';

import 'antd/dist/antd.css';

export default class Test2 extends Component {
	constructor(props) {
		super(props);

		this.state = {
			Qty: 0,
		};

		//	console.log('This global', this.global);
	}

	_Test = async () => {
		let _Value2 = {
			Amount: '2.50',
			CustomerName: 'Ali Baba Testing',
			MobileNo: '0125556666',
			ProfileCode: 'VS004-01',
		};
		let _Value = { Type: 'GETMOL', QueryType: 'GETMOL', Value: _Value2 };

		//console.log("object", _Value);

		let _Result = await CallPostApi(_Value);
		console.log('Return', _Result);

		window.open(_Result.Url, '_self');

		/*  openWindow(_Result.Url, {
	  height: window.innerHeight,
	  name: 'My pop-up',
	  width: window.innerWidth,
	}); */
	};

	_Test5 = () => {
		const _Item = [
			{ Name: 'Phang Yik foo' },
			{ Name: 'Wong Yik Chong' },
			{ Name: 'Tan Kok Wai' },
			{ Name: 'Lee kok Hoong' },
			{ Name: 'Ooi Boon Leong' },
		];

		let _Search = '1';
		let _New = _Item.filter(o => o.Name.includes(_Search));
		console.log('first', _New);
		return _New;
		//return _New;
	};

	_LoopData = () => {
		const onChange = key => {
			console.log(key);
		};

		const items = [
			{
				key: '1',
				label: `Tab 1`,
				children: `Content of Tab Pane 1`,
			},
			{
				key: '2',
				label: `Tab 2`,
				children: `Content of Tab Pane 2`,
			},
			{
				key: '3',
				label: `Tab 3`,
				children: `Content of Tab Pane 3`,
			},
		];

		return (
			<div>
				Hello
				<Ion.IonSegment scrollable>
					{items.map(s => (
						<Ion.IonSegmentButton>
							<Ion.IonButton>{s.label}</Ion.IonButton>
						</Ion.IonSegmentButton>
					))}
				</Ion.IonSegment>
			</div>
		);
	};

	_SumQty = x => {
		console.log(x.detail.checked);

		let _Qty = 0;

		if (x.detail.checked === true) {
			_Qty = 1 + this.state.Qty;
		} else {
			_Qty = this.state.Qty - 1;
		}

		this.setState({ Qty: _Qty });
		console.log('New Qty', this.state.Qty);
	};

	_TestData = () => {
		let _Data = [
			{
				Id: 21,
				IsCheck: 0,
				Desc: '',
				Code: 'B12',
				Name: 'Mixed Juice',
				ControlQty: 1,
				CondCode: 'D',
				CondName: 'Fruit Juice',
				DefName: 'Fruit Juice',
				ButtonIndex: 0,
				Name1: '测试版本',
				Name2: 'テスト版',
				Name3: null,
			},
			{
				Id: 16,
				IsCheck: 0,
				Desc: '',
				Code: 'F004',
				Name: null,
				ControlQty: 1,
				CondCode: 'A',
				CondName: 'Choice of Soup',
				DefName: 'Choice of Soup',
				ButtonIndex: 1,
				Name1: '测试版本',
				Name2: 'テスト版',
				Name3: null,
			},
			{
				Id: 17,
				IsCheck: 0,
				Desc: '',
				Code: 'A001',
				Name: null,
				ControlQty: 1,
				CondCode: 'A',
				CondName: 'Choice of Soup',
				DefName: 'Choice of Soup',
				ButtonIndex: 1,
				Name1: '测试版本',
				Name2: 'テスト版',
				Name3: null,
			},
			{
				Id: 18,
				IsCheck: 0,
				Desc: '',
				Code: 'F14',
				Name: 'Tuna Mayonnaise Sandwich',
				ControlQty: 1,
				CondCode: 'A',
				CondName: 'Choice of Soup',
				DefName: 'Choice of Soup',
				ButtonIndex: 1,
				Name1: '测试版本',
				Name2: 'テスト版',
				Name3: null,
			},
			{
				Id: 19,
				IsCheck: 0,
				Desc: '',
				Code: 'F14',
				Name: 'Tuna Mayonnaise Sandwich',
				ControlQty: 1,
				CondCode: 'B',
				CondName: 'Flavour',
				DefName: 'Flavour',
				ButtonIndex: 1,
				Name1: '测试版本',
				Name2: 'テスト版',
				Name3: null,
			},
			{
				Id: 20,
				IsCheck: 0,
				Desc: '',
				Code: 'F14',
				Name: 'Tuna Mayonnaise Sandwich',
				ControlQty: 1,
				CondCode: 'C',
				CondName: 'Sugar Level',
				DefName: 'Sugar Level',
				ButtonIndex: 1,
				Name1: '????',
				Name2: '????',
				Name3: '',
			},
			{
				Id: 22,
				IsCheck: 0,
				Desc: '',
				Code: 'B51',
				Name: 'Tiger Tower',
				ControlQty: 1,
				CondCode: 'C',
				CondName: 'Sugar Level',
				DefName: 'Sugar Level',
				ButtonIndex: 1,
				Name1: '????',
				Name2: '????',
				Name3: '',
			},
			{
				Id: 23,
				IsCheck: 0,
				Desc: '',
				Code: 'B87',
				Name: 'Pearl Milk Tea(R)',
				ControlQty: 1,
				CondCode: 'C',
				CondName: 'Sugar Level',
				DefName: 'Sugar Level',
				ButtonIndex: 1,
				Name1: '????',
				Name2: '????',
				Name3: '',
			},
			{
				Id: 24,
				IsCheck: 0,
				Desc: '',
				Code: 'B88',
				Name: 'Pearl Milk Tea(L)',
				ControlQty: 1,
				CondCode: 'C',
				CondName: 'Sugar Level',
				DefName: 'Sugar Level',
				ButtonIndex: 1,
				Name1: '????',
				Name2: '????',
				Name3: '',
			},
			{
				Id: 25,
				IsCheck: 0,
				Desc: '',
				Code: 'B89',
				Name: 'Matcha Milk Tea (R)',
				ControlQty: 1,
				CondCode: 'C',
				CondName: 'Sugar Level',
				DefName: 'Sugar Level',
				ButtonIndex: 1,
				Name1: '????',
				Name2: '????',
				Name3: '',
			},
			{
				Id: 26,
				IsCheck: 0,
				Desc: '',
				Code: 'B90',
				Name: 'Matcha Milk Tea (L)',
				ControlQty: 1,
				CondCode: 'C',
				CondName: 'Sugar Level',
				DefName: 'Sugar Level',
				ButtonIndex: 1,
				Name1: '????',
				Name2: '????',
				Name3: '',
			},
			{
				Id: 27,
				IsCheck: 0,
				Desc: '',
				Code: 'OT18',
				Name: 'Force Condiment',
				ControlQty: 1,
				CondCode: 'B',
				CondName: 'Flavour',
				DefName: 'Flavour',
				ButtonIndex: 1,
				Name1: '测试版本',
				Name2: 'テスト版',
				Name3: null,
			},
			{
				Id: 35,
				IsCheck: 0,
				Desc: '',
				Code: 'S09',
				Name: 'Chinese New Year Set',
				ControlQty: 1,
				CondCode: 'A',
				CondName: 'Choice of Soup',
				DefName: 'Choice of Soup',
				ButtonIndex: 1,
				Name1: '测试版本',
				Name2: 'テスト版',
				Name3: null,
			},
			{
				Id: 39,
				IsCheck: 0,
				Desc: '',
				Code: 'F01',
				Name: 'Caesar Salad',
				ControlQty: 1,
				CondCode: 'B',
				CondName: 'Flavour',
				DefName: 'Flavour',
				ButtonIndex: 1,
				Name1: '测试版本',
				Name2: 'テスト版',
				Name3: null,
			},
			{
				Id: 46,
				IsCheck: 0,
				Desc: '',
				Code: 'F120',
				Name: 'EGG SALAD',
				ControlQty: 1,
				CondCode: 'K',
				CondName: 'KITCHEN',
				DefName: 'KITCHEN',
				ButtonIndex: 1,
				Name1: '',
				Name2: '',
				Name3: '',
			},
			{
				Id: 36,
				IsCheck: 0,
				Desc: '',
				Code: 'S09',
				Name: 'Chinese New Year Set',
				ControlQty: 1,
				CondCode: 'B',
				CondName: 'Flavour',
				DefName: 'Flavour',
				ButtonIndex: 2,
				Name1: '测试版本',
				Name2: 'テスト版',
				Name3: null,
			},
			{
				Id: 40,
				IsCheck: 0,
				Desc: '',
				Code: 'F01',
				Name: 'Caesar Salad',
				ControlQty: 1,
				CondCode: 'BEV',
				CondName: 'Status',
				DefName: 'Status',
				ButtonIndex: 2,
				Name1: 'Status',
				Name2: 'Status',
				Name3: 'Status',
			},
			{
				Id: 37,
				IsCheck: 0,
				Desc: '',
				Code: 'S09',
				Name: 'Chinese New Year Set',
				ControlQty: 1,
				CondCode: 'BEV',
				CondName: 'Status',
				DefName: 'Status',
				ButtonIndex: 3,
				Name1: 'Status',
				Name2: 'Status',
				Name3: 'Status',
			},
			{
				Id: 41,
				IsCheck: 0,
				Desc: '',
				Code: 'F01',
				Name: 'Caesar Salad',
				ControlQty: 1,
				CondCode: 'BEV1',
				CondName: 'Ice Level',
				DefName: 'Ice Level',
				ButtonIndex: 3,
				Name1: 'Ice Level',
				Name2: 'Ice Level',
				Name3: 'Ice Level',
			},
			{
				Id: 38,
				IsCheck: 0,
				Desc: '',
				Code: 'S09',
				Name: 'Chinese New Year Set',
				ControlQty: 1,
				CondCode: 'BEV1',
				CondName: 'Ice Level',
				DefName: 'Ice Level',
				ButtonIndex: 4,
				Name1: 'Ice Level',
				Name2: 'Ice Level',
				Name3: 'Ice Level',
			},
			{
				Id: 42,
				IsCheck: 0,
				Desc: '',
				Code: 'F01',
				Name: 'Caesar Salad',
				ControlQty: 1,
				CondCode: 'BEV2',
				CondName: 'Milk Level',
				DefName: 'Milk Level',
				ButtonIndex: 4,
				Name1: 'Milk Level',
				Name2: 'Milk Level',
				Name3: 'Milk Level',
			},
			{
				Id: 43,
				IsCheck: 0,
				Desc: '',
				Code: 'F01',
				Name: 'Caesar Salad',
				ControlQty: 1,
				CondCode: 'C',
				CondName: 'Sugar Level',
				DefName: 'Sugar Level',
				ButtonIndex: 5,
				Name1: '????',
				Name2: '????',
				Name3: '',
			},
			{
				Id: 44,
				IsCheck: 0,
				Desc: '',
				Code: 'F01',
				Name: 'Caesar Salad',
				ControlQty: 1,
				CondCode: 'D',
				CondName: 'Fruit Juice',
				DefName: 'Fruit Juice',
				ButtonIndex: 6,
				Name1: '测试版本',
				Name2: 'テスト版',
				Name3: null,
			},
			{
				Id: 45,
				IsCheck: 0,
				Desc: '',
				Code: 'F01',
				Name: 'Caesar Salad',
				ControlQty: 1,
				CondCode: 'K',
				CondName: 'KITCHEN',
				DefName: 'KITCHEN',
				ButtonIndex: 7,
				Name1: '',
				Name2: '',
				Name3: '',
			},
		];

		console.log('data', _Data);

		//	let _Id5 = 35
		//	let _Index12 = _Data.findIndex(
		//		obj => obj.Id === _Id5

		//	);
		//	console.log('Found CondimentID = ', _Index12);

		let data = lod.filter(_Data, c => c.Code === 'F14');

		return data.map((c, index) => (
			<div>
				<Ion.IonItem lines="full">
					<Ion.IonText slot="end">{c.CondName}</Ion.IonText>

					<Ion.IonCheckbox
						slot="start"
						onIonChange={x => this._SumQty(x)}
					></Ion.IonCheckbox>
					<br />
				</Ion.IonItem>
			</div>
		));
	};

	_RenderPaymentMode = () => {
		let _PayMode = [
			{
				Code: 'C',
				Name: 'Credit Card',
				ImageName: 'https://staging.vpos.com.my/NewWebOrder/Cc.png',
			},
			{
				Code: 'W',
				Name: 'E-Wallet',
				ImageName: 'https://staging.vpos.com.my/NewWebOrder/Tng.png',
			},
		];

		return (
			<Antd.Drawer
				title="Select Payment Mode"
				visible={true}
				placement="bottom"
				key={'bottom'}
			>
				<Antd.List
					itemLayout="horizontal"
					dataSource={_PayMode}
					renderItem={item => (
						<Antd.List.Item>
							<Antd.List.Item.Meta
								avatar={
									<Antd.Avatar shape="square" size="large" src={item.ImageName}>
										{/* <CreditCardTwoTone /> */}
									</Antd.Avatar>
								}
								title={item.Name}
							/>
						</Antd.List.Item>
					)}
				></Antd.List>
			</Antd.Drawer>
		);
	};

	componentDidMount() {
		/* 
	console.log('width',window.innerWidth)
	console.log('height',window.innerHeight) */
	}

	render() {
		return <div>{this._RenderPaymentMode()}</div>;
	}
}

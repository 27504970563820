import React, { Component, setGlobal, getGlobal } from "reactn";
import * as lod from "lodash";
const time = new Date().getTime().toString(); // => `1545880607433`

//let _urlBased = 'https://staging.vpos.com.my/lalamove:3333/'
//let _urlBased = 'https://staging.vpos.com.my/v8BackEnd/API/Test/'
//let _urlBased = this.global.BaseUrl + 'Lalamove/GetQuotation'

//https://staging.vpos.com.my/v8BackEnd/API/Test/GetQuotation?zHost=https://rest.sandbox.lalamove.com&zKey=2728fc428fa4e107ed75c66acafb7963&zSecret=xt4%2FT7HDpVd9ipcrc5pUNeol%2B0cDjWw3bPHv41vN%2FTsspPZ8gdJqPEa67Jx4eLEJ

function _UpdatePrice(x, y) {
  let _Gob = getGlobal();
  let _Gb = _Gob.WebInfo;
  //  console.log('Update Price', x)

  //Result { totalFee: '9', totalFeeCurrency: 'MYR' }

  var q = x;

    //console.log('UpdatePrice', q)
  //  console.log('UpdatePrice-1', q.totalFee)
  //console.log("Lalamove Charges", parseFloat(q.totalFee));


  let g = parseFloat(q.totalFee);

  let raw = _GetOrderBody(g);
  lod.set(_Gb, "Charges", g);
  lod.set(_Gb, "OrderRaw", raw);
  lod.set(_Gb, "Parameter", q.Parameter);
}

function _GetQuotationBody() {
  let _Gb = getGlobal();
  let _Data = _Gb.WebInfo;

  if (_Data === undefined) {
    return null;
  }

  //   let _Lnt = data.SelectedOutletLocation_Lnt

  var raw = JSON.stringify({
    serviceType: "MOTORCYCLE",
    specialRequests: [],
    stops: [
      {
        location: {
          lat: _Data.FromAddress_lat.toString(),
          lng: _Data.FromAddress_lng.toString(),
        },
        addresses: {
          ms_MY: {
            displayString: _Data.FromAddress,
            country: "MY_KUL",
          },
        },
      },
      {
        location: {
          lat: _Data.ToAddress_lat.toString(),
          lng: _Data.ToAddress_lng.toString(),
        },
        addresses: {
          ms_MY: { displayString: _Data.ToAddress, country: "MY_KUL" },
        },
      },
    ],
    requesterContact: {
      name: _Data.CustomerName,
      phone: _Data.MobileNo.toString(),
    },
    deliveries: [
      {
        toStop: 1,
        toContact: { name: "Shen Ong", phone: "0376886555" },
        remarks: "",
      },
    ],
  });

  return raw;
}
export function GetQuotation() {
  let _Gb = getGlobal();
  let _Gb2 = _Gb.InterfaceConfig;

  let _ModuleCode = "LALAMOVE";

  let _Data = lod.filter(_Gb2, (c) => c.ModuleCode === _ModuleCode);
  let _Data2 = _Data[0].BasedUrl + _Data[0].UserName;

  let host = _Data[0].BasedUrl;
  let key = _Data[0].DummyCode1;
  let secret = _Data[0].DummyCode2;

  lod.set(_Gb2, "LalamoveBasedUrl", host);
  lod.set(_Gb2, "LalamoveApiKey", key);
  lod.set(_Gb2, "LalamoveSecretKey", secret);

  //  let _LaUrl = 'https://sandbox-rest.lalamove.com' + path

  let raw = _GetQuotationBody();

  if (raw === undefined) {
    return null;
  }

  //let _url = _urlBased + 'quotation?host=' + host + '&key=' + key + '&secret=' + secret

  //let _url = _Gb.BasedUrl;
  let _url =
    _Gb.BasedUrl +
    "Lalamove/GetQuotation?zHost=" +
    host +
    "&zKey=" +
    encodeURIComponent(key) +
    "&zSecret=" +
    encodeURIComponent(secret);

  //https://staging.vpos.com.my/v8BackEnd/API/Test/GetQuotation?zHost=https://rest.sandbox.lalamove.com&zKey=2728fc428fa4e107ed75c66acafb7963&zSecret=xt4%2FT7HDpVd9ipcrc5pUNeol%2B0cDjWw3bPHv41vN%2FTsspPZ8gdJqPEa67Jx4eLEJ

  //console.log("Url", _url);
  //console.log("body", raw);

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  //  console.log('Url', _url)
  //   console.log('data', raw)

  fetch(_url, requestOptions)
    .then((response) => response.json())
    .then((result) => _UpdatePrice(result, raw))
  //  .then((result) => console.log("Result", result))
    .catch((error) => console.log("error", error));
}

function _UpdatePlaceOrder(x) {
  //   result.customerOrderId, result.orderRef
  let _Gob = getGlobal();

  //  var q = JSON.parse(x);

  var q = x.result;
  //   console.log("Place Order done", q);
  setGlobal({
    LalamoveDone: true,
    LalamoveOrderId: q.customerOrderId,
    LalamoveOrderRef: q.orderRef,
  });

  lod.set(_Gob.DefaultAppInfo, "customerOrderId", q.customerOrderId);
  lod.set(_Gob.DefaultAppInfo, "orderRef", q.orderRef);

  console.log("Lalamove OrderId.", q.customerOrderId);
  console.log("Lalamove OrderRef.", q.orderRef);
}

export function PlaceOrder() {
  let _Gob = getGlobal();

  let raw = _GetPlaceOrderBody();

  let host = _Gob.LalamoveBasedUrl;
  let key = _Gob.LalamoveApiKey;
  let secret = _Gob.LalamoveSecretKey;

  //   let _url = _urlBased + 'order?host=' + host + '&key=' + key + '&secret=' + secret
  let _url =
    _Gob.BaseUrl +
    "Lalamove/PostOrder?zHost=" +
    host +
    "&zKey=" +
    encodeURIComponent(key) +
    "&zSecret=" +
    encodeURIComponent(secret);
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(_url, requestOptions)
    .then((response) => response.json())
    .then((result) => _UpdatePlaceOrder(result))
    .catch((error) => console.log("error", error));
}

function _GetPlaceOrderBody() {
  let _Gob = getGlobal();

  let data = _Gob.DefaultAppInfo;

  var raw = JSON.stringify({
    serviceType: "MOTORCYCLE",
    specialRequests: [],
    stops: [
      {
        location: {
          lat: data.SelectedOutletLocation_Lnt.toString(),
          lng: data.SelectedOutletLocation_Lng.toString(),
        },
        addresses: {
          ms_MY: {
            displayString: data.SelectedOutletAddress,
            country: "MY_KUL",
          },
        },
      },
      {
        location: {
          lat: data.DeliveryLocation_Lnt.toString(),
          lng: data.DeliveryLocation_Lng.toString(),
        },
        addresses: {
          ms_MY: { displayString: data.DeliveryAddress, country: "MY_KUL" },
        },
      },
    ],
    requesterContact: {
      name: data.CustomerName,
      phone: data.MobileNo.toString(),
    },
    deliveries: [
      {
        toStop: 1,
        toContact: { name: "Shen Ong", phone: "0376886555" },
        remarks: "",
      },
    ],
    quotedTotalFee: {
      amount: _Gob.LiveDelCharge.toString(),
      currency: "MYR",
    },
  });

  return raw;
}

function _GetOrderBody(x) {
  let _Gb = getGlobal();
  let _Data = _Gb.WebInfo;

  let _Amt = parseFloat(x);

  var raw = JSON.stringify({
    serviceType: "MOTORCYCLE",
    specialRequests: [],
    stops: [
      {
        location: {
          lat: _Data.FromAddress_lat.toString(),
          lng: _Data.FromAddress_lng.toString(),
        },
        addresses: {
          ms_MY: {
            displayString: _Data.FromAddress,
            country: "MY_KUL",
          },
        },
      },
      {
        location: {
          lat: _Data.ToAddress_lat.toString(),
          lng: _Data.ToAddress_lng.toString(),
        },
        addresses: {
          ms_MY: { displayString: _Data.ToAddress, country: "MY_KUL" },
        },
      },
    ],
    requesterContact: {
      name: _Data.CustomerName,
      phone: _Data.MobileNo.toString(),
    },
    deliveries: [
      {
        toStop: 1,
        toContact: { name: "Shen Ong", phone: "0376886555" },
        remarks: "",
      },
    ],
    quotedTotalFee: {
      amount: _Amt.toString(),
      currency: "MYR",
    },
  });

  return raw;
}

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import Init from './Init';
import * as serviceWorker from './serviceWorker';
import Test2 from '../src/components/Test2';
import TestRev from './components/Web/TestRev';
import TestForm from './components/Web/TestForm';
import 'antd/dist/antd.css';

ReactDOM.render(<Init />, document.getElementById('root'));

serviceWorker.unregister();

import React, { Component } from 'reactn';
import {
	Result as Res,
	Drawer,
	Modal,
	Spin,
	Button,
	Typography,
	Space,
	List,
} from 'antd';
import Main from '../Main';
import PosOrder from '../PosOrder';
import * as Ion from '@ionic/react';
import 'antd/dist/antd.css';
import { CallGetApi, CallPostApi } from '../../functions/General';
import ViewOrder from '../ViewOrder';

class WebResult extends Component {
	constructor(props) {
		super(props);

		this.state = {
			IsOk: false,
			IsRetry: false,
			IsLoading: true,
			IsBack: false,
			IsSuccess: false,
		};
	}

	_RenderSuccess222 = () => {
		let _Txt1 = this.global.LanguagePack[this.global.LanguageNo].LabelThankyou;
		let _Txt2 = this.global.LanguagePack[this.global.LanguageNo].LabelWebText1;

		//	let _OrderNo =
		//		this.global.LanguagePack[this.global.LanguageNo].LabelOrderNo +
		//		' ' +
		//		this.global.OrderNo;

		let _OrderNo = 'Order No. ' + this.global.OrderNo;

		let _Style = { color: 'MediumSeaGreen', 'font-weight': 'bold' };
		let _Style2 = { color: 'Red', 'font-size': '25px', 'font-weight': 'bold' };

		let _Style3 = {
			//	position: 'relative',
			'font-size': '21px',
			'align-items': 'center',
			'vertical-align': 'middle',
			'border-color': 'DodgerBlue',
			'border-style': 'solid',
			'font-weight': 'bold',
		};

		let _AddTxt = 'Screenshot this screen for record purpose';
		return (
			<List>
				<p>{_Txt1}</p>
				<p>{_Txt2}</p>

				<div style={_Style2}>{_AddTxt}</div>
				<Button
					type="primary"
					key="Back"
					size="large"
					color={this.global.Color}
					block
					onClick={() => this.setState({ IsOk: true })}
				>
					{this.global.LanguagePack[this.global.LanguageNo].LabelWebButton}
				</Button>
				<div style={_Style3}>
					<p>{_OrderNo}</p>
				</div>
			</List>
		);
	};

	_RenderSuccess = () => {
		let _Txt1 = this.global.LanguagePack[this.global.LanguageNo].LabelThankyou;
		let _Txt2 = this.global.LanguagePack[this.global.LanguageNo].LabelWebText1;

		//	let _OrderNo =
		//		this.global.LanguagePack[this.global.LanguageNo].LabelOrderNo +
		//		' ' +
		//		this.global.OrderNo;

		let _OrderNo = 'Order No. ' + this.global.OrderNo;

		const { Paragraph, Text } = Typography;

		let _Style = { overflow: 'auto' };
		let _Style2 = { color: 'Red', 'font-size': '21px', 'font-weight': 'bold' };

		let _Style3 = {
			position: 'relative',
			//'font-size': '22px',
			margin: 'auto',
			width: '100%',
			padding: '10px',
			'align-items': 'center',
			'vertical-align': 'middle',
			'border-color': 'DodgerBlue',
			'border-style': 'solid',
			'font-weight': 'bold',
			'border-spacing': '5px',
		};

		let _AddTxt = 'Screenshot this screen for record purpose';
		return (
			<Modal
				centered
				visible={true}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
				footer={false}
				closable={false}
			>
				<Res
					status="success"
					title={
						<div>
							<p>{_Txt1}</p>
							<p>{_Txt2}</p>

							<div style={_Style2}>{_AddTxt}</div>
						</div>
					}
					extra={[
						<Button
							type="primary"
							key="Back"
							size="large"
							color={this.global.Color}
							block
							onClick={() => this.setState({ IsOk: true })}
						>
							{this.global.LanguagePack[this.global.LanguageNo].LabelWebButton}
						</Button>,
					]}
				>
					<div style={_Style3}>{_OrderNo}</div>
				</Res>
			</Modal>
		);
	};
	_RenderFail = () => {
		//	console.log('first');
		let _Txt1 = this.global.LanguagePack[this.global.LanguageNo].LabelSorry;
		let _Txt2 =
			this.global.LanguagePack[this.global.LanguageNo].LabelWebSorryText;

		let _OrderNo =
			this.global.LanguagePack[this.global.LanguageNo].LabelOrderNo +
			' ' +
			this.global.OrderNo;

		let _Style = { color: 'MediumSeaGreen', 'font-weight': 'bold' };

		return (
			<Res
				status="error"
				title={
					<div>
						<p>{_Txt1}</p>
						<p>{_Txt2}</p>
					</div>
				}
				extra={[
					<Space>
						<Button
							key="Back"
							size="large"
							color="Danger"
							block
							onClick={() => this.setState({ IsRetry: true })}
						>
							{this.global.LanguagePack[this.global.LanguageNo].LabelRetry}
						</Button>
						<Button
							type="primary"
							key="Back"
							size="large"
							color={this.global.Color}
							block
							onClick={() => this.setState({ IsBack: true })}
						>
							{this.global.LanguagePack[this.global.LanguageNo].LabelBack}
						</Button>
					</Space>,
				]}
			/>
		);
	};

	_RenderCheck = () => {
		//	console.log('Global----', this.global.ReturnStatus);

		//	if (this.state.IsSuccess === false) {
		switch (this.global.ReturnStatus.Status) {
			case 'Y':
				if (this.state.IsSuccess === false) {
					this._RenderSaveResult();
				}
			case 'N':
				this.setState({ IsError: true, IsSuccess: false, IsLoading: false });
				return this._RenderFail();

			default:
				return this._RenderFail();
		}
	};
	//	};

	_RenderSaveResult = async () => {
		//	console.log('fffff');
		let _Value6 = {
			Type: 'UPDATEPAYMENT',
			//TokenId: this.global.ReturnStatus.ReturnToken,
			FetchType: 'D',
			TokenId: localStorage.getItem('WebTokenId'),
		};

		//	console.log("Before Result", _Value6);

		let _Result = await CallGetApi(_Value6);
		//	console.log("Save Result", _Result);
		if (_Result.IsSuccess === 1) {
			localStorage.removeItem('WebData');
			localStorage.removeItem('WebTokenId');
			localStorage.removeItem('WebAmount');
			let _LiveData = { Bills: {}, BillDetails: [] };
			this.setGlobal({
				TrsNo: _Result.TrsNo,
				OrderNo: _Result.OrderNo,
				LiveData: _LiveData,
			});
			this.setState({ IsError: false, IsSuccess: true, IsLoading: false });
		} else {
			this.setState({
				ErrorMessage: _Result.ErrorMessage,
				IsError: true,
				IsSuccess: false,
				IsLoading: false,
			});
		}
	};

	componentDidMount() {
		this._RenderCheck();
	}

	_RenderError = () => {
		const _Style = { 'text-align': 'center' };
		const { Paragraph, Text } = Typography;

		return (
			<Modal
				centered
				visible={this.state.IsError}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
				footer={false}
				closable={false}
			>
				<Res
					status="warning"
					title={this.global.LanguagePack[this.global.LanguageNo].AlertTitle}
					subTitle={
						this.global.LanguagePack[this.global.LanguageNo]
							.AlertFailSubmitRetry
					}
					extra={[
						<Space>
							<Button disabled type="primary" key="Retry" size="large" block>
								Retry
							</Button>
							<Button
								key="Back"
								size="large"
								block
								onClick={() =>
									this.setState({
										IsError: false,
										IsLoading: false,
										IsBack: true,
									})
								}
							>
								Back
							</Button>
						</Space>,
					]}
				/>
				<div className="desc">
					<Paragraph>
						<Text strong style={{ color: 'red', fontSize: 16 }}>
							Message
						</Text>
					</Paragraph>
					{/* <Paragraph>{this.state.ErrorMessage}</Paragraph> */}
					<Paragraph>Error While Submit...</Paragraph>
				</div>
			</Modal>
		);
	};

	_RenderLoading = () => {
		const _Style = { textAlign: 'center' };
		return (
			<Modal
				centered
				visible={this.state.IsLoading}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
				footer={false}
				closable={false}
				width={200}
			>
				<div style={_Style}>
					<Spin
						tip={this.global.LanguagePack[this.global.LanguageNo].AlertSubmit}
					/>
				</div>
			</Modal>
		);
	};

	render() {
		//console.log(this.state);

		if (this.state.IsBack === true) {
			return <Main />;
		}

		if (this.state.IsRetry === true) {
			return <PosOrder />;
		}

		if (this.state.IsOk === true) {
			return <ViewOrder />;
		}

		if (this.state.IsSuccess === true) {
			return this._RenderSuccess();
		}

		return (
			<div>
				{this._RenderLoading()}
				{this._RenderError()}
			</div>
		);
	}
}

export default WebResult;

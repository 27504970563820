import React, { Component, getGlobal, setGlobal } from 'reactn';
import * as Ion from '@ionic/react';
import '../Css/Style.css';
import PosOrder from './PosOrder';
import * as lod from 'lodash';
import ViewOrder from './ViewOrder';
import WebResult from './Web/WebResult';
import {
	listOutline,
	chevronBack,
	language,
	closeCircle,
} from 'ionicons/icons';
import {
	ConvertLanguage,
	UpdateLanguage,
	UpdateSelectedPrice,
	CallPostApi,
} from '../functions/General';
import WebStart from './Web/WebStart';
import { Drawer, Input, Modal, Form, Button, Space, InputNumber } from 'antd';

let MyMobileNo = '';

export default class Main extends Component {
	constructor(props) {
		super(props);

		this.state = {
			IsSelectService: false,
			IsStart: false,
			IsViewOrder: false,
			IsLang: false,
			IsOpenDrawer: false,
			IsMenuText: false,
			MenuType: '',
			MenuText: '',
			MenuTitle: '',
			IsMemberSign: false,
			IsMemberSignUp: false,
			MobileNo: '',
		};

		//	console.log('This global', this.global);
	}
	formRef = React.createRef();
	_UpdateSelectedPrice = (zPriceGroupNo, zIndex) => {
		switch (zPriceGroupNo) {
			case '0':
				lod.set(
					this.global.ItemInfo[zIndex],
					'PosPrice',
					this.global.ItemInfo[zIndex].DefPrice
				);
				lod.set(
					this.global.ItemInfoAll[zIndex],
					'PosPrice',
					this.global.ItemInfoAll[zIndex].DefPrice
				);
				lod.set(
					this.global.SubItemInfo[zIndex],
					'PosPrice',
					this.global.SubItemInfo[zIndex].DefPrice
				);

				break;
			case '1':
				lod.set(
					this.global.ItemInfo[zIndex],
					'PosPrice',
					this.global.ItemInfo[zIndex].PosPrice1
				);
				lod.set(
					this.global.ItemInfoAll[zIndex],
					'PosPrice',
					this.global.ItemInfoAll[zIndex].PosPrice1
				);
				lod.set(
					this.global.SubItemInfo[zIndex],
					'PosPrice',
					this.global.SubItemInfo[zIndex].PosPrice1
				);
				break;
			case '2':
				lod.set(
					this.global.ItemInfo[zIndex],
					'PosPrice',
					this.global.ItemInfo[zIndex].PosPrice2
				);
				lod.set(
					this.global.ItemInfoAll[zIndex],
					'PosPrice',
					this.global.ItemInfoAll[zIndex].PosPrice2
				);
				lod.set(
					this.global.SubItemInfo[zIndex],
					'PosPrice',
					this.global.SubItemInfo[zIndex].PosPrice2
				);
				break;
			case '3':
				lod.set(
					this.global.ItemInfo[zIndex],
					'PosPrice',
					this.global.ItemInfo[zIndex].PosPrice3
				);
				lod.set(
					this.global.ItemInfoAll[zIndex],
					'PosPrice',
					this.global.ItemInfoAll[zIndex].PosPrice3
				);
				lod.set(
					this.global.SubItemInfo[zIndex],
					'PosPrice',
					this.global.SubItemInfo[zIndex].PosPrice3
				);
				break;
			case '4':
				lod.set(
					this.global.ItemInfo[zIndex],
					'PosPrice',
					this.global.ItemInfo[zIndex].PosPrice4
				);
				lod.set(
					this.global.ItemInfoAll[zIndex],
					'PosPrice',
					this.global.ItemInfoAll[zIndex].PosPrice4
				);
				lod.set(
					this.global.SubItemInfo[zIndex],
					'PosPrice',
					this.global.SubItemInfo[zIndex].PosPrice4
				);
				break;
			case '5':
				lod.set(
					this.global.ItemInfo[zIndex],
					'PosPrice',
					this.global.ItemInfo[zIndex].PosPrice5
				);
				lod.set(
					this.global.ItemInfoAll[zIndex],
					'PosPrice',
					this.global.ItemInfoAll[zIndex].PosPrice5
				);
				lod.set(
					this.global.SubItemInfo[zIndex],
					'PosPrice',
					this.global.SubItemInfo[zIndex].PosPrice5
				);

			case '6':
				lod.set(
					this.global.ItemInfo[zIndex],
					'PosPrice',
					this.global.ItemInfo[zIndex].PosPrice6
				);
				lod.set(
					this.global.ItemInfoAll[zIndex],
					'PosPrice',
					this.global.ItemInfoAll[zIndex].PosPrice6
				);
				lod.set(
					this.global.SubItemInfo[zIndex],
					'PosPrice',
					this.global.SubItemInfo[zIndex].PosPrice6
				);
				break;
			case '7':
				lod.set(
					this.global.ItemInfo[zIndex],
					'PosPrice',
					this.global.ItemInfo[zIndex].PosPrice7
				);
				lod.set(
					this.global.ItemInfoAll[zIndex],
					'PosPrice',
					this.global.ItemInfoAll[zIndex].PosPrice7
				);
				lod.set(
					this.global.SubItemInfo[zIndex],
					'PosPrice',
					this.global.SubItemInfo[zIndex].PosPrice7
				);
				break;
			case '8':
				lod.set(
					this.global.ItemInfo[zIndex],
					'PosPrice',
					this.global.ItemInfo[zIndex].PosPrice8
				);
				lod.set(
					this.global.ItemInfoAll[zIndex],
					'PosPrice',
					this.global.ItemInfoAll[zIndex].PosPrice8
				);
				lod.set(
					this.global.SubItemInfo[zIndex],
					'PosPrice',
					this.global.SubItemInfo[zIndex].PosPrice8
				);
				break;
			case '9':
				lod.set(
					this.global.ItemInfo[zIndex],
					'PosPrice',
					this.global.ItemInfo[zIndex].PosPrice9
				);
				lod.set(
					this.global.ItemInfoAll[zIndex],
					'PosPrice',
					this.global.ItemInfoAll[zIndex].PosPrice9
				);
				lod.set(
					this.global.SubItemInfo[zIndex],
					'PosPrice',
					this.global.SubItemInfo[zIndex].PosPrice9
				);
				break;
			case '10':
				lod.set(
					this.global.ItemInfo[zIndex],
					'PosPrice',
					this.global.ItemInfo[zIndex].PosPrice10
				);
				lod.set(
					this.global.ItemInfoAll[zIndex],
					'PosPrice',
					this.global.ItemInfoAll[zIndex].PosPrice10
				);
				lod.set(
					this.global.SubItemInfo[zIndex],
					'PosPrice',
					this.global.SubItemInfo[zIndex].PosPrice10
				);
				break;

			default:
				lod.set(
					this.global.ItemInfo[zIndex],
					'PosPrice',
					this.global.ItemInfo[zIndex].DefPrice
				);
				lod.set(
					this.global.ItemInfoAll[zIndex],
					'PosPrice',
					this.global.ItemInfoAll[zIndex].PosPrice
				);
				lod.set(
					this.global.SubItemInfo[zIndex],
					'PosPrice',
					this.global.SubItemInfo[zIndex].PosPrice
				);

				break;
		}
	};

	_Action = (zType, zCode) => {
		switch (zType) {
			case 'UPDATELANGUAGE---':
				for (let i = 0; i < this.global.ItemInfo.length; i++) {
					switch (zCode) {
						case '1':
							lod.set(
								this.global.ItemInfo[i],
								'MenuName',
								this.global.ItemInfo[i].Name1
							);
							lod.set(
								this.global.ItemInfoAll[i],
								'MenuName',
								this.global.ItemInfoAll[i].Name1
							);

							lod.set(
								this.global.ClassGroupInfo[i],
								'MenuName',
								this.global.ItemInfoAll[i].Name1
							);
							lod.set(
								this.global.ClassDetailsInfo[i],
								'MenuName',
								this.global.ItemInfoAll[i].Name1
							);
							break;
						default:
							lod.set(
								this.global.ItemInfo[i],
								'MenuName',
								this.global.ItemInfo[i].Name
							);
							lod.set(
								this.global.ItemInfoAll[i],
								'MenuName',
								this.global.ItemInfoAll[i].Name
							);
							break;
					}
				}

				for (let i = 0; i < this.global.ModifierGroupInfo.length; i++) {
					switch (zCode) {
						case '1':
							lod.set(
								this.global.ModifierGroupInfo[i],
								'Name',
								this.global.ModifierGroupInfo[i].Name1
							);
							break;
						default:
							lod.set(
								this.global.ModifierGroupInfo[i],
								'Name',
								this.global.ModifierGroupInfo[i].DefName
							);
							break;
					}
				}

				for (let i = 0; i < this.global.ModifierDetailsInfo.length; i++) {
					switch (zCode) {
						case '1':
							lod.set(
								this.global.ModifierDetailsInfo[i],
								'Name',
								this.global.ModifierDetailsInfo[i].Name1
							);
							break;
						default:
							lod.set(
								this.global.ModifierDetailsInfo[i],
								'Name',
								this.global.ModifierDetailsInfo[i].DefName
							);
							break;
					}
				}

				for (let i = 0; i < this.global.CondimentGroupInfo.length; i++) {
					switch (zCode) {
						case '1':
							lod.set(
								this.global.CondimentGroupInfo[i],
								'Name',
								this.global.CondimentGroupInfo[i].Name1
							);
							break;
						default:
							lod.set(
								this.global.CondimentGroupInfo[i],
								'Name',
								this.global.CondimentGroupInfo[i].CondName
							);
							break;
					}
				}

				for (let i = 0; i < this.global.CondimentDetailsInfo.length; i++) {
					switch (zCode) {
						case '1':
							lod.set(
								this.global.CondimentDetailsInfo[i],
								'Name',
								this.global.CondimentDetailsInfo[i].Name1
							);
							break;
						default:
							lod.set(
								this.global.CondimentDetailsInfo[i],
								'Name',
								this.global.CondimentDetailsInfo[i].DefName
							);
							break;
					}
				}

				for (let i = 0; i < this.global.ClassGroupInfo.length; i++) {
					switch (zCode) {
						case '1':
							lod.set(
								this.global.ClassGroupInfo[i],
								'Name',
								this.global.ClassGroupInfo[i].Name1
							);
							break;
						default:
							lod.set(
								this.global.ClassGroupInfo[i],
								'Name',
								this.global.ClassGroupInfo[i].TypeName
							);
							break;
					}
				}

				for (let i = 0; i < this.global.ClassDetailsInfo.length; i++) {
					switch (zCode) {
						case '1':
							lod.set(
								this.global.ClassDetailsInfo[i],
								'Name',
								this.global.ClassDetailsInfo[i].Name1
							);
							break;
						default:
							lod.set(
								this.global.ClassDetailsInfo[i],
								'Name',
								this.global.ClassDetailsInfo[i].ClassName
							);
							break;
					}
				}

				for (let i = 0; i < this.global.ServiceInfo.length; i++) {
					switch (zCode) {
						case '1':
							lod.set(
								this.global.ServiceInfo[i],
								'Name',
								this.global.ServiceInfo[i].Name1
							);
							break;
						default:
							lod.set(
								this.global.ServiceInfo[i],
								'Name',
								this.global.ServiceInfo[i].ServiceTypeName
							);
							break;
					}
				}
				this.setGlobal({
					LanguageNo: zCode,
					LanguagePack: ConvertLanguage(zCode),
				});

				this.setState({ IsLang: false });
				this.forceUpdate();
				break;

			case 'SELECTSERVICE':
				let _AppMode = localStorage.getItem('ServiceCode');
				let _TableCode = localStorage.getItem('TableCode');
				let _TokenId = localStorage.getItem('Token');
				//	console.log('Global Service', this.global.ServiceInfo);
				let data = lod.filter(this.global.ServiceInfo, c => c.Code === zCode);

				//	console.log('Data', data);

				let _Default = {
					AppMode: _AppMode,
					SelectedServiceInfo: data[0],
					SelectedServiceCode: zCode,
					TableCode: _TableCode,
					TokenId: _TokenId,
					DefTableCode: localStorage.getItem('DefTableCode'),
				};
				let _LiveData = { Bills: {}, BillDetails: [] };

				let _PriceGroupNo = data[0].PriceGroupNo;
				let _PriceGroupName = 'DefPrice';
				if (_PriceGroupNo !== '0') {
					_PriceGroupName = 'PosPrice' + _PriceGroupNo;
				}

				let _Value = {
					PriceGroupNo: _PriceGroupNo,
					ServiceCode: data[0].ServiceCode,
				};
				UpdateSelectedPrice('', _Value);

				this.setGlobal({
					Default: _Default,
					LiveData: _LiveData,
					TableCode: _TableCode,
					TokenId: _TokenId,
				});
				this.setState({ IsStart: true });
				break;

			case 'UPDATELANGUAGE':
				UpdateLanguage(zCode);

				this.setGlobal({
					LanguageNo: zCode,
					LanguagePack: ConvertLanguage(zCode),
				});

				this.setState({ IsLang: false });
				this.forceUpdate();
				break;

			default:
				break;
		}
	};

	_RenderText = () => {
		//console.log('gggg', this.global.Default.AppMode);
		let _Txt =
			this.global.LanguagePack[this.global.LanguageNo].ButtonMyTableCaption +
			localStorage.getItem('TableName');
		//	console.log(this.global);

		if (
			this.global.Default.AppMode === 'T' ||
			this.global.Default.AppMode === 'P'
		) {
			_Txt = 'RefNo : ' + localStorage.getItem('DefTableCode');
		}

		if (this.global.Default.AppMode === 'O') {
			_Txt = '';
		}

		// console.log(this.global)
		const _Style = { textAlign: 'center' };
		const _Style2 = { 'margin-top': '10px' };
		//if (this.global.Default.TableCode !== undefined || this.global.Default.TableCode !== null) {
		if (this.global.Default.TableCode !== 'null') {
			return (
				<div>
					<Ion.IonLabel className="ion-text-center">
						<Ion.IonText color="light">
							<div style={_Style2}>{this.global.OutletInfo.OutletName}</div>
						</Ion.IonText>
					</Ion.IonLabel>

					<Ion.IonLabel className="ion-text-center">
						<Ion.IonText color="light">
							<div style={_Style}>{_Txt}</div>
						</Ion.IonText>
					</Ion.IonLabel>
				</div>
			);
		}
	};

	_RenderFAB = () => {
		return (
			<Ion.IonFab vertical="top" horizontal="end">
				<Ion.IonFabButton
					color="light"
					onClick={() => this.setState({ IsLang: true })}
				>
					<Ion.IonIcon icon={language} />
				</Ion.IonFabButton>
			</Ion.IonFab>
		);
	};

	_RenderLanguage = () => {
		return (
			<Ion.IonPopover
				isOpen={this.state.IsLang}
				onDidDismiss={e => this.setState({ IsLang: false })}
			>
				<Ion.IonList>
					<Ion.IonListHeader>
						<Ion.IonLabel className="ion-text-center">
							{
								this.global.LanguagePack[this.global.LanguageNo]
									.ButtonSelectLanguage
							}
						</Ion.IonLabel>
					</Ion.IonListHeader>
					<br />
					<Ion.IonItem lines="none">
						<Ion.IonLabel>
							<Ion.IonButton
								color={this.global.Color}
								size="large"
								expand="full"
								onClick={() => this._Action('UPDATELANGUAGE', '0')}
							>
								English
							</Ion.IonButton>
						</Ion.IonLabel>
					</Ion.IonItem>

					<Ion.IonItem lines="none">
						<Ion.IonLabel>
							<Ion.IonButton
								color={this.global.Color}
								size="large"
								expand="full"
								onClick={() => this._Action('UPDATELANGUAGE', '1')}
							>
								中文
							</Ion.IonButton>
						</Ion.IonLabel>
					</Ion.IonItem>
				</Ion.IonList>
			</Ion.IonPopover>
		);
	};

	_RenderMain = () => {
		const _Style = {
			position: 'absolute',
			textAlign: 'center',
			'object-fit': 'cover',
			height: '93vh',
			width: '100%',
		};

		let _Style2 = { color: 'white' };

		let _ImageName = '';
		if (this.global.ImageInfo !== null) {
			_ImageName = this.global.ImageInfo[0].ImageName;
		}

		return (
			<div>
				<img style={_Style} src={_ImageName} alt="" />
				<div className="ImageTextMain">
					{this._RenderText()}
					{this._RenderButton()}
				</div>
			</div>
		);
	};

	_RenderButton = () => {
		let _size = 12;
		let _sm = 12;
		let _md = 6;
		let _lg = 6;
		let _xl = 6;

		const _CheckMember = () => {
			//	console.log('Ref', this.global.OutletInfo.RefOutlet);
			// [F25] Enable Member Login
			if (this.global.OutletInfo.RefOutlet.substr(24, 1) === 'O') {
				let queryString = window.location.search.substring(1);
				const urlParams = new URLSearchParams(queryString);
				const _Profile = urlParams.get('zProfileCode');

				let _Mobile = localStorage.getItem('MobileNo');
				let _Name = localStorage.getItem('CustomerName');
				let _ProfileCode = localStorage.getItem('ProfileCode');
				//	console.log('first', _Mobile);
				if (_Mobile !== null) {
					if (_Profile !== _ProfileCode) {
						let _Gb = this.global.WebInfo;
						lod.set(_Gb, 'MobileNo', _Mobile);
						lod.set(_Gb, 'CustomerName', _Name);
						this.setState({
							IsMemberSign: false,
							IsMemberSignUp: false,
							IsSelectService: true,
						});
					} else {
						this.setState({ IsMemberSign: true });
					}
				} else {
					this.setState({ IsMemberSign: true });
				}
			} else {
				this.setState({ IsSelectService: true });
			}
		};

		return (
			<Ion.IonGrid>
				<Ion.IonRow>
					<Ion.IonCol
						size-lg={_lg}
						size-xl={_xl}
						size-md={_md}
						size-sm={_sm}
						size={_size}
					>
						<Ion.IonButton
							color={this.global.Color}
							expand="block"
							size="large"
							fill="solid"
							//onClick={() => this.setState({ IsSelectService: true })}
							//onClick={() => this.setState({ IsMemberSign: true })}
							onClick={() => _CheckMember()}
						>
							<Ion.IonText>
								{
									this.global.LanguagePack[this.global.LanguageNo]
										.ButtonOrderCaption
								}
							</Ion.IonText>
						</Ion.IonButton>
					</Ion.IonCol>
					<Ion.IonCol
						size-lg={_lg}
						size-xl={_xl}
						size-md={_md}
						size-sm={_sm}
						size={_size}
					>
						<Ion.IonButton
							color={this.global.Color}
							expand="block"
							fill="solid"
							size="large"
							onClick={() => this.setState({ IsViewOrder: true })}
						>
							{
								this.global.LanguagePack[this.global.LanguageNo]
									.ButtonMyHistoryCaption
							}
						</Ion.IonButton>
					</Ion.IonCol>
				</Ion.IonRow>
			</Ion.IonGrid>
		);
	};

	_ShowMember = () => {
		if (this.state.IsMemberSign === true) {
			if (this.global.WebInfo.CustomerName !== '') {
				return (
					<Ion.IonLabel className="ion-text-center">
						<br />
						{'Hi ' + this.global.WebInfo.CustomerName}
					</Ion.IonLabel>
				);
			}
		}
	};

	_RenderService = () => {
		//    let _Data = this.global.ServiceInfo

		//
		//   console.log('object--Before', this.global)

		if (this.state.IsSelectService === true) {
			switch (this.global.Default.AppMode) {
				case 'T':
					this._Action('SELECTSERVICE', 'T');
					return null;

				case 'P':
					this._Action('SELECTSERVICE', 'P');
					return null;

				default:
					break;
			}

			/* if (this.global.Default.AppMode === 'T') {
				this._Action('SELECTSERVICE', 'T');
				return null;
			} */
		}

		let _Data = lod.filter(
			this.global.ServiceInfo,
			c => c.ServiceGroup === this.global.Default.AppMode
		);
		// console.log('object-After', _Data)

		return (
			<Ion.IonModal isOpen={this.state.IsSelectService} backdropDismiss={false}>
				<Ion.IonList>
					<Ion.IonListHeader>
						<Ion.IonLabel className="ion-text-center">
							{
								this.global.LanguagePack[this.global.LanguageNo]
									.ButtonOrderingMethod
							}
							{this._ShowMember()}
						</Ion.IonLabel>
					</Ion.IonListHeader>
					<br />

					{_Data.map(item => (
						<Ion.IonItem lines="none">
							<Ion.IonLabel>
								<Ion.IonButton
									shape="round"
									color={this.global.Color}
									size="large"
									expand="full"
									onClick={() => this._Action('SELECTSERVICE', item.Code)}
								>
									{item.Name}
								</Ion.IonButton>
							</Ion.IonLabel>
						</Ion.IonItem>
					))}
				</Ion.IonList>
				<Ion.IonButton
					shape="full"
					color={this.global.Color}
					size="large"
					expand="full"
					onClick={() => this.setState({ IsSelectService: false })}
				>
					{this.global.LanguagePack[this.global.LanguageNo].ButtonCancelCaption}
				</Ion.IonButton>
			</Ion.IonModal>
		);
	};

	_RenderMenuText = () => {
		const { TextArea } = Input;

		return (
			<>
				<Ion.IonModal isOpen={this.state.IsMenuText} backdropDismiss={false}>
					<Ion.IonHeader>
						<Ion.IonToolbar color={this.global.Color}>
							<Ion.IonButtons
								slot="start"
								onClick={() => this.setState({ IsMenuText: false })}
							>
								<Ion.IonButton>
									<Ion.IonIcon icon={chevronBack}></Ion.IonIcon>
								</Ion.IonButton>
							</Ion.IonButtons>
							<Ion.IonLabel>{this.state.MenuTitle}</Ion.IonLabel>
						</Ion.IonToolbar>
					</Ion.IonHeader>
					<TextArea
						autoSize={true}
						value={this.state.MenuText}
						bordered={false}
						readOnly={true}
					/>
					<br />
					<br />
					<TextArea></TextArea>
				</Ion.IonModal>
			</>
		);
	};

	_CheckPayment = () => {
		if (this.global.WebInfo.WithPayment === 1) {
			return (
				<Ion.IonButtons
					slot="start"
					onClick={() => this.setState({ IsOpenDrawer: true })}
				>
					<Ion.IonButton>
						<Ion.IonIcon icon={listOutline}></Ion.IonIcon>
					</Ion.IonButton>
				</Ion.IonButtons>
			);
		}
	};

	_RenderHeader() {
		return (
			<Ion.IonHeader>
				<Ion.IonToolbar color={this.global.Color}>
					{this._CheckPayment()}
					<Ion.IonButtons slot="end">
						<Ion.IonButton onClick={() => this.setState({ IsLang: true })}>
							<Ion.IonIcon icon={language} />
						</Ion.IonButton>
					</Ion.IonButtons>
				</Ion.IonToolbar>
			</Ion.IonHeader>
		);
	}

	_RenderDrawer = () => {
		return (
			<Drawer
				title="Menu"
				placement="left"
				closable={false}
				onClose={() => this.setState({ IsOpenDrawer: false })}
				visible={this.state.IsOpenDrawer}
				key="left"
			>
				<Ion.IonList>
					<Ion.IonListHeader>
						<Ion.IonLabel></Ion.IonLabel>
					</Ion.IonListHeader>
					<br />
					<Ion.IonItem
						onClick={() =>
							this.setState({
								MenuType: 'ABOUT',
								MenuText: this.global.OutletInfo.AboutUs,
								MenuTitle: 'About Us',
								IsOpenDrawer: false,
								IsMenuText: true,
							})
						}
					>
						<Ion.IonLabel>About Us</Ion.IonLabel>
					</Ion.IonItem>
					<Ion.IonItem
						onClick={() =>
							this.setState({
								MenuType: 'PRIVACY',
								MenuText: this.global.OutletInfo.PrivacyPolicy,
								MenuTitle: 'Privacy Policy',
								IsOpenDrawer: false,
								IsMenuText: true,
							})
						}
					>
						<Ion.IonLabel>Privacy Policy</Ion.IonLabel>
					</Ion.IonItem>
					<Ion.IonItem
						onClick={() =>
							this.setState({
								MenuType: 'RETURN',
								MenuText: this.global.OutletInfo.ReturnPolicy,
								MenuTitle: 'Return Policy',
								IsOpenDrawer: false,
								IsMenuText: true,
							})
						}
					>
						<Ion.IonLabel>Return Policy</Ion.IonLabel>
					</Ion.IonItem>
					<Ion.IonItem
						onClick={() =>
							this.setState({
								MenuType: 'TERM',
								MenuText: this.global.OutletInfo.TermofUse,
								MenuTitle: 'Term of Use',
								IsOpenDrawer: false,
								IsMenuText: true,
							})
						}
					>
						<Ion.IonLabel>Term of Use</Ion.IonLabel>
					</Ion.IonItem>
					<Ion.IonItem
						onClick={() =>
							this.setState({
								MenuType: 'CONTACT',
								MenuText: this.global.OutletInfo.ContactUs,
								MenuTitle: 'Contact Us',
								IsOpenDrawer: false,
								IsMenuText: true,
							})
						}
					>
						<Ion.IonLabel>Contact Us</Ion.IonLabel>
					</Ion.IonItem>
				</Ion.IonList>
			</Drawer>
		);
	};

	_FetchSubmit = async (e, zType) => {
		try {
			let _MobileNo;
			MyMobileNo = _MobileNo;
			let _Gb = this.global.WebInfo;
			//	this.formRef.current.setFieldsValue({ MobileNo: _MobileNo });

			switch (zType) {
				case 'VALIDATE':
					_MobileNo = '60' + e.MobileNo_Sign;
					let _Value = { Type: 'VALIDATEMEMBER', Value: { Code: _MobileNo } };
					let _Result = await CallPostApi(_Value);
					if (_Result.IsSuccess === 0) {
						this.setState({
							IsMemberSign: false,
							IsMemberSignUp: true,
							MobileNo: _MobileNo,
						});
					} else {
						let _Info = { MobileNo: _Result.Code, CustomerName: _Result.Name };

						lod.set(_Gb, 'MobileNo', _Result.Code);
						lod.set(_Gb, 'CustomerName', _Result.Name);

						//	this.setGlobal({ WebInfo: _Info });
						localStorage.setItem('MobileNo', _Result.Code);
						localStorage.setItem('CustomerName', _Result.Name);

						this.setState({
							IsMemberSign: false,
							IsMemberSignUp: false,
							IsSelectService: true,
						});
					}
					break;
				case 'INSERT':
					_MobileNo = '0' + e.MobileNo_Sign;

					let _Value2 = {
						Type: 'INSERTMEMBER',
						Value: {
							Code: this.state.MobileNo,
							Name: e.Name,
							EmailAddress: e.EmailAddress,
						},
					};
					let _Result2 = await CallPostApi(_Value2);
					if (_Result2.IsSuccess === 0) {
						this.setState({ IsMemberSign: false, IsMemberSignUp: true });
					} else {
						let _Info = {
							MobileNo: this.state.MobileNo,
							CustomerName: e.Name,
						};

						lod.set(_Gb, 'MobileNo', this.state.MobileNo);
						lod.set(_Gb, 'CustomerName', e.Name);

						localStorage.setItem('MobileNo', this.state.MobileNo);
						localStorage.setItem('CustomerName', e.Name);

						//	this.setGlobal({ WebInfo: _Info });
						this.setState({
							IsMemberSign: false,
							IsMemberSignUp: false,
							IsSelectService: true,
						});
					}
					break;

				default:
					break;
			}
		} catch (error) {}
	};

	_RenderMemberSign = () => {
		return (
			<Modal
				title="Member Sign In"
				centered
				closable={false}
				maskClosable={false}
				visible={this.state.IsMemberSign}
				footer={null}
			>
				<Form
					//	ref={this.formRef}
					name={'SignForm'}
					//	labelCol={{ span: 4 }}
					//wrapperCol={{ span: 14 }}
					layout="vertical"
					onFinish={e => this._FetchSubmit(e, 'VALIDATE')}
					size="large"
					//	initialValues={{ remember: true }}
					//initialValues={{ disabled: componentDisabled }}
					//	onValuesChange={onFormLayoutChange}
					//	disabled={componentDisabled}
				>
					<Form.Item
						label="Mobile No."
						name="MobileNo_Sign"
						labelAlign="left"
						tooltip="Mobile Phone No."
						rules={[
							{
								required: true,
								message: 'Mobile Phone is required!',
							},
						]}
					>
						<Input
							autoFocus
							prefix="+60"
							placeholder="e.g 23331234"
							style={{
								width: '100%',
							}}
							onChange={e => this.setState({ MobileNo: e.target.value })}
							//onChange={e => console.log('Change', e.target.value)}
						/>
					</Form.Item>

					<Space>
						<Button type="primary" htmlType="submit">
							Submit
						</Button>
						<Button
							onClick={() =>
								this.setState({
									IsMemberSign: false,
									IsMemberSignUp: false,
								})
							}
						>
							Cancel
						</Button>
					</Space>
				</Form>
			</Modal>
		);
	};

	_RenderMemberSignUp = () => {
		return (
			<Modal
				title="Member Sign Up"
				centered
				forceRender={false}
				closable={false}
				maskClosable={false}
				visible={this.state.IsMemberSignUp}
				footer={null}
			>
				<Form
					//	labelCol={{ span: 4 }}
					//wrapperCol={{ span: 14 }}
					ref={this.formRef}
					name={'SignUpForm'}
					size="large"
					//	initialValues={{ remember: true }}
					layout="vertical"
					onFinish={e => this._FetchSubmit(e, 'INSERT')}
					//	initialValues={{ MobileNo: this.state.MobileNo }}
					//	onValuesChange={onFormLayoutChange}
					//	disabled={componentDisabled}
				>
					<Form.Item
						label="Mobile No."
						name="MobileNo"
						labelAlign="left"
						tooltip="Mobile Phone No."
					>
						<Input
							//	autoFocus
							defaultValue={this.state.MobileNo}
							//defaultValue={MyMobileNo}
							//value={MyMobileNo}
							//	value={this.state.MobileNo}
							//disabled={true}
							//	prefix="+60"
							placeholder="e.g 23331234"
							style={{
								width: '100%',
							}}
						/>
					</Form.Item>

					<Form.Item
						label="Name"
						name="Name"
						tooltip="Name"
						labelAlign="left"
						rules={[{ required: true, message: 'Name is required!' }]}
					>
						<Input autoFocus placeholder="Name" />
					</Form.Item>
					<Form.Item
						label="Email Address"
						name="EmailAddress"
						labelAlign="left"
						rules={[
							{
								required: true,
								type: 'email',
								message: 'Invalid Email Address !',
							},
						]}
					>
						<Input placeholder="Email Address" />
					</Form.Item>
					<Space>
						<Button type="primary" htmlType="submit">
							Submit
						</Button>
						<Button
							onClick={() =>
								this.setState({
									IsMemberSign: false,
									IsMemberSignUp: false,
								})
							}
						>
							Cancel
						</Button>
					</Space>
				</Form>
			</Modal>
		);
	};

	render() {
		//console.log('Web Data', this.global);
		// console.log("HOST", window.location.href.split("?")[0]);

		if (this.state.IsStart === true) {
			if (this.global.Default.SelectedServiceCode === null) {
				return null;
			}

			localStorage.removeItem('WebData');
			localStorage.removeItem('WebTokenId');
			localStorage.removeItem('WebAmount');

			switch (this.global.Default.SelectedServiceCode) {
				case 'E':
					return <WebStart />;
				case 'S':
					return <WebStart />;

				default:
					return <PosOrder />;
			}
		}

		//console.log('This global', this.global);
		if (this.state.IsViewOrder === true) {
		//	console.log('first');
			return <ViewOrder />;
		}

		return (
			<Ion.IonApp>
				{this._RenderHeader()}
				<Ion.IonContent fullscreen>
					{this._RenderMain()}
					{this._RenderMenuText()}
					{/*    {this._RenderFAB()} */}
					{this._RenderMemberSign()}
					{this._RenderMemberSignUp()}
					{this._RenderDrawer()}
					{this._RenderService()}
					{this._RenderLanguage()}
				</Ion.IonContent>
			</Ion.IonApp>
		);
	}
}
